import request from "common/plugins/request"
import { useEffect, useMemo, useState } from "react"

export const useSubaccounts = (ctx) => {
	const [subaccounts, setSubaccounts] = useState([])
	const [subaccount, setSubaccount] = useState([])
	const [proccessesID, setProccessesID] = useState([])
	const [subaccountsID, setSubaccountsID] = useState([])
	const [isActivatedAddSubaccounts, setIsActivatedAddSubaccounts] =
		useState(false)

	const getSubaccounts = async () => {
		const userID = localStorage.getItem("id_pasiolo")
		const results = await request.get(
			`datauserpasiololegal/?padre_id=${userID}`
		)
		setSubaccounts(
			results.filter((result) => result.id !== Number(userID))
		)
	}

	const getSubaccount = async (IDPacciolo) => {
		try {
			const account = await request.get(
				`https://help.redprocesal.net/api/datauserpasiololegal/${IDPacciolo}/`
			)
			setSubaccount(account)
		} catch (e) {
			setSubaccount({})
		}
	}

	const createSubaccounts = async ({ name, email, password }) => {
		const userID = localStorage.getItem("id_pasiolo")
		const { results } = await request.post("/subaccount/", {
			name,
			email,
			password,
			parent_id: userID,
		})
		console.log("create subaccounts", results)
		getSubaccounts()
	}

	const disabledSubaccount = async (subaccountID) => {
		try {
			await request.patch(`/userdata/${subaccountID}/`, {
				is_active: false,
			})
			getSubaccounts()
		} catch (err) {
			console.error(err)
		}
	}

	const enabledSubaccount = async (subaccountID) => {
		try {
			await request.patch(`/userdata/${subaccountID}/`, {
				is_active: true,
			})
			getSubaccounts()
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		setIsActivatedAddSubaccounts(
			proccessesID.length && subaccountsID.length
		)
	}, [proccessesID, subaccountsID])

	const toggleProcessesID = (processID) => {
		const index = proccessesID.findIndex((id) => processID === id)
		if (index === -1) {
			setProccessesID([...proccessesID, processID])
		} else {
			proccessesID.splice(index, 1)
			setProccessesID(proccessesID)
		}
	}

	const clearProcessesID = () => {
		setProccessesID([])
	}

	const replaceProcessesID = (proccessesID) => {
		setProccessesID(proccessesID)
	}

	const toggleSubaccountsID = (subaccountID) => {
		const index = subaccountsID.findIndex((id) => subaccountID === id)
		if (index === -1) {
			addSubaccountID(subaccountID)
		} else {
			removeSubaccountID(subaccountID)
		}
	}

	const addSubaccountID = (subaccountID) => {
		setSubaccountsID([...subaccountsID, subaccountID])
	}

	const replaceSubaccountsID = (subaccountID) => {
		setSubaccountsID(subaccountID)
	}

	const removeSubaccountID = (subaccountID) => {
		setSubaccountsID(
			subaccountsID.filter((id) => id !== subaccountID)
		)
	}

	const clearSubaccountsID = () => {
		setSubaccountsID([])
	}

	const populateSubaccountsInProccesses = (processes = []) => {
		// eslint-disable-next-line
		const activeProcesses = processes.filter((process) => {
			if (proccessesID.includes(process.id)) {
				process.subaccount = subaccountsID
				return process
			}
		})

		return activeProcesses
	}

	return useMemo(
		() => ({
			subaccount,
			subaccounts,
			getSubaccounts,
			createSubaccounts,
			proccessesID,
			toggleProcessesID,
			subaccountsID,
			toggleSubaccountsID,
			isActivatedAddSubaccounts,
			populateSubaccountsInProccesses,
			clearSubaccountsID,
			clearProcessesID,
			addSubaccountID,
			removeSubaccountID,
			replaceSubaccountsID,
			replaceProcessesID,
			disabledSubaccount,
			enabledSubaccount,
			getSubaccount,
		}),
		// eslint-disable-next-line
		[
			subaccount,
			subaccounts,
			proccessesID,
			subaccountsID,
			isActivatedAddSubaccounts,
		]
	)
}
