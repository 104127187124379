import { Box } from "@material-ui/core"
import { Button } from "common/components/inputs/basics"
import Modal from "common/components/modal"
import { useMainContext } from "common/context/mainContext/mainContext"
import React, { useEffect } from "react"
import AssignSubcount from "./AssignSubcount"

const EditProcessesSubaccounts = ({ isOpen, onClose, process }) => {
	const {
		subaccounts,
		replaceSubaccountsID,
		replaceProcessesID,
		populateSubaccountsInProccesses,
		clearSubaccountsID,
		clearProcessesID,
	} = useMainContext().subaccounts
	const { processes, updateMultipleProcesses } =
		useMainContext().process
	useEffect(() => {
		const includesSubaccounts = subaccounts.reduce(
			(accumulator, { id }) => {
				if (process.subaccount?.includes(String(id))) {
					accumulator = [...accumulator, id]
				}
				return accumulator
			},
			[]
		)
		replaceSubaccountsID(includesSubaccounts)
		replaceProcessesID([process.id])
		// eslint-disable-next-line
	}, [process])

	const updateSubaccount = () => {
		const _processes = populateSubaccountsInProccesses(processes)
		if (_processes.length) {
			updateMultipleProcesses(_processes)
			clearSubaccountsID()
			clearProcessesID()
			onClose()
		}
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<Box textAlign="center" marginBottom={5}>
				<h3>Subcuentas asignadas</h3>
			</Box>
			<AssignSubcount />
			<Box
				display="flex"
				justifyContent="space-between"
				marginTop={5}
			>
				<Button color="primary" onClick={updateSubaccount}>
					Actualizar Proceso
				</Button>
				<Button onClick={onClose}>Cancelar</Button>
			</Box>
		</Modal>
	)
}

export default EditProcessesSubaccounts
