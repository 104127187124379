import React, { useEffect, useState } from "react"
import Grid from "@material-ui/core/Grid"
import { Input } from "common/components/inputs/basics"
import { Button } from "common/components/inputs/basics"
import { useMainContext } from "common/context/mainContext/mainContext"
import moment from "moment"

const ClientsFilter = () => {

    const { getVSNotifications } = useMainContext().VSNotifications
    const currentDay = moment().format("YYYY-MM-DD")
    const [finalDate, setFinalDate] = useState(currentDay)
    const [initialDate, setInitialDate] = useState(currentDay)

    const VSNotificationsByDate = () => {
        getVSNotifications(initialDate, finalDate)
    }

    useEffect(() => {
        VSNotificationsByDate()
        // eslint-disable-next-line
    }, [])

	return (
		<Grid container spacing={3}>
			<Grid item xs={3}>
				<Input 
                    type="date" 
                    label="Fecha inicio" 
                    value={initialDate} 
                    onChange={ ({ target })=> setInitialDate(target.value) }
                />
			</Grid>
			<Grid item xs={3}>
				<Input 
                    type="date" 
                    label="Fecha final" 
                    value={finalDate} 
                    onChange={ ({ target })=> setFinalDate(target.value) }
                />
			</Grid>
			<Grid item xs={3}>
				<Button 
                    color="primary"
                    onClick={ VSNotificationsByDate }
                    disabled={ !initialDate.length || !finalDate.length }
                >Filtrar</Button>
			</Grid>
		</Grid>
	)
}

export default ClientsFilter
