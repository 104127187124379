import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Tooltip,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import LinkIcon from "@material-ui/icons/Link"
import PanToolIcon from "@material-ui/icons/PanTool"
import PublishIcon from "@material-ui/icons/Publish"
import { Input } from "common/components/inputs/basics"
import { useMainContext } from "common/context/mainContext/mainContext"
import useGuard from "common/hooks/useGuard"
import request from "common/plugins/request"
import { isValidUrl } from "containers/signUp/utils/actions"
import { useState } from "react"
import { FileUploader } from "react-drag-drop-files"

export const NotificationsActionsCell = ({
	id_notificacion,
	cup,
	url,
	auto_anotacion,
}) => {
	const { isSuperUser } = useGuard()
	const [openUploadAttached, setOpenUploadAttached] = useState(false)
	const [file, setFile] = useState(null)
	const handleChange = (file) => {
		setFile(file)
	}

	const { getNotificationAdmin, replaceNotificationUrl } =
		useMainContext().notifications

	const saveAutoNotification = async () => {
		try {
			const payload = new FormData()
			payload.append("auto", file)
			await request.put(
				`allnotifications/${id_notificacion}/`,
				payload
			)
			const notification = await getNotificationAdmin(id_notificacion)
			if (!notification) throw notification
			replaceNotificationUrl(notification.id, notification.url)
		} catch (error) {
			console.error(error)
		} finally {
			setOpenUploadAttached(false)
		}
	}

	return (
		<Box display="flex" justifyContent="center">
			{isSuperUser && (
				<Tooltip title="Subir archivo">
					<IconButton>
						<PublishIcon
							onClick={() => setOpenUploadAttached(true)}
							style={{ fontSize: "1.35rem" }}
						/>
					</IconButton>
				</Tooltip>
			)}

			{openUploadAttached && (
				<Dialog open fullWidth={500}>
					<DialogTitle>Subir Auto {cup}</DialogTitle>
					<DialogContent>
						<form>
							<FileUploader
								name="file"
								handleChange={handleChange}
								types={["PDF", "PNG", "JPEG", "JPG"]}
								onTypeError={(file) =>
									console.log("file error", file)
								}
								onSizeError={(file) =>
									console.log("file error", file)
								}
								maxSize={10}
								disabled={!!file}
							>
								<div
									style={{
										cursor: "pointer",
										borderStyle: "dashed",
										borderWidth: 2,
										borderRadius: 5,
										borderColor: "#ccc",
										color: "#737373",
										height: 200,
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									{!file ? (
										<>
											<div
												style={{
													position: "relative",
													display: "flex",
													justifyContent: "center",
													height: 65,
													marginTop: -15,
												}}
											>
												<PanToolIcon
													style={{
														top: "3.3rem",
														left: "calc(50% + 13px)",
														color: "#ccc",
														fontSize: "1.7rem",
														position: "absolute",
														transform:
															"translate(-50%, -50%) rotate(-32deg)",
														zIndex: 4,
													}}
												/>
												<InsertDriveFileIcon
													style={{
														fontSize: "3.8rem",
														position: "absolute",
														color: "inherit",
													}}
												/>
											</div>
											<p style={{ marginTop: 15, marginBottom: 15 }}>
												Arrastra o sube el documento
											</p>
											<div>
												<Button
													color="primary"
													variant="contained"
													style={{ marginRight: 5 }}
												>
													Subir documento
												</Button>
											</div>
										</>
									) : (
										<div
											style={{
												backgroundColor: "#d9d9d9",
												color: "#444444",
												height: "inherit",
												width: "100%",
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												justifyContent: "center",
												position: "relative",
											}}
										>
											<IconButton
												size="small"
												onClick={() => setFile(null)}
												style={{
													position: "absolute",
													top: 5,
													right: 5,
												}}
											>
												<CloseIcon />
											</IconButton>
											<InsertDriveFileIcon
												style={{
													fontSize: "3.8rem",
													color: "inherit",
													marginTop: -10,
												}}
											/>
											<p style={{ marginTop: 10 }}>{file.name}</p>
										</div>
									)}
								</div>
							</FileUploader>
							{!file && (
								<Box mt={2} display="flex">
									<Input
										placeholder="URL"
										inputProps={{ readOnly: true }}
									/>
									<Button
										color="primary"
										variant="outlined"
										onClick={(e) => {
											e.stopPropagation()
											navigator.clipboard
												.read()
												.then(async (items) => {
													const imageTypes = items[0].types
													for (const imageType of imageTypes) {
														const blob = await items[0].getType(
															imageType
														)
														const text = await blob.text()
														if (isValidUrl(text)) {
															// prettier-ignore
															// eslint-disable-next-line no-useless-escape
															const fileName = text.replace(/^.*[\\\/]/, '')
															const response = await fetch(text)
															const blob = await response.blob()
															const newFile = new File(
																[blob],
																fileName,
																{ type: blob.type }
															)
															handleChange(newFile)
														}
													}
												})
										}}
										style={{ width: 130, marginLeft: 5 }}
									>
										Pegar URL
									</Button>
								</Box>
							)}
						</form>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							onClick={() => setOpenUploadAttached(false)}
						>
							Cancelar
						</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={saveAutoNotification}
							disabled={!file}
						>
							Guardar
						</Button>
					</DialogActions>
				</Dialog>
			)}

			{!!url?.length && (
				<Tooltip title="Link">
					<IconButton>
						<LinkIcon
							onClick={() => window.open(url)}
							style={{ fontSize: "1.35rem" }}
						/>
					</IconButton>
				</Tooltip>
			)}
		</Box>
	)
}
