import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import FormMoneyManagement from "./components/formMoneyManagement"

const NewMoneyManagement = () => (
	<DashboardLayout>
		<MainProvider>
			<FormMoneyManagement />
		</MainProvider>
	</DashboardLayout>
)

export default NewMoneyManagement
