import { Box } from "@material-ui/core"
import { Button } from "common/components/inputs/basics"
import TabsBasic from "common/components/tabs"
import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import ProcessData from "containers/sumaryProcess/components/processData"
import { useState } from "react"
import { useHistory, useParams } from "react-router"
import ProcessPrivateData from "./components/processPrivateData"

const SummaryProcess = () => {
	const { id } = useParams()
	const history = useHistory()

	const [activeTab, setActiveTab] = useState(0)
	const optionComponents = [<ProcessData />, <ProcessPrivateData />]

	const redirectToUpdate = () => {
		history.push(`/processes/${id}/update`)
	}

	return (
		<DashboardLayout>
			<MainProvider>
				<Box marginBottom={3}>
					<TabsBasic
						value={activeTab}
						handleChange={(_, index) => setActiveTab(index)}
						tabs={["DATOS DEL PROCESO", "DATOS PRIVADOS DEL PROCESO"]}
					/>
				</Box>
				{optionComponents[activeTab]}
				<Box marginTop={5}>
					<Button color="primary" onClick={redirectToUpdate}>
						Editar Datos
					</Button>
				</Box>
			</MainProvider>
		</DashboardLayout>
	)
}

export default SummaryProcess
