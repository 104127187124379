import { Box } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { Input } from "common/components/inputs/basics"
import { Button } from "common/components/inputs/basics"
import { DataTable } from "common/components/table"
import React from "react"
import CardReportData from "./components/cardReportData"

const DetailReport = () => (
	<>
		<Grid container spacing={5}>
			<Grid item xs={12} style={{ position: "relative" }}>
				<CardReportData />
			</Grid>
			<Grid item xs={12} md={6}>
				<DataTable
					title="Demandantes"
					headers={["Cédula", "Nombre"]}
					titleBackground={false}
					data={[]}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<DataTable
					title="Demandado"
					headers={["Cédula", "Nombre"]}
					titleBackground={false}
					data={[]}
				/>
			</Grid>

			<Grid item xs={12}>
				<Input
					rows={3}
					multiline
					name="hechos"
					label="Hechos Resumen"
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<DataTable
					title="Pretensiones"
					headers={["Pretensiones"]}
					titleBackground={false}
					data={[]}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<DataTable
					title="Pretensiones Economicas"
					headers={["Descripcion", "Valor"]}
					titleBackground={false}
					data={[]}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<DataTable
					title="Cronologia del Proceso"
					headers={["Fecha", "Parte", "Actuación", "Adjunto"]}
					titleBackground={false}
					data={[]}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<DataTable
					title="Pendientes"
					headers={["Fecha Límite", "Responsable", "Actividad"]}
					titleBackground={false}
					data={[]}
				/>
			</Grid>

			<Grid item xs={12}>
				<Input
					rows={3}
					multiline
					name="hechos"
					label="Observaciones"
				/>
			</Grid>
		</Grid>
		<Box display="flex" justifyContent="space-between" marginTop={5}>
			<Box>
				<Button color="primary" style={{ marginRight: 10 }}>Imprimir</Button>
				<Button color="primary">Generar pdf</Button>
			</Box>
			<Button>Salir</Button>
		</Box>
	</>
)

export default DetailReport
