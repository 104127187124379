import {
	Box,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from "@material-ui/core"
import { useMainContext } from "common/context/mainContext/mainContext"
import React from "react"

const useStyles = makeStyles((theme) => ({
	title_table: {
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		backgroundColor: theme.palette.primary.main,
		padding: 15,
		color: theme.palette.light,
		fontWeight: 500,
	},
}))

const RPNotificationsTable = () => {
	const classes = useStyles()
	const { RPNotifications } = useMainContext().VSNotifications

	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	return (
		<>
			<Box className={classes.title_table}>
				Notificaciones Red Procesal
			</Box>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell width="15%" align="center">
								Despacho
							</TableCell>
							<TableCell width="15%" align="center">
								Actuación
							</TableCell>
							<TableCell width="15%" align="center">
								Radicado
							</TableCell>
							<TableCell width="15%" align="center">
								Demandante
							</TableCell>
							<TableCell width="15%" align="center">
								Demandado
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? RPNotifications?.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
							  )
							: RPNotifications
						).map(
							(
								{ numero, nombre, resumen, radicado, ddo, dte },
								index
							) => (
								<TableRow key={index}>
									<TableCell align="center">
										{`${nombre} ${String(numero).padStart(3, "0")}`}
									</TableCell>
									<TableCell align="center">{resumen}</TableCell>
									<TableCell align="center">{radicado}</TableCell>
									<TableCell align="center">{dte}</TableCell>
									<TableCell align="center">{ddo}</TableCell>
								</TableRow>
							)
						)}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[10, 20, 50, 100]}
					component="div"
					count={RPNotifications.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Filas por pagina"
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} de ${count}`
					}
				/>
			</TableContainer>
		</>
	)
}
export default RPNotificationsTable
