import { Box, Paper } from "@material-ui/core"
import { DataGrid } from "common/components/DataGrid"
import { UcFirst } from "containers/signUp/utils/UcFirst"
import moment from "moment"
import { useMemo, useState } from "react"
import { NotificationsActionsCell } from "./NotificationsActionsCell"

const {
	useMainContext,
} = require("common/context/mainContext/mainContext")
const { useEffect } = require("react")

const NotificationsTableAdmin = () => {
	const { notifications: notificationsCtx } = useMainContext()
	const { notifications, qtyNotifications, getNotifications } =
		notificationsCtx

	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	})

	useEffect(() => {
		getNotifications()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const columns = useMemo(
		() => [
			{
				accessorKey: "fecha_creacion",
				header: "Fecha",
				size: 150,
				Cell: ({ renderedCellValue: fecha_registro }) =>
					moment(fecha_registro).format("YYYY-MM-DD"),
			},
			{
				accessorKey: "razon_social",
				header: "Razón Social",
			},
			{
				accessorKey: "cup",
				header: "Cup",
				maxSize: 100,
			},
			{
				accessorKey: "office",
				header: "Despacho",
				size: 400,
				Cell: ({ renderedCellValue: office }) => UcFirst(office),
			},
			{
				accessorKey: "dte",
				header: "Demandante",
				Cell: ({ renderedCellValue: dte }) => UcFirst(dte),
			},
			{
				accessorKey: "ddo",
				header: "Demandado",
				Cell: ({ renderedCellValue: ddo }) => UcFirst(ddo),
			},
			{
				accessorKey: "auto_anotacion",
				header: "Anotación",
				Cell: ({ renderedCellValue: auto_anotacion }) =>
					UcFirst(auto_anotacion),
			},

			{
				accessorKey: "url",
				header: "Acciones",
				size: 100,
				Cell: ({ row: { original } }) => (
					<NotificationsActionsCell {...original} />
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[notifications]
	)

	useEffect(() => {
		getNotifications({
			offset: pagination.pageIndex * pagination.pageSize,
			limit: pagination.pageSize,
		})
		// eslint-disable-next-line
	}, [pagination])

	return (
		<Box style={{ width: "100%" }} component={Paper}>
			<DataGrid
				columns={columns}
				data={notifications}
				rowCount={qtyNotifications}
				enablePagination={true}
				pagination={pagination}
				onPaginationChange={setPagination}
			/>
		</Box>
	)
}

export default NotificationsTableAdmin
