import {
	Box,
    makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core"
import VisibilityIcon from '@material-ui/icons/Visibility';
import LockIcon from '@material-ui/icons/Lock';
import React from "react"

const TableBoxMoney = () => {
	const classes = useStyles()
	return (
		<>
			<Box className={classes.title_table}>Lista de Cajas</Box>
			<TableContainer component={Paper}>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell width="30%" align="center">Nombre</TableCell>
                            <TableCell width="50%" align="center">Descripción</TableCell>
                            <TableCell align="center">Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
                        <TableRow>
                            <TableCell>CARRO</TableCell>
                            <TableCell>Carro con placas tales y tales</TableCell>
                            <TableCell align="center">
                                <LockIcon style={{ marginRight: 10 }}/>
                                <VisibilityIcon />
                            </TableCell>
                        </TableRow>
                    </TableBody>
				</Table>
			</TableContainer>
		</>
	)
}

const useStyles = makeStyles((theme) => ({
	title_table: {
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		backgroundColor: theme.palette.primary.main,
		padding: 15,
		color: theme.palette.light,
		fontWeight: 500,
	},
}))

export default TableBoxMoney
