import { Box, Grid } from "@material-ui/core"
import {
	Autocomplete,
	Button,
	Input,
} from "common/components/inputs/basics"
import { useMainContext } from "common/context/mainContext/mainContext"
import { useEffect } from "react"
import { useFormCompany } from "../hooks/useFormCompany"

const FormCompany = ({ onSubmit }) => {
	const {
		provinces = [],
		getCities,
		cities = [],
	} = useMainContext().address

	const formSingUp = useFormCompany({
		onSubmit,
	})

	useEffect(() => {
		if (formSingUp.values.departamento && provinces.length) {
			getCities(formSingUp.values.departamento)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formSingUp.values.departamento, provinces])

	const goToHome = () => (window.location.href = "/")

	return (
		<form
			noValidate
			autoComplete="off"
			onSubmit={formSingUp.handleSubmit}
		>
			<Grid container spacing={4}>
				<Grid item xs={12} md={6}>
					<Input
						name="nit"
						type="number"
						label="Nit"
						onChange={formSingUp.handleChange}
						value={formSingUp.values.nit}
						errorText={formSingUp.errors.nit}
						error={formSingUp.touched.nit && !!formSingUp.errors.nit}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						type="text"
						label="Razón Social"
						name="razonsocial"
						onChange={formSingUp.handleChange}
						value={formSingUp.values.razonsocial}
						errorText={formSingUp.errors.razonsocial}
						error={
							formSingUp.touched.razonsocial &&
							!!formSingUp.errors.razonsocial
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Autocomplete
						label="Departamento"
						name="departamento"
						options={provinces}
						onChange={(_, province) =>
							formSingUp.setFieldValue("departamento", province)
						}
						getOptionLabel={(province) => (province ? province : "")}
						errorText={formSingUp.errors.departamento}
						error={
							formSingUp.touched.departamento &&
							!!formSingUp.errors.departamento
						}
						value={formSingUp.values.departamento}
						disabled={!provinces?.length}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Autocomplete
						label="Municipio"
						name="municipio"
						options={cities}
						onChange={(_, city) =>
							formSingUp.setFieldValue("municipio", city)
						}
						getOptionLabel={(city) => (city ? city : "")}
						errorText={formSingUp.errors.municipio}
						error={
							formSingUp.touched.municipio &&
							!!formSingUp.errors.municipio
						}
						value={formSingUp.values.municipio}
						disabled={!cities?.length}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						type="text"
						label="Dirección"
						name="direccion"
						onChange={formSingUp.handleChange}
						value={formSingUp.values.direccion}
						errorText={formSingUp.errors.direccion}
						error={
							formSingUp.touched.direccion &&
							!!formSingUp.errors.direccion
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						type="text"
						label="Barrio"
						name="barrio"
						onChange={formSingUp.handleChange}
						value={formSingUp.values.barrio}
						errorText={formSingUp.errors.barrio}
						error={
							formSingUp.touched.barrio && !!formSingUp.errors.barrio
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						type="text"
						label="Celular"
						name="celular"
						onChange={formSingUp.handleChange}
						value={formSingUp.values.celular}
						errorText={formSingUp.errors.celular}
						error={
							formSingUp.touched.celular &&
							!!formSingUp.errors.celular
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						type="text"
						label="Teléfono Fijo (opcional)"
						name="telefonofijo"
						onChange={formSingUp.handleChange}
						value={formSingUp.values.telefonofijo}
						errorText={formSingUp.errors.telefonofijo}
						error={
							formSingUp.touched.telefonofijo &&
							!!formSingUp.errors.telefonofijo
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						type="email"
						name="email"
						label="Correo Electrónico"
						onChange={formSingUp.handleChange}
						value={formSingUp.values.email}
						errorText={formSingUp.errors.email}
						error={
							formSingUp.touched.email && !!formSingUp.errors.email
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						name="username"
						label="Usuario"
						onChange={formSingUp.handleChange}
						value={formSingUp.values.username}
						errorText={formSingUp.errors.username}
						error={
							formSingUp.touched.username &&
							!!formSingUp.errors.username
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						type="password"
						name="password"
						label="Contraseña"
						onChange={formSingUp.handleChange}
						value={formSingUp.values.password}
						errorText={formSingUp.errors.password}
						error={
							formSingUp.touched.password &&
							!!formSingUp.errors.password
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						type="password"
						name="validatePassword"
						label="Repetir Contraseña"
						onChange={formSingUp.handleChange}
						value={formSingUp.values.validatePassword}
						errorText={formSingUp.errors.validatePassword}
						error={
							formSingUp.touched.validatePassword &&
							!!formSingUp.errors.validatePassword
						}
					/>
				</Grid>

				<Grid item xs={12}>
					<Box display="flex" justifyContent="end" marginTop={3}>
						<Box marginRight={3}>
							<Button onClick={goToHome}>Cancelar</Button>
						</Box>
						<Button color="primary" type="submit">
							Siguiente
						</Button>
					</Box>
				</Grid>
			</Grid>
		</form>
	)
}

export default FormCompany
