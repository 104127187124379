import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import React, { useEffect, useState } from "react"
import { Box, makeStyles } from "@material-ui/core"
import esLocale from "@fullcalendar/core/locales/es"
import interactionPlugin from "@fullcalendar/interaction"
import listPlugin from "@fullcalendar/list"
import GenerateEvent from "./generateEvent"
import { useMainContext } from "common/context/mainContext/mainContext"
import moment from "moment"

const CalendarEvents = () => {
	const classes = useStyles()
	const [activeEvent, setActiveEvent] = useState(null)
	const [isNewEvent, setIsNewEvent] = useState(false)

	const { events, getEvents } = useMainContext().eventsCalendar
	//const { updateEvent } = useMainContext().eventsCalendar

	useEffect(() => {
		getEvents()
		// eslint-disable-next-line
	}, [])

	return (
		<Box className={classes.container}>
			<FullCalendar
				locale={esLocale}
				timeZone="America/Bogota"
				plugins={[
					interactionPlugin,
					dayGridPlugin,
					timeGridPlugin,
					listPlugin,
				]}
				initialView="timeGridWeek"
				businessHours={true}
				headerToolbar={{
					left: "prev,next today",
					center: "title",
					right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
				}}
				slotLabelFormat={{
					hour: "2-digit",
					minute: "2-digit",
					meridiem: "short",
				}}
				nowIndicator={true}
				dayMaxEvents={true}
				selectable={true}
				select={({ startStr, endStr }) => {
					setIsNewEvent(true)
					setActiveEvent({ start: startStr, end: endStr })
				}}
				eventClick={({ event }) => {
					setIsNewEvent(false)
					const eventItem = events.find(
						({ id }) => Number(event.id) === Number(id)
					)
					setActiveEvent(eventItem)
				}}
				eventDrop={({ event, view }) => {
					const formatDate = "YYYY-MM-DD HH:mm"
					const eventItem = events.find(
						({ id }) => Number(event.id) === Number(id)
					)
					console.log(event.start, event.end)
					eventItem.startDate = moment(event.start).format(formatDate)
					eventItem.endtDate = moment(event.end).format(formatDate)
					console.log("good: ", event.id, eventItem)
					//updateEvent(event.id, eventItem)
				}}
				eventDragStop={({ event }) => {
					console.log(event.start, event.end)
				}}
				editable={true}
				events={events}
			/>
			{activeEvent && (
				<GenerateEvent
					activeEvent={activeEvent}
					clearActiveEvent={() => setActiveEvent(null)}
					typeAction={isNewEvent ? "create" : "update"}
				/>
			)}
		</Box>
	)
}

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.palette.light,
		borderRadius: 8,
		padding: 30,
		"& > button": {
			backgroundColor: theme.palette.primary.light,
		},
	},
}))

export default CalendarEvents
