import { Box, makeStyles } from "@material-ui/core"
import ActionSteps from "common/components/actionSteps"
import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import { useState } from "react"
import ContentSteps from "./components/contentSteps"

const useStyles = makeStyles((theme) => ({
	contentSteps: {
		width: 380,
		maxWidth: "100%",
		margin: "0 auto",
	},
}))

const UpdateProcess = () => {
	const classes = useStyles()
	const [activeStep, setActiveStep] = useState(0)
	const qtySteps = 3

	const handleStep = (index) => {
		setActiveStep(index)
	}
	return (
		<DashboardLayout>
			<Box maxWidth={800}>
				<MainProvider>
					<div className={classes.contentSteps}>
						<ActionSteps
							steps={qtySteps}
							activeStep={activeStep}
							handleStep={handleStep}
							nonLinear={true}
						/>
					</div>
					<ContentSteps
						steps={qtySteps}
						activeStep={activeStep}
						handleStep={handleStep}
					/>
				</MainProvider>
			</Box>
		</DashboardLayout>
	)
}

export default UpdateProcess
