import { TableCell, TableRow } from "@material-ui/core"
import { useMainContext } from "common/context/mainContext/mainContext"
import { useEffect, useState } from "react"

const AssignedProcessesTableRowHead = () => (
	<TableRow>
		<TableCell width="10%" align="center">
			Radicado
		</TableCell>
		<TableCell width="15%" align="center">
			Demandante
		</TableCell>
		<TableCell width="15%" align="center">
			Demandado
		</TableCell>
		<TableCell width="10%" align="center">
			Asignado
		</TableCell>
	</TableRow>
)

const AssignedProcessesTableRowBody = ({ row, onRowClick }) => {
	const { subaccounts } = useMainContext().subaccounts
	const [subaccountsInProcess, setSubaccountsInProcess] = useState([])

	useEffect(() => {
		if (row.subaccount) {
			setSubaccountsInProcess(
				subaccounts.filter(({ id }) =>
					row.subaccount?.includes(String(id))
				)
			)
		}
		// eslint-disable-next-line
	}, [row])

	return (
		<TableRow onClick={(e) => onRowClick(e, row)}>
			<TableCell align="center">{row.radicado}</TableCell>
			<TableCell>{row.dte}</TableCell>
			<TableCell>{row.ddo}</TableCell>
			<TableCell align="center">
				<ul style={{ lineHeight: 1.8 }}>
					{subaccountsInProcess.map(({ razonsocial }) => (
						<li>{razonsocial}</li>
					))}
				</ul>
			</TableCell>
		</TableRow>
	)
}

export {
	AssignedProcessesTableRowHead,
	AssignedProcessesTableRowBody,
}
