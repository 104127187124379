import {
	Card,
	CardContent,
	Grid,
	makeStyles,
} from "@material-ui/core"
import React from "react"

const useStyles = makeStyles((theme) => ({
	cardContent: {
		fontSize: 13,
		padding: "5px 16px",
		"& p": { padding: 6 },
		"& strong": {
			color: theme.palette.darkGray,
			marginRight: 5,
		},
	},
	titleCard: {
		paddingTop: 5,
		paddingBottom: 10,
		fontWeight: 600,
		fontSize: 14,
		color: theme.palette.primary.main,
	},
}))

const CardProcessData = ({ process = {} }) => {
	const classes = useStyles()

	return (
		<Card className={classes.card}>
			<CardContent className={classes.cardContent}>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6} md={3}>
						<h3 className={classes.titleCard}>CUP / Radicado</h3>
						{process?.cup && (
							<p>
								<strong>{process.cup}</strong>
								<br />
								<strong>
									{process.ano_del_Proceso}-{process.consecutivo}
								</strong>
							</p>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<h3 className={classes.titleCard}>
							Despacho donde cursa el proceso
						</h3>
						<p>
							<strong>{process.despacho_cursa_proceso}</strong>
						</p>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<h3 className={classes.titleCard}>Tipo de Proceso</h3>
						<p>
							<strong>{process.tipo_de_proceso}</strong>
						</p>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<h3 className={classes.titleCard}>Tipo de parte</h3>
						{process.tipodeparte && (
							<p>
								<strong>
									{process.tipodeparte === "DEMANDANTE"
										? "Demandante"
										: ""}
									{process.tipodeparte === "DEMANDADO"
										? "Demandado"
										: ""}
									{process.tipodeparte === "INTERVINIENTE"
										? "Interviniente"
										: ""}
								</strong>
							</p>
						)}
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<h3 className={classes.titleCard}>Demandante</h3>
						<p>
							<strong>{process.dte}</strong>
						</p>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<h3 className={classes.titleCard}>Demandado</h3>
						<p>
							<strong>{process.ddo}</strong>
						</p>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<h3 className={classes.titleCard}>Apoderado</h3>
						<p>
							<strong>
								{process.tipodeparte === "DEMANDANTE"
									? process?.detalle_del_proceso?.data_abogadodte[0]
											?.nombre
									: ""}
								{process.tipodeparte === "DEMANDADO"
									? process?.detalle_del_proceso?.data_abogadoddo[0]
											?.nombre
									: ""}
							</strong>
						</p>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<h3 className={classes.titleCard}>Responsable</h3>
						{!!process?.detalle_del_proceso?.datos_responsable
							?.length && (
							<p>
								<strong>
									{
										process?.detalle_del_proceso?.datos_responsable[0]
											?.nombre
									}
								</strong>
							</p>
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}
export default CardProcessData
