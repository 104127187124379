import request from "common/plugins/request"
import { useMemo, useState } from "react"

export const useVSNotifications = ()=> {
    const [VSNotificationsDate, setVSNotificationsDate] = useState({})
    const [VSNotifications, setVSNotifications] = useState([])
    const [PSNotifications, setPSNotifications] = useState([])
    const [RPNotifications, setRPNotifications] = useState([])

    const getVSNotifications = async (initialDate, finalDate) => {
        try{
            const { result_compare = [] } = await request.get("notificationclient/reportnotifications/" , {
                params: { fechamenor: initialDate, fechamayor: finalDate }
            })
            setVSNotificationsDate({ initialDate, finalDate })
            setVSNotifications(result_compare)
        } catch(err){
            setVSNotifications([])
            throw err;
        }
    }

    const getVSNotification = async (count, fromDate, toDate) => {
        try{
            const { results_pasiololegal, results_redprocesal } = await request.get("notificationclient/compare/", {
                params: {
                    cuenta: count,
                    fechamenor: fromDate,
                    fechamayor: toDate
                }
            })
            setPSNotifications(results_pasiololegal)
            setRPNotifications(results_redprocesal)
        } catch(err){
            setPSNotifications([])
            setRPNotifications([])
            throw err;
        }
    }

	return useMemo(
		() => ({
            VSNotificationsDate,
            getVSNotifications,
            getVSNotification,
            VSNotifications,
            PSNotifications,
            RPNotifications
		}),
		// eslint-disable-next-line
		[VSNotifications, PSNotifications, RPNotifications]
	)

}