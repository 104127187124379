import { makeStyles } from "@material-ui/core"
import HomeIcon from "@material-ui/icons/Home"
import { Button } from "common/components/inputs/basics"
import { useHistory } from "react-router"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100vw",
		height: "100vh",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		position: "fixed",
		overflow: "hidden",
		left: 0,
		top: 0,
		backgroundColor: theme.palette.primary.main,
		zIndex: 1000000,
	},
	title: {
		color: theme.palette.light,
		fontSize: "4rem",
		[theme.breakpoints.down("md")]: {
			fontSize: "3rem",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "2rem",
		},
	},
	button: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.light,
		textDecoration: "none",
		fontSize: ".95rem",
		fontWeight: 600,
		marginTop: 25,
		display: "flex",
		alignItems: "center",
		padding: 10,
		paddingLeft: 15,
		paddingRight: 15,
		[theme.breakpoints.down("md")]: {
			fontSize: ".8rem",
			marginTop: 15,
		},
	},
	iconButton: {
		fontSize: "1.6rem",
		marginRight: 5,
		[theme.breakpoints.down("md")]: {
			marginRight: 3,
			fontSize: "1.2rem",
		},
	},
}))

const Building = () => {
	const classes = useStyles()
	const history = useHistory()

	const redirectToHome = () => history.push(`/`)

	return (
		<div className={classes.root}>
			<h1 className={classes.title}>Próximamente...</h1>
			<Button onClick={redirectToHome} className={classes.button}>
				<HomeIcon className={classes.iconButton} />
				Regresar al Inicio
			</Button>
		</div>
	)
}

export default Building
