import EditLoggedUser from "containers/user/EditLoggedUser"
import { Fragment } from "react"
import { Route } from "react-router-dom"
import { Routes } from "router/routes"

export default function Routers() {
	return (
		<>
			{Object.values(Routes).map(
				({ path, component, children }, index) => (
					<Fragment key={index}>
						<Route exact {...{ component, path }}></Route>
						{children
							? Object.values(children).map(
									({ path, component }, subindex) => (
										<Route
											exact
											{...{ component, path }}
											key={`${index}${subindex}`}
										></Route>
									)
							  )
							: null}
					</Fragment>
				)
			)}
			<Route path="/user/update" component={EditLoggedUser}></Route>
		</>
	)
}
