import { Box, makeStyles } from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"
import DoneAllIcon from "@material-ui/icons/DoneAll"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import { Button } from "common/components/inputs/basics"
import { useMainContext } from "common/context/mainContext/mainContext"
import useCreateNotification from "common/hooks/useCreateNotification"
import { useEffect, useState } from "react"
import MediaQuery from "react-responsive"
import { useParams } from "react-router"

const ActionsButtons = ({
	formData,
	data: mapData,
	stepControls,
}) => {
	const classes = useStyles()
	const { id } = useParams()
	const { isUpdated, process, changeProcess, updateProcess } =
		useMainContext().process
	const { createNotifications } = useCreateNotification()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [actionNotifications, setActionNotifications] =
		useState(false)

	const validateForm = () => {
		const validation = formData.isValid
		if (!validation) {
			formData.submitForm()
		}
		return validation
	}

	useEffect(() => {
		if (isSubmitting) {
			updateProcess(id)
			createNotifications(actionNotifications[0], id)
		}
		// eslint-disable-next-line
	}, [process])

	useEffect(() => {
		if (isUpdated) {
			stepControls.finish()
		}
		// eslint-disable-next-line
	}, [isUpdated])

	const changeDataProcess = () => {
		const { process, actions } = mapData(formData.values)
		changeProcess(process)
		if (actions) {
			setActionNotifications(actions)
		}
	}

	const nextStep = () => {
		if (validateForm()) {
			changeDataProcess()
			stepControls.next()
		}
	}

	const backStep = () => {
		if (validateForm()) {
			changeDataProcess()
			stepControls.back()
		}
	}

	const finishStep = () => {
		if (validateForm()) {
			changeDataProcess()
			setIsSubmitting(true)
		}
	}

	return (
		<Box marginRight={5} className={classes.root}>
			<Button onClick={() => stepControls.cancel()}>
				<CancelIcon />
				<MediaQuery minDeviceWidth={800}>Cancelar</MediaQuery>
			</Button>
			<div>
				{stepControls.hasBack && (
					<Button type="button" color="primary" onClick={backStep}>
						<NavigateBeforeIcon />
						<MediaQuery minDeviceWidth={800}>Anterior</MediaQuery>
					</Button>
				)}
				{stepControls.hasNext && (
					<Button type="button" color="primary" onClick={nextStep}>
						<MediaQuery minDeviceWidth={800}>Siguiente</MediaQuery>
						<NavigateNextIcon />
					</Button>
				)}
				<Button type="button" color="primary" onClick={finishStep}>
					<MediaQuery minDeviceWidth={800}>Guardar</MediaQuery>
					<DoneAllIcon />
				</Button>
			</div>
		</Box>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		margin: 0,
		"& button": {
			marginRight: 10,
			paddingRight: "25px",
			paddingLeft: "25px",
			"&:last-child": {
				marginRight: 0,
			},
		},
	},
}))

export default ActionsButtons
