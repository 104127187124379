import { Box, IconButton, Paper, Tooltip } from "@material-ui/core"
import LinkIcon from "@material-ui/icons/Link"
import { DataGrid } from "common/components/DataGrid"
import useGuard from "common/hooks/useGuard"
import { UcFirst } from "containers/signUp/utils/UcFirst"
import { useMemo, useState } from "react"

const {
	useMainContext,
} = require("common/context/mainContext/mainContext")
const { useEffect } = require("react")

const AllNotificationsTable = () => {
	const { notifications: notificationsCtx } = useMainContext()
	const {
		notificationsAdmin,
		qtyNotificationsAdmin,
		getNotificationsAdmin,
	} = notificationsCtx

	const { isSuperUser } = useGuard()

	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	})

	useEffect(() => {
		getNotificationsAdmin()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const columns = useMemo(
		() => [
			...(isSuperUser && [
				{
					accessorKey: "clientes",
					header: "Clientes",
					Cell: ({ renderedCellValue: clients }) =>
						clients.join(", "),
				},
			]),
			{
				accessorKey: "fecha_registro",
				header: "Fecha",
				size: 150,
				Cell: ({ renderedCellValue: fecha_registro }) =>
					fecha_registro,
			},
			{
				accessorKey: "cup",
				header: "Cup",
				maxSize: 100,
			},
			{
				accessorKey: "office",
				header: "Despacho",
				size: 400,
				Cell: ({ renderedCellValue: office }) => UcFirst(office),
			},
			{
				accessorKey: "dte",
				header: "Demandante",
				Cell: ({ renderedCellValue: dte }) => UcFirst(dte),
			},
			{
				accessorKey: "ddo",
				header: "Demandado",
				Cell: ({ renderedCellValue: ddo }) => UcFirst(ddo),
			},
			{
				accessorKey: "auto_anotacion",
				header: "Anotación",
				Cell: ({ renderedCellValue: auto_anotacion }) =>
					UcFirst(auto_anotacion),
			},

			{
				accessorKey: "url",
				header: "Documento",
				size: 100,
				Cell: ({ renderedCellValue: url }) => (
					<Box display="flex" justifyContent="center">
						{!!url?.length && (
							<Tooltip title="Link">
								<IconButton>
									<LinkIcon
										onClick={() => window.open(url)}
										style={{ fontSize: "1.35rem" }}
									/>
								</IconButton>
							</Tooltip>
						)}
					</Box>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[notificationsAdmin]
	)

	useEffect(() => {
		getNotificationsAdmin({
			offset: pagination.pageIndex * pagination.pageSize,
			limit: pagination.pageSize,
		})
		// eslint-disable-next-line
	}, [pagination])

	return (
		<Box style={{ width: "100%" }} component={Paper}>
			<DataGrid
				columns={columns}
				data={notificationsAdmin}
				rowCount={qtyNotificationsAdmin}
				enablePagination={true}
				pagination={pagination}
				onPaginationChange={setPagination}
			/>
		</Box>
	)
}

export default AllNotificationsTable
