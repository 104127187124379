import { Box } from "@material-ui/core"
import TabsBasic from "common/components/tabs"
import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import { useState } from "react"
import GenerateReports from "./components/generateReports"
import ShowReports from "./components/showReports"

const Reports = () => {
	const [activeTab, setActiveTab] = useState(0)
	const reportViews = [<GenerateReports />, <ShowReports />]
	return (
		<DashboardLayout>
			<MainProvider>
				<Box marginBottom={6}>
					<TabsBasic
						value={activeTab}
						handleChange={(_, index) => setActiveTab(index)}
						tabs={["Generar Informes", "Ver Informes"]}
					/>
				</Box>
				{reportViews[activeTab]}
			</MainProvider>
		</DashboardLayout>
	)
}

export default Reports
