import { useMainContext } from "common/context/mainContext/mainContext"
import { useState } from "react"

const useOffices = () => {
	const [offices, setOffices] = useState([])
	const { offices: officesServices } = useMainContext()

	const getOffices = (activeCity) => {
		officesServices.getOffices(activeCity).then((_offices) => {
			const availableOffices = _offices.reduce(
				(acc, office) =>
					office?.nombre_publico ? [...acc, office] : acc,
				[]
			)
			setOffices(availableOffices)
		})
	}

	const clearOffices = () => {
		setOffices([])
	}

	return { offices, getOffices, clearOffices }
}

export default useOffices
