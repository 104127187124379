import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import VSNotificationsTables from "./components/VSNotificationsTables"

const VSNotificationDetail = () => {
	return (
		<DashboardLayout>
			<MainProvider>
				<VSNotificationsTables />
			</MainProvider>
		</DashboardLayout>
	)
}

export default VSNotificationDetail
