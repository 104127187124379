import Step from "@material-ui/core/Step"
import StepButton from "@material-ui/core/StepButton"
import Stepper from "@material-ui/core/Stepper"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "inherit",
	},
}))

const ActionSteps = ({
	activeStep = 0,
	handleStep,
	steps = 0,
	nonLinear = false,
}) => {
	const classes = useStyles()
	return (
		<Stepper
			nonLinear={nonLinear}
			activeStep={activeStep}
			className={classes.root}
		>
			{Array.from({ length: steps }).map((label, index) => (
				<Step key={label}>
					<StepButton
						onClick={() => handleStep(index)}
						completed={activeStep > index}
					></StepButton>
				</Step>
			))}
		</Stepper>
	)
}

export default ActionSteps
