import { Box } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import { Button } from "common/components/inputs/basics"
import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import TableBoxMoney from "./components/tableBoxMoney"

const MoneyManagement = () => (
	<DashboardLayout>
		<MainProvider>
			<Box marginBottom={3} display="flex" justifyContent="flex-end">
				<Button color="primary">
					<AddIcon style={{ marginRight: 5 }}>Add</AddIcon>
					Nueva Caja
				</Button>
			</Box>
			<TableBoxMoney />
		</MainProvider>
	</DashboardLayout>
)

export default MoneyManagement
