import { useMainContext } from "common/context/mainContext/mainContext"
import { useCallback, useState } from "react"

const useNotificationsCUP = (CUP) => {
	const [notifications, setNotifications] = useState([])
	const { notifications: notificationService } = useMainContext()

	const getNotificationsCUP = useCallback(
		(CUP) => {
			notificationService
				.getNotificationsByCUP(CUP)
				.then((_notifications) => {
					setNotifications(_notifications)
				})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[CUP]
	)

	return { notifications, getNotificationsCUP }
}

export default useNotificationsCUP
