import {
	Box,
	Dialog,
	DialogContent,
	Typography,
} from "@material-ui/core"
import { blue, green, orange, red } from "@material-ui/core/colors"
import { CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon } from "@material-ui/icons"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined"

const AlertMessage = ({
	type, //'error' | 'success' | 'info' | 'warning';
	title,
	message,
	...dialogProps
}) => (
	<Dialog {...dialogProps}>
		<DialogContent>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				padding={4}
			>
				{type === "success" && (
					<Box color={green[400]} fontSize="4rem">
						<CheckCircleOutlineOutlinedIcon fontSize="inherit" />
					</Box>
				)}

				{type === "info" && (
					<Box
						fontSize="4rem"
						color={blue[200]}
						style={{ translate: "rotate(180deg)" }}
					>
						<ErrorOutlineIcon fontSize="inherit" />
					</Box>
				)}

				{type === "warning" && (
					<Box color={orange[400]} fontSize="4rem">
						<ReportProblemOutlinedIcon fontSize="inherit" />
					</Box>
				)}

				{type === "error" && (
					<Box color={red[400]} fontSize="4rem">
						<ErrorOutlineIcon fontSize="inherit" />
					</Box>
				)}

				<Box pb={1}>
					<Typography variant="body-m" fontWeight="medium">
						{title}
					</Typography>
				</Box>
				<Typography>{message}</Typography>
			</Box>
		</DialogContent>
	</Dialog>
)

export default AlertMessage
