import { Grid, Switch } from "@material-ui/core"
import { Input } from "common/components/inputs/basics"
import FuncionalityDataTable from "common/components/table/funcionalityDataTable"
import Title from "common/components/text/Title"
import React from "react"
import * as Yup from "yup"

const ProcessResponsible = ({ data, changeData }) => {
	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required("Requerido"),
	})
	return (
		<>
			<Title size="h4" marginBottom={0.5}>
				Responsables del Proceso
			</Title>

			<FuncionalityDataTable
				headers={["Cédula", "Nombre", "Desde", "Estado"]}
				initialValues={{ cedula: "", nombre: "", desde: "", activo: false }}
				onChangeData={(newData) => changeData(newData)}
				typesValues={{ cedula: "currency"}}
				validationSchema={validationSchema}
				data={data}
			>
				{({ handleValue, setFieldValue, values, errors, touched }) => (
					<Grid container spacing={3}>
						<Grid item xs={6} md={3}>
							<Input
								name="cedula"
								label="Cédula"
								type="currency"
								onChange={(event, value)=> setFieldValue("cedula", value)}
								value={values.cedula}
								currencySymbol="cc"
								decimalPlaces={0}
							/>
						</Grid>

						<Grid item xs={6} md={5}>
							<Input
								name="nombre"
								label="Nombre"
								onChange={handleValue}
								value={values.nombre}
								errorText={errors.nombre}
								error={
									touched.nombre &&
									!!errors.nombre
								}
							/>
						</Grid>

						<Grid item xs={6} md={3}>
							<Input
								type="date"
								name="desde"
								label="Desde"
								onChange={handleValue}
								value={values.desde}
							/>
						</Grid>


						<Grid item xs={2} md={1} >
							<Switch 
								color="primary"
								name="activo"
								checked={values.activo === "Activo"}
								onChange={({ target })=> {
									target.checked?setFieldValue("activo", "Activo"):setFieldValue("activo", "Inactivo")
								}}
							/>
						</Grid>
					</Grid>
				)}
			</FuncionalityDataTable>
		</>
	)
}

export default ProcessResponsible
