import { Box } from "@material-ui/core"
import { MainProvider } from "common/context/mainContext/mainContext"
import useGuard from "common/hooks/useGuard"
import DashboardLayout from "common/layouts/DashboardLayout"
import NotificationsFilters from "./components/NotificationsFilters"
import { NotificationsTable } from "./components/NotificationsTable"
import NotificationsTableAdmin from "./components/NotificationsTableAdmin"

const Notifications = () => {
	const { isSuperUser } = useGuard()
	return (
		<DashboardLayout>
			<MainProvider>
				<Box marginBottom={3}>
					<NotificationsFilters />
				</Box>
				{isSuperUser && <NotificationsTableAdmin />}
				{!isSuperUser && <NotificationsTable />}
			</MainProvider>
		</DashboardLayout>
	)
}

export default Notifications
