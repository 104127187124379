import request from "common/plugins/request"
import moment from "moment"
import { useMemo, useState } from "react"

export const useNotifications = () => {
	const [notifications, setNotifications] = useState([])
	const [qtyNotifications, setQtyNotifications] = useState(0)

	const [filters, setFilters] = useState({
		startDate: "",
		endDate: "",
		city: "",
		office: "",
		radicado: "",
		type: "",
		claimant: "",
		defendant: "",
	})

	const changeFilter = (filter) => {
		setFilters({
			...filters,
			...filter,
		})
	}

	const [notificationsAdmin, setNotificationsAdmin] = useState([])
	const [qtyNotificationsAdmin, setQtyNotificationsAdmin] =
		useState(0)

	const [filtersAdmin, setFiltersAdmin] = useState({
		cupOrCode: "",
		startDate: "",
		office: "",
		endDate: "",
		claimant: "",
		defendant: "",
	})

	const changeFilterAdmin = (filter) => {
		setFiltersAdmin({
			...filtersAdmin,
			...filter,
		})
	}

	const getNotifications = async (queryParams) => {
		const dateGte = filters.startDate
			? moment(filters.startDate).format("YYYY-MM-DD")
			: ""
		const dateLte = filters.endDate
			? moment(filters.endDate).format("YYYY-MM-DD")
			: ""

		let params = {
			ciudad__icontains: filters.city,
			office__icontains: filters.office,
			dte__icontains: filters.claimant,
			ddo__icontains: filters.defendant,
			fecha_creacion__gte: dateGte,
			fecha_creacion__lte: dateLte,
			tipo_de_notificacion__icontains: filters.type,
			cup__icontains: filters.radicado,
			razon_social__icontains: filters.nameCompany,
		}

		// TODO remove id_cliente
		// if (localStorage.getItem("is_superuser_pasiolo") !== "true") {
		// 	params.id_cliente = localStorage.getItem("id_pasiolo")
		// }

		if (queryParams) {
			params = { ...params, ...queryParams }
		}

		const { results, count } = await request.get(
			"notificationclient",
			{
				params,
			}
		)
		setNotifications(results)
		setQtyNotifications(count)
	}

	const getNotificationsAdmin = async (queryParams = {}) => {
		const params = {
			office__icontains: filtersAdmin.office,
			dte__icontains: filtersAdmin.claimant,
			ddo__icontains: filtersAdmin.defendant,
			cup__icontains: filtersAdmin.cupOrCode,
		}

		const { results, count } = await request.get(
			"allnotifications/",
			{ params: { ...params, ...queryParams } }
		)
		setNotificationsAdmin(results)
		setQtyNotificationsAdmin(count)
	}

	const getNotificationAdmin = async (notificationId) => {
		const notification = await request.get(
			`allnotifications/${notificationId}/`
		)
		return notification
	}

	const replaceNotificationUrl = async (notificationId, url) => {
		setNotifications(
			notifications.map((notification) => {
				if (
					Number(notification.id_notificacion) ===
					Number(notificationId)
				) {
					notification.url = url
				}
				return notification
			})
		)
	}

	const createNotification = async (notificationPayload) => {
		return await request.post(
			"gestionesrealizadas/",
			notificationPayload
		)
	}

	const updateNotification = async (
		notificationID,
		notificationPayload
	) => {
		return await request.patch(
			`gestionesrealizadas/${notificationID}/`,
			notificationPayload
		)
	}

	const deleteNotification = async (notificationID) => {
		await request.delete(`gestionesrealizadas/${notificationID}`)
	}

	const getNotificationsByCUP = async (CUP) => {
		const { results } = await request.get("notificationclient", {
			params: { cup__icontains: CUP },
		})
		setNotifications(results)
	}

	return useMemo(
		() => ({
			notifications,
			setNotifications,
			getNotifications,
			getNotificationsByCUP,
			createNotification,
			updateNotification,
			deleteNotification,
			filters,
			changeFilter,
			qtyNotifications,
			getNotificationsAdmin,
			notificationsAdmin,
			qtyNotificationsAdmin,
			filtersAdmin,
			changeFilterAdmin,
			getNotificationAdmin,
			replaceNotificationUrl,
		}),
		// eslint-disable-next-line
		[
			notifications,
			qtyNotifications,
			filters,
			notificationsAdmin,
			qtyNotificationsAdmin,
			filtersAdmin,
		]
	)
}
