const toCurrency = (number) => {
    return Number(number).toLocaleString("es-CO")
}

const toSimpleCurrency = (number) => {
    console.log("number: ", number)
    return Number(number).toLocaleString("es-CO")
}

const currencyToNumber = (currency) => {
    //console.log("currency", currency, currency.replace(/[^0-9\.]+/g,""))
    return Number(currency.replace(/[^0-9.-]+/g,""));
}

export const useCurrency = ()=> {
    return { toCurrency, toSimpleCurrency, currencyToNumber }
}