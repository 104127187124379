import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import CalendarEvents from "./components/calendarEvents"

const Agenda = () => {
	return (
		<DashboardLayout>
			<MainProvider>
				<CalendarEvents />
			</MainProvider>
		</DashboardLayout>
	)
}

export default Agenda
