export const primary = "#129BDB"
export const primaryDark = "#3c3876"
export const gray = "#eff0f5"
export const darkGray = "#323232"
export const lightGray = "#969696"
export const ultraLightGray = "#cccccc"

export const dark = "#222222"
export const light = "#FFFFFF"

//$darkGray: #323232 !important;
//$lightGray: #969696 !important;
//$gray: #e5e7ef !important;
