import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@material-ui/core/"
import { ConfigBasicTheme } from "common/themes"

const Title = ({ size = "h1", children, ...props }) => {
	const { typography } = ConfigBasicTheme
	return (
		<Typography component="div">
			<Box
				fontSize={typography[size]}
				fontWeight={600}
				lineHeight={3}
				{...props}
			>
				{children}
			</Box>
		</Typography>
	)
}

Title.propTypes = {
	size: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element.isRequired,
	]),
}

export default Title
