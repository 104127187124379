import axios from "axios"
import Loading from "common/components/loading"
import ReactDOMServer from "react-dom/server"

const request = axios.create({
	baseURL: process.env.REACT_APP_API,
	// timeout: 4000,
})

const token = localStorage.getItem("token_pasiolo")
if (token) {
	request.defaults.headers.common["Authorization"] = `token ${token}`
} else {
	request.defaults.headers.common[
		"Authorization"
	] = `token ${process.env.REACT_APP_DEFAULT_TOKEN}`
}

const loadingContainer = document.getElementById("loading"),
	loadingComponent = ReactDOMServer.renderToString(<Loading />),
	startLoading = () =>
		(loadingContainer.innerHTML = loadingComponent),
	endLoading = () => {
		setTimeout(() => {
			if (loadingContainer.firstChild)
				loadingContainer.firstChild.remove()
		}, 800)
	}

request.interceptors.request.use(
	(config) => {
		if (!config?.hideLoader) startLoading()
		return config
	},
	(error) => {
		endLoading()
		return Promise.reject(error)
	}
)

request.interceptors.response.use(
	({ data, status, statusText, ...others }) => {
		console.log("status", data, status, statusText)
		console.log("status", others)
		endLoading()
		return data
	},
	(error) => {
		console.log("error", error)
		endLoading()
		return Promise.reject(error)
	}
)

export default request
