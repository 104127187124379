import { useMainContext } from "common/context/mainContext/mainContext"
import { useEffect, useState } from "react"

const useOfficeTypesGroup = () => {
	const [officeTypesGroup, setOfficeTypesGroup] = useState([])
	const { getTypesOfficesGroup } = useMainContext().offices

	useEffect(() => {
		getTypesOfficesGroup().then((_officeTypes) => {
			setOfficeTypesGroup(_officeTypes)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return { officeTypesGroup }
}

export default useOfficeTypesGroup
