import {
	AppBar,
	Avatar,
	Box,
	Fade,
	IconButton,
	makeStyles,
	Paper,
	Popper,
	Toolbar,
	Typography,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import NotificationsIcon from "@material-ui/icons/Notifications"
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew"
import useGuard from "common/hooks/useGuard"
import { ultraLightGray } from "common/themes/colors"
import { UcFirst } from "containers/signUp/utils/UcFirst"
import { useState } from "react"

const HeaderNavBar = ({ toggleDrawer }) => {
	const classes = useStyles()
	const [anchorElNotifications, setAnchorElNotifications] =
		useState(null)
	const [openNotifications, setOpenNotifications] = useState(false)

	const [anchorElSettings, setAnchorElSettings] = useState(null)
	const [openSettings, setOpenSettings] = useState(false)

	const togglePopperNotifications = (event) => {
		setAnchorElNotifications(event.currentTarget)
		setOpenNotifications(!openNotifications)
	}

	const { user } = useGuard()

	const togglePopperSettings = (event) => {
		setAnchorElSettings(event.currentTarget)
		setOpenSettings(!openSettings)
	}

	const logout = () => {
		localStorage.clear()
		window.location = "/"
	}

	const goToUpdateUser = () => {
		window.location = "/user/update"
	}

	return (
		<AppBar position="sticky" className={classes.nav}>
			<Toolbar color="primary">
				<Box
					width="100%"
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Box className={classes.menuIcon}>
						<IconButton color="inherit" onClick={toggleDrawer}>
							<MenuIcon />
						</IconButton>
					</Box>
					<Box display="flex" ml="auto">
						<IconButton
							color="primary"
							className={classes.notificationButton}
							onClick={togglePopperNotifications}
						>
							<NotificationsIcon />
						</IconButton>
						<Box ml={2}>
							<Typography color="primary" component="strong">
								{UcFirst(user?.username)}
							</Typography>
							<IconButton
								color="primary"
								onClick={togglePopperSettings}
							>
								<Avatar className={classes.settingsAvatar} />
							</IconButton>
						</Box>
						<Popper
							transition
							open={openSettings}
							anchorEl={anchorElSettings}
							placement={"bottom-end"}
							className={classes.popper}
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Paper className={classes.containerItems}>
										<Box
											className={classes.item}
											onClick={goToUpdateUser}
										>
											Mi perfil
										</Box>
										<Box className={classes.item} onClick={logout}>
											<PowerSettingsNewIcon
												style={{ fontSize: 18, marginRight: 5 }}
											/>
											Salir
										</Box>
									</Paper>
								</Fade>
							)}
						</Popper>
						<Popper
							transition
							open={openNotifications}
							anchorEl={anchorElNotifications}
							placement={"bottom-start"}
							className={classes.popper}
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Paper className={classes.container}>
										<ul></ul>
									</Paper>
								</Fade>
							)}
						</Popper>
					</Box>
				</Box>
			</Toolbar>
		</AppBar>
	)
}

const useStyles = makeStyles((theme) => ({
	menuIcon: {
		display: "none",
		"@media (max-width: 1500px)": {
			display: "block",
		},
	},
	nav: {
		borderBottom: `1px solid ${ultraLightGray}`,
		backgroundColor: theme.palette.gray,
		color: "#444",
		boxShadow: "none",
	},
	popper: {
		zIndex: 10000,
	},
	containerItems: {
		width: 130,
		fontSize: ".8rem",
		overflow: "hidden",
		borderTopLeftRadius: 5,
		borderBottomLeftRadius: 5,
		borderBottomRightRadius: 5,
		borderTopRightRadius: 0,
	},
	item: {
		padding: "10px 15px",
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		fontWeight: 500,
		"&:hover": {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.light,
		},
	},
	settingsAvatar: {
		width: 32,
		height: 32,
	},
}))

export default HeaderNavBar
