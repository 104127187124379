import { useMainContext } from "common/context/mainContext/mainContext"
import { useState, useEffect } from "react"

const useOfficeTypes = () => {
	const [officeTypes, setOfficeTypes] = useState([])
	const { getTypesOffices } = useMainContext().offices

	useEffect(() => {
		getTypesOffices().then((_officeTypes) => {
			setOfficeTypes(_officeTypes)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return { officeTypes }
}

export default useOfficeTypes
