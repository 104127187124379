import { Box, Grid } from "@material-ui/core"
import {
	Autocomplete,
	Button,
	Input,
} from "common/components/inputs/basics"
import { useMainContext } from "common/context/mainContext/mainContext"
import { useEffect } from "react"
import { useFormLoggedUser } from "./hooks/useFormLoggedUser"

const EditLoggedUserForm = ({ user }) => {
	const {
		provinces = [],
		getCities,
		cities = [],
	} = useMainContext().address
	const { updateUser } = useMainContext().signUp

	const formLoggedUser = useFormLoggedUser({
		user,
		onSubmit: async (data) => {
			const usuarioData = {
				username: data.username,
				first_name: data.nombre,
				email: data.email,
				password: data.password,
			}
			const dataUserPasioloLegal = {
				user_pasiolo_id: null,
				apellido: data.apellido,
				cedula: data.cedula,
				razonsocial: `${data.nombre} ${data.apellido}`,
				departamento: data.departamento,
				municipio: data.municipio,
				direccion: data.direccion,
				barrio: data.barrio,
				pago: null,
				telefonofijo_persona: data.telefonofijo,
				celular_persona: data.celular,
				telefonofijo_empresa: null,
				celular_empresa: null,
				plan: null,
				sub_cuenta: true,
				padre_id: localStorage.getItem("id_pasiolo"),
			}
			await updateUser({ usuarioData, dataUserPasioloLegal })
		},
	})

	useEffect(() => {
		if (formLoggedUser.values.departamento && provinces.length) {
			getCities(formLoggedUser.values.departamento)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formLoggedUser.values.departamento, provinces])

	return (
		<form
			noValidate
			autoComplete="off"
			onSubmit={formLoggedUser.handleSubmit}
		>
			<Grid container spacing={6}>
				<Grid item xs={12} md={6}>
					<Input
						type="number"
						name="cedula"
						label="Cédula de Cuidadanía"
						onChange={formLoggedUser.handleChange}
						value={formLoggedUser.values.cedula}
						errorText={formLoggedUser.errors.cedula}
						error={
							formLoggedUser.touched.cedula &&
							!!formLoggedUser.errors.cedula
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						label="Nombre"
						name="nombre"
						onChange={formLoggedUser.handleChange}
						value={formLoggedUser.values.nombre}
						errorText={formLoggedUser.errors.nombre}
						error={
							formLoggedUser.touched.nombre &&
							!!formLoggedUser.errors.nombre
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						type="text"
						label="Apellido"
						name="apellido"
						onChange={formLoggedUser.handleChange}
						value={formLoggedUser.values.apellido}
						errorText={formLoggedUser.errors.apellido}
						error={
							formLoggedUser.touched.apellido &&
							!!formLoggedUser.errors.apellido
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						type="email"
						name="email"
						label="Correo Electrónico"
						onChange={formLoggedUser.handleChange}
						value={formLoggedUser.values.email}
						errorText={formLoggedUser.errors.email}
						error={
							formLoggedUser.touched.email &&
							!!formLoggedUser.errors.email
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						type="text"
						label="Celular"
						name="celular"
						onChange={formLoggedUser.handleChange}
						value={formLoggedUser.values.celular}
						errorText={formLoggedUser.errors.celular}
						error={
							formLoggedUser.touched.celular &&
							!!formLoggedUser.errors.celular
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						type="text"
						label="Teléfono Fijo (opcional)"
						name="telefonofijo"
						onChange={formLoggedUser.handleChange}
						value={formLoggedUser.values.telefonofijo}
						errorText={formLoggedUser.errors.telefonofijo}
						error={
							formLoggedUser.touched.telefonofijo &&
							!!formLoggedUser.errors.telefonofijo
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Autocomplete
						label="Departamento"
						name="departamento"
						options={provinces}
						onChange={(_, province) =>
							formLoggedUser.setFieldValue("departamento", province)
						}
						getOptionLabel={(province) => (province ? province : "")}
						errorText={formLoggedUser.errors.departamento}
						error={
							formLoggedUser.touched.departamento &&
							!!formLoggedUser.errors.departamento
						}
						value={formLoggedUser.values.departamento}
						disabled={!provinces?.length}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Autocomplete
						label="Ciudad"
						name="municipio"
						options={cities}
						onChange={(_, city) =>
							formLoggedUser.setFieldValue("municipio", city)
						}
						getOptionLabel={(city) => (city ? city : "")}
						errorText={formLoggedUser.errors.municipio}
						error={
							formLoggedUser.touched.municipio &&
							!!formLoggedUser.errors.municipio
						}
						value={formLoggedUser.values.municipio}
						disabled={!cities?.length}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						type="text"
						label="Dirección"
						name="direccion"
						onChange={formLoggedUser.handleChange}
						value={formLoggedUser.values.direccion}
						errorText={formLoggedUser.errors.direccion}
						error={
							formLoggedUser.touched.direccion &&
							!!formLoggedUser.errors.direccion
						}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Input
						type="text"
						label="Barrio"
						name="barrio"
						onChange={formLoggedUser.handleChange}
						value={formLoggedUser.values.barrio}
						errorText={formLoggedUser.errors.barrio}
						error={
							formLoggedUser.touched.barrio &&
							!!formLoggedUser.errors.barrio
						}
					/>
				</Grid>

				<Grid item xs={12}>
					<Box display="flex" justifyContent="end" marginTop={5}>
						<Button color="primary" type="submit">
							Finalizar
						</Button>
					</Box>
				</Grid>
			</Grid>
		</form>
	)
}

export default EditLoggedUserForm
