import {
	Box,
	IconButton,
	makeStyles,
	Tooltip,
} from "@material-ui/core"
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn"
import EditIcon from "@material-ui/icons/Edit"
import PauseIcon from "@material-ui/icons/Pause"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import { DataGrid } from "common/components/DataGrid"
import { useMainContext } from "common/context/mainContext/mainContext"
import { UcFirst } from "containers/signUp/utils/UcFirst"
import { useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom"

const SubaccoutsTable = () => {
	const classes = useStyles()
	const history = useHistory()

	const {
		subaccounts,
		getSubaccounts,
		disabledSubaccount,
		enabledSubaccount,
	} = useMainContext().subaccounts

	const redirectToUpdateSubaccount = (id) => {
		history.push(`/subcuentas/update/${id}`)
	}

	const redirectToDetailSubaccount = (id) => {
		history.push(`/subcuentas/processes/${id}`)
	}

	useEffect(() => {
		getSubaccounts()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const columns = useMemo(
		() => [
			{
				accessorKey: "cedula",
				header: "Identificación",
				muiTableBodyCellProps: {
					align: "center",
				},
			},
			{
				accessorKey: "razonsocial",
				header: "Nombre",
				Cell: ({ renderedCellValue: nombre }) => UcFirst(nombre),
			},
			{
				accessorKey: "user",
				header: "Email",
				Cell: ({ renderedCellValue: { email } }) => UcFirst(email),
			},
			{
				maxSize: 100,
				accessorKey: "id",
				header: "Acciones",
				Cell: ({ row: { original: subaccount } }) => (
					<Box display="flex" justifyContent="center">
						{subaccount.user.is_active && (
							<Tooltip title="Deshabilitar subcuenta">
								<IconButton
									onClick={() =>
										disabledSubaccount(subaccount.user.id)
									}
								>
									<PauseIcon className={classes.disabled_btn} />
								</IconButton>
							</Tooltip>
						)}

						{!subaccount.user.is_active && (
							<Tooltip title="Habilitar subcuenta">
								<IconButton
									onClick={() =>
										enabledSubaccount(subaccount.user.id)
									}
								>
									<PlayArrowIcon className={classes.disabled_btn} />
								</IconButton>
							</Tooltip>
						)}

						<Tooltip title="Editar subcuenta">
							<IconButton
								onClick={() =>
									redirectToUpdateSubaccount(subaccount.id)
								}
							>
								<EditIcon className={classes.delete_btn} />
							</IconButton>
						</Tooltip>

						<Tooltip title="Asignar procesos">
							<IconButton
								onClick={() =>
									redirectToDetailSubaccount(subaccount.user.id)
								}
							>
								<AssignmentTurnedInIcon
									className={classes.delete_btn}
								/>
							</IconButton>
						</Tooltip>
					</Box>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			subaccounts,
			disabledSubaccount,
			enabledSubaccount,
			redirectToDetailSubaccount,
			redirectToUpdateSubaccount,
		]
	)

	return <DataGrid columns={columns} data={subaccounts} />
}

const useStyles = makeStyles((theme) => ({
	disabled_btn: {
		color: "orange",
		marginRight: 5,
	},
	delete_btn: {
		color: theme.palette.error,
	},
}))

export default SubaccoutsTable
