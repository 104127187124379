import { Box, Grid } from "@material-ui/core"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import AssociatedCosts from "./associatedCosts"
import Pending from "./pending"
import ProcessResponsible from "./processResponsible"

const ProcessFormPrivateData2 = ({
	datos_responsable = [],
	datos_pendientes = [],
	datos_gestion = [],
	data_costos = [],
	children,
}) => {
	const [responsables, setResponsables] = useState([])

	const formPrivateData2 = useFormik({
		initialValues: {
			datos_responsable: datos_responsable,
			datos_pendientes: datos_pendientes,
			datos_gestion: datos_gestion,
			data_costos: data_costos,
		},
		onSubmit() {},
	})

	useEffect(() => {
		setResponsables(
			formPrivateData2.values.datos_responsable.map(
				({ nombre }) => nombre
			)
		)
	}, [formPrivateData2.values.datos_responsable])

	return (
		<form onSubmit={formPrivateData2.handleSubmit}>
			<Grid container spacing={3} justify="center">
				<Grid item xs={12} md={10}>
					<ProcessResponsible
						data={formPrivateData2.values.datos_responsable}
						changeData={(value) =>
							formPrivateData2.setFieldValue(
								"datos_responsable",
								value
							)
						}
					/>
				</Grid>

				<Grid item xs={12} md={10}>
					<Pending
						data={formPrivateData2.values.datos_pendientes}
						values={{ responsables }}
						changeData={(value) =>
							formPrivateData2.setFieldValue(
								"datos_pendientes",
								value
							)
						}
					/>
				</Grid>

				<Grid item xs={12} md={10}>
					<AssociatedCosts
						data={formPrivateData2.values.data_costos}
						changeData={(value) =>
							formPrivateData2.setFieldValue("data_costos", value)
						}
					/>
				</Grid>

				<Grid item xs={12} md={10}>
					<Box display="flex" paddingTop={5}>
						{children(formPrivateData2)}
					</Box>
				</Grid>
			</Grid>
		</form>
	)
}
export default ProcessFormPrivateData2
