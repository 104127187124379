import { Box } from "@material-ui/core"
import ProcessFilters from "containers/processes/components/ProcessFilters"
import React, { useState } from "react"
import AssignedProcessesTable from "./AssignedProcessesTable"
import EditProcessesSubaccounts from "./EditProcessesSubaccounts"

const TabEditAssignedProcesses = () => {
	const [isOpenEdit, setIsOpenEdit] = useState(false)
	const [process, setProcess] = useState({})

	const closeEdit = () => setIsOpenEdit(false)
	const openEdit = () => setIsOpenEdit(true)

	return (
		<>
			<Box marginBottom={3}>
				<ProcessFilters isSubcount={true} />
			</Box>
			<AssignedProcessesTable
				onRowClick={(_, _process) => {
					setProcess(_process)
					openEdit()
				}}
			/>
			<EditProcessesSubaccounts
				isOpen={isOpenEdit}
				onClose={closeEdit}
				process={process}
			/>
		</>
	)
}

export default TabEditAssignedProcesses
