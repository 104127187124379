import { useMainContext } from "common/context/mainContext/mainContext"
import ProcessForm from "components/forms/processForm/processForm"
import React from "react"
import ActionsButtons from "./actionsButtons"

const ProcessFirstStep = (stepControls) => {
	const { process } = useMainContext().process
	if (!Object.values(process).length) return null
	return (
		<ProcessForm {...{ ...process, ...process.detalle_del_proceso }}>
			{(formData) => (
				<ActionsButtons
					{...{ formData, stepControls }}
					data={({
						data_dte,
						data_ddo,
						data_abogadodte,
						data_abogadoddo,
						despachos_del_proceso,
						...data
					}) => {
						data.dte = data_dte.length?data_dte[0].nombre:""
						data.ddo = data_ddo.length?data_ddo[0].nombre:""
						data.abogadodte = data_abogadodte.length?data_abogadodte[0].nombre:""
						data.abogadoddo = data_abogadoddo.length?data_abogadoddo[0].nombre:""
						data.detalle_del_proceso = {
							...process.detalle_del_proceso,
							...{
								data_dte,
								data_ddo,
								data_abogadodte,
								data_abogadoddo,
								despachos_del_proceso,
							},
						}
						return { process: data }
					}}
				/>
			)}
		</ProcessForm>
	)
}

export default ProcessFirstStep
