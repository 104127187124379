import { Box, Button, Grid } from "@material-ui/core"
import { Autocomplete, Input } from "common/components/inputs/basics"
import { useMainContext } from "common/context/mainContext/mainContext"
import useCities from "common/hooks/useCities"
import { UcFirst } from "containers/signUp/utils/UcFirst"
import ProcessAdvancedFilters from "./ProcessFiltersAdvanced"

const ProcessFilters = ({ isSubcount = false }) => {
	const { cities } = useCities()
	const { changeFilter, getProcesses } = useMainContext().process

	return (
		<Box display="flex" alignItems="center">
			<ProcessAdvancedFilters isSubcount={isSubcount} />

			<Grid
				container
				spacing={1}
				style={{ marginLeft: 5, width: 800 }}
			>
				<Grid item xs={3}>
					<Input
						name="cup"
						label="CUP / Radicado"
						onChange={({ target: { value: cup } }) =>
							changeFilter({ cup })
						}
						autoComplete="off"
					/>
				</Grid>
				<Grid item xs={3}>
					<Autocomplete
						label="Ciudad"
						options={cities.map(({ name }) => name)}
						onChange={(_, city) => changeFilter({ city })}
						getOptionLabel={(city) => UcFirst(city)}
						disabled={!cities.length}
					/>
				</Grid>
				<Grid item xs={3}>
					<Input
						name="claimant"
						label="Demandante"
						onChange={({ target: { value: claimant } }) =>
							changeFilter({ claimant })
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<Input
						name="defendant"
						label="Demandado"
						onChange={({ target: { value: defendant } }) =>
							changeFilter({ defendant })
						}
					/>
				</Grid>
			</Grid>

			<Button
				color="primary"
				variant="contained"
				style={{ marginLeft: 5 }}
				onClick={() => getProcesses()}
			>
				Filtrar
			</Button>
		</Box>
	)
}

export default ProcessFilters
