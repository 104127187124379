import { Box } from "@material-ui/core"
import { Button } from "common/components/inputs/basics"
import ProcessFilters from "containers/processes/components/ProcessFilters"
import React, { useEffect } from "react"
import AssignSubcount from "./AssignSubcount"
import AssignedProcessesTable from "./AssignedProcessesTable"
import AvailableProcessesTable from "./AvailableProcessesTable"
import AddIcon from "@material-ui/icons/Add"
import { useMainContext } from "common/context/mainContext/mainContext"
import { useHistory } from "react-router-dom"

const TabAssignProcesses = () => {
	const {
		isActivatedAddSubaccounts,
		populateSubaccountsInProccesses,
		clearSubaccountsID,
		clearProcessesID,
	} = useMainContext().subaccounts
	const { processes, updateMultipleProcesses } =
		useMainContext().process

	const assignedSubaccountsToProccesses = () => {
		const _processes = populateSubaccountsInProccesses(processes)
		if (_processes.length) {
			updateMultipleProcesses(_processes)
			clearSubaccountsID()
			clearProcessesID()
		}
	}
	const history = useHistory()

	const goToCreateSubaccount = () => {
		history.push("/subcuentas/create")
	}

	useEffect(() => {
		clearProcessesID()
		clearSubaccountsID()
		// eslint-disable-next-line
	}, [])

	return (
		<>
			<Box marginBottom={4}>
				<ProcessFilters />
			</Box>
			<Box marginBottom={4}>
				<AssignSubcount />
			</Box>
			<AvailableProcessesTable />
			{/* TODO (EN PROXIMA VISTA **USUARIO**)  */}
			<Box
				justifyContent={"space-between"}
				display="flex"
				marginTop={3}
				marginBottom={3}
			>
				<Button
					color="primary"
					style={{ marginLeft: 10 }}
					onClick={goToCreateSubaccount}
				>
					<AddIcon style={{ marginRight: 5 }} /> Crear Subcuenta
				</Button>
				<Button
					color="primary"
					onClick={() => assignedSubaccountsToProccesses(processes)}
					disabled={!isActivatedAddSubaccounts}
				>
					Asignar
				</Button>
			</Box>

			<AssignedProcessesTable />
		</>
	)
}

export default TabAssignProcesses
