import { Box, makeStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import TwitterIcon from "@material-ui/icons/Twitter"
import Title from "common/components/text/Title"
import { useMemo } from "react"
import MediaQuery from "react-responsive"

const LoginLayout = ({ children }) => {
	const classes = useStyles()
	const year = useMemo(() => new Date().getFullYear(), [])
	return (
		<Grid container className={classes.root}>
			<MediaQuery minDeviceWidth={1280}>
				<Grid item xs={5} className={classes.leftContent}>
					<Box className={classes.contentDescription}>
						<Title>Notificador Judicial 24 horas.</Title>
						<p>
							Prestamos Vigilancia Judicial a nivel nacional, siempre
							que los procesos y los despachos estén debidamente
							digitalizados y actualizados en la Consulta Nacional
							Unificada de la Rama Judicial, y que dichos procesos
							sean de acceso público, es decir, aquellos que no están
							catalogados como privados en la Consulta Nacional
							Unificada. <br /> <br />
							Además, prestamos un servicio completo en los
							departamentos de Atlántico y Bolívar, sin tener en
							cuenta si los procesos están o no registrados en la CNA
							o si se consideran privados, siempre que estos procesos
							estén debidamente publicados e identificables a través
							del CUP de forma correcta en los portales de la Rama
							Judicial. El servicio que proporcionamos es
							completamente virtual.
						</p>

						<div className={classes.separator}>
							<div></div>
						</div>

						<div className={classes.socialNetwork}>
							<a href="https://m.facebook.com/redprocesalcartagena">
								<FacebookIcon style={{ fontSize: 28 }}></FacebookIcon>
							</a>
							<a href="https://instagram.com/redprocesal">
								<InstagramIcon
									style={{ fontSize: 28 }}
								></InstagramIcon>
							</a>
							<a href="https://twitter.com/redprocesal">
								<TwitterIcon style={{ fontSize: 28 }}></TwitterIcon>
							</a>
						</div>

						<ul className={classes.listInformation}>
							<li>
								<a href="/">Politicas de privacidad</a>
							</li>
							<li>
								<a href="/">Contáctenos</a>
							</li>
							<li>
								<a href="/">©Red procesal {year}</a>
							</li>
						</ul>
					</Box>
				</Grid>
			</MediaQuery>
			<Grid item xs={12} lg={7}>
				{children}
			</Grid>
		</Grid>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: "100vw",
		height: "100vh",
	},
	leftContent: {
		height: "100%",
		backgroundColor: theme.palette.primary.dark,
		display: "flex",
		alignItems: "end",
		padding: "60px 90px",
	},
	contentDescription: {
		margin: "0 auto",
		width: 580,
		maxWidth: "100%",
		color: theme.palette.light,
		"& h1": {
			fontSize: "1.8rem !important",
		},
		"& p": {
			lineHeight: 1.6,
			marginTop: -15,
			fontSize: "1.1rem",
		},
	},
	separator: {
		width: "100%",
		height: 1,
		backgroundColor: theme.palette.light,
		margin: "30px 0 40px",
		position: "relative",
		"& div": {
			width: 8,
			height: 8,
			backgroundColor: theme.palette.light,
			borderRadius: "50%",
			position: "absolute",
			right: 0,
			top: "100%",
			transform: "translateY(-50%)",
		},
	},
	socialNetwork: {
		width: 130,
		display: "flex",
		justifyContent: "space-between",
		marginBottom: 30,
		"& a": {
			color: theme.palette.light,
		},
	},
	listInformation: {
		display: "flex",
		justifyContent: "space-between",
		listStyle: "none",
		marginTop: 20,
		"& a": {
			textDecoration: "none",
			color: theme.palette.light,
			fontSize: ".8rem",
		},
	},
}))

export default LoginLayout
