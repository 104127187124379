import { useFormik } from "formik"
import * as Yup from "yup"

const validationSchema = Yup.object().shape({
	codeCity: Yup.string().required("Requerido").nullable(),
	codeOffice: Yup.string().required("Requerido").nullable(),
	numberOffice: Yup.string().required("Requerido").nullable(),
	yearProcess: Yup.string().required("Requerido").nullable(),
	consecutive: Yup.string()
		.required("Requerido")
		.min(5, "Min. 5 caracteres")
		.max(5, "Max. 5 caracteres"),
})

export const useGenerateCupSchema = (
	{
		codeCity = "",
		codeOffice = "",
		numberOffice = "",
		yearProcess = "",
		consecutive = "",
	},
	onSubmit
) => {
	const initialValues = {
		codeCity,
		codeOffice,
		numberOffice,
		yearProcess,
		consecutive,
	}

	return useFormik({
		validationSchema,
		initialValues,
		onSubmit,
	})
}
