import { makeStyles } from "@material-ui/core"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import React from "react"

const List = ({ onRemoveItem, list }) => {
    const classes = useStyles()
	return (
		<ul className={classes.list}>
			{list.map((item, index) =>
				item? (
					<li className={classes.listItem} key={index}>
						{ item }
						<RemoveCircleOutlineIcon
							onClick={() => onRemoveItem(index)}
							className={classes.listRemove}
						/>
					</li>
				) : null
			)}
		</ul>
	)
}

const useStyles = makeStyles((theme) => ({
	list: {
		marginTop: 2,
		padding: 6,
		borderRadius: 5,
		fontWeight: 500,
		overflow: "hidden",
		backgroundColor: theme.palette.ultraLightGray,
	},

	listItem: {
		listStyle: "none",
		padding: 10,
		fontSize: ".9rem",
		position: "relative",
		"& strong": {
			marginRight: 15,
		},
	},

	listRemove: {
		right: 0,
		top: "50%",
		fontSize: "1.4rem",
		position: "absolute",
		transform: "translate(-15px, -50%)",
		cursor: "pointer",
	},
}))

export default List
