import { Box, Grid } from "@material-ui/core"
import { Input } from "common/components/inputs/basics"
import { useFormik } from "formik"
import React from "react"
import Budget from "./budget"
import EconomicObjectives from "./economicObjectives"
import Objectives from "./objectives"

const ProcessFormPrivateData = ({
	hechos = "",
	datos_pretensiones = [],
	datos_pretensiones_economicas = [],
	exepciones = "",
	datos_cuantia = [],
	children,
}) => {
	const formPrivateData = useFormik({
		initialValues: {
			hechos,
			datos_pretensiones,
			datos_pretensiones_economicas,
			datos_cuantia,
			exepciones,
		},
		onSubmit(){}
	})

	return (
		<form onSubmit={formPrivateData.handleSubmit}>
			<Box marginTop={2}>
				<Grid container spacing={3} justify="center">
					<Grid item xs={12} md={10}>
						<Input
							rows={3}
							multiline
							name="hechos"
							label="Hechos Resumen"
							onChange={formPrivateData.handleChange}
							value={formPrivateData.values.hechos}
						/>
					</Grid>
					<Grid item xs={12} md={10}>
						{/*PRETENCIONES*/}
						<Objectives
							data={formPrivateData.values.datos_pretensiones}
							changeData={(value) =>
								formPrivateData.setFieldValue(
									"datos_pretensiones",
									value
								)
							}
						/>
					</Grid>
					<Grid item xs={12} md={10}>
						<EconomicObjectives
							data={
								formPrivateData.values.datos_pretensiones_economicas
							}
							changeData={(value) =>
								formPrivateData.setFieldValue(
									"datos_pretensiones_economicas",
									value
								)
							}
						/>
					</Grid>

					<Grid item xs={12} md={10}>
						<Box marginTop={3}>
							<Input
								rows={3}
								multiline
								name="exepciones"
								label="Contestación y Excepción"
								onChange={formPrivateData.handleChange}
								value={formPrivateData.values.exepciones}
							/>
						</Box>
					</Grid>

					<Grid item xs={12} md={10}>
						<Budget
							data={formPrivateData.values.datos_cuantia}
							changeData={(value) =>
								formPrivateData.setFieldValue("datos_cuantia", value)
							}
						/>
					</Grid>
					<Grid item xs={12} md={10}>
						<Box display="flex" paddingTop={5}>
							{children(formPrivateData)}
						</Box>
					</Grid>
				</Grid>
			</Box>
		</form>
	)
}
export default ProcessFormPrivateData
