import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Tooltip,
} from "@material-ui/core"
import TuneIcon from "@material-ui/icons/Tune"
import { Autocomplete, Input } from "common/components/inputs/basics"
import { useMainContext } from "common/context/mainContext/mainContext"
import useCities from "common/hooks/useCities"
import useOffices from "common/hooks/useOffices"
import useOfficeTypesGroup from "common/hooks/useOfficeTypesGroup"
import useStatesProcess from "common/hooks/useStatesProcess"
import { UcFirst } from "containers/signUp/utils/UcFirst"
import { useEffect, useState } from "react"

const ProcessAdvancedFilters = ({ isSubcount = false }) => {
	const { cities } = useCities()
	const { statesProcess } = useStatesProcess()
	const { officeTypesGroup } = useOfficeTypesGroup()
	const { offices, getOffices } = useOffices()
	const [isOpenFilter, setIsOpenFilter] = useState(false)

	const mainContext = useMainContext()
	const { filters, changeFilter, getProcesses } = mainContext.process

	const handleFilter = ({ target }) => {
		changeFilter({
			[target.name]: target.value,
		})
	}

	const onEnterFilter = (e) => {
		if (e.keyCode === 13) onHandleFilter()
	}

	const onHandleFilter = () => {
		getProcesses()
	}

	useEffect(() => {
		const { city } = filters
		if (city) getOffices(city)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters.city])

	return (
		<>
			<Tooltip title="Filtros avanzados">
				<Button
					variant="contained"
					onClick={() => setIsOpenFilter(true)}
				>
					<TuneIcon />
				</Button>
			</Tooltip>

			<Dialog
				open={isOpenFilter}
				onClose={() => setIsOpenFilter(false)}
			>
				<DialogTitle>Filtros Avanzados</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Input
								name="cup"
								label="CUP / Radicado"
								onKeyUp={onEnterFilter}
								onChange={handleFilter}
								autoComplete="off"
							/>
						</Grid>

						<Grid item xs={6}>
							<Autocomplete
								label="Ciudad"
								options={cities.map(({ name }) => name)}
								onChange={(_, city) => changeFilter({ city })}
								getOptionLabel={(city) => UcFirst(city)}
								disabled={!cities.length}
							/>
						</Grid>

						<Grid item xs={6}>
							<Autocomplete
								label="Tipo despacho"
								options={officeTypesGroup?.map(({ type }) => type)}
								onChange={(_, typeOffice) =>
									changeFilter({ typeOffice })
								}
								getOptionLabel={(type) => UcFirst(type)}
								disabled={!officeTypesGroup.length}
							/>
						</Grid>

						<Grid item xs={6}>
							<Autocomplete
								label="Despacho"
								options={offices.map(({ name }) => name)}
								onInputChange={(_, office) =>
									changeFilter({ office })
								}
								getOptionLabel={(office) => UcFirst(office)}
								disabled={!offices.length}
							/>
						</Grid>

						<Grid item xs={6}>
							<Autocomplete
								label="Estado del Proceso"
								options={statesProcess.map(({ name }) => name)}
								onInputChange={(_, state) => changeFilter({ state })}
								getOptionLabel={(state) => UcFirst(state)}
							/>
						</Grid>

						<Grid item xs={6}>
							<Input
								label="Tipo de Proceso"
								name="type"
								onChange={handleFilter}
							/>
						</Grid>

						<Grid item xs={12}>
							<Input
								name="claimant"
								label="Demandante"
								onChange={handleFilter}
							/>
						</Grid>

						<Grid item xs={12}>
							<Input
								name="defendant"
								label="Demandado"
								onChange={handleFilter}
							/>
						</Grid>

						<Grid item xs={12}>
							<Input
								label="Apoderados"
								name="proxies"
								onChange={handleFilter}
							/>
						</Grid>
						{isSubcount && (
							<Grid item xs={12}>
								<Input
									label="Asignado"
									name="asignado"
									onChange={handleFilter}
								/>
							</Grid>
						)}

						{localStorage.getItem("is_superuser_pasiolo") !==
							"false" && (
							<Grid item xs={12}>
								<Input
									label="Razón Social"
									name="nameCompany"
									onChange={handleFilter}
								/>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						variant="contained"
						onClick={onHandleFilter}
					>
						Filtrar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default ProcessAdvancedFilters
