import { useMainContext } from "common/context/mainContext/mainContext"
import FormProcessPrivateData from "components/forms/processFormPrivateData/processFormPrivateData"
import React from "react"
import ActionsButtons from "./actionsButtons"

const ProcessSecondStep = (stepControls) => {
	const { process } = useMainContext().process
	if (!Object.values(process).length) return null
	return (
		<FormProcessPrivateData {...process?.detalle_del_proceso}>
			{(formData) => (
				<ActionsButtons
					{...{ formData, stepControls }}
					data={(data) => {
						process.detalle_del_proceso = Object.assign(
							process.detalle_del_proceso,
							data
						)
						return { process }
					}}
				/>
			)}
		</FormProcessPrivateData>
	)
}

export default ProcessSecondStep
