import { Box, IconButton, Tooltip } from "@material-ui/core"
import LinkIcon from "@material-ui/icons/Link"
import { DataGrid } from "common/components/DataGrid"
import { useMainContext } from "common/context/mainContext/mainContext"
import { UcFirst } from "containers/signUp/utils/UcFirst"
import React, { useEffect, useMemo } from "react"

const RelatedDocumentsTable = () => {
	const {
		getRelatedDocuments,
		relatedDocuments,
		qtyRelatedDocuments,
	} = useMainContext().searchNotifications

	const [pagination, setPagination] = React.useState({
		pageIndex: 0,
		pageSize: 10,
	})

	useEffect(() => {
		getRelatedDocuments({
			offset: pagination.pageIndex * pagination.pageSize,
			limit: pagination.pageSize,
		})
		// eslint-disable-next-line
	}, [pagination])

	const onOpenLink = (link) => {
		window.open(link)
	}

	const columns = useMemo(
		() => [
			{
				accessorKey: "name",
				header: "Despacho",
				Cell: ({ renderedCellValue: name }) => UcFirst(name),
			},
			{
				size: 50,
				accessorKey: "url_document",
				header: "Link",
				Cell: ({ renderedCellValue: url_document }) => (
					<Box display="flex" justifyContent="center">
						<Tooltip title={url_document}>
							<IconButton
								onClick={() => onOpenLink(url_document)}
								color="primary"
							>
								<LinkIcon />
							</IconButton>
						</Tooltip>
					</Box>
				),
			},
		],
		[]
	)

	return (
		<Box maxWidth={800}>
			Documentos relacionados
			<DataGrid
				columns={columns}
				data={relatedDocuments}
				enablePagination={true}
				pagination={pagination}
				onPaginationChange={setPagination}
				rowCount={qtyRelatedDocuments}
			/>
		</Box>
	)
}

export default RelatedDocumentsTable
