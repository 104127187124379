import { useMainContext } from "common/context/mainContext/mainContext"
import FormPerson from "containers/signUp/components/formPerson"
// import { mapDataUser } from "containers/signUp/utils/mapDataUser"
import { useHistory } from "react-router-dom"

const CreateSubaccoutForm = () => {
	const history = useHistory()
	const { createUser } = useMainContext().signUp
	const onSubmitForm = async (data) => {
		const usuarioData = {
			username: data.username,
			first_name: data.nombre,
			email: data.email,
			password: data.password,
		}
		const dataUserPasioloLegal = {
			user_pasiolo_id: null,
			apellido: data.apellido,
			cedula: data.cedula,
			razonsocial: `${data.nombre} ${data.apellido}`,
			departamento: data.departamento,
			municipio: data.municipio,
			direccion: data.direccion,
			barrio: data.barrio,
			pago: null,
			telefonofijo_persona: data.telefonofijo,
			celular_persona: data.celular,
			telefonofijo_empresa: null,
			celular_empresa: null,
			plan: null,
			sub_cuenta: true,
			padre_id: localStorage.getItem("id_pasiolo"),
		}
		// const dataUser = mapDataUser(data)
		await createUser({ usuarioData, dataUserPasioloLegal })
		history.push("/subcuentas")
	}

	return <FormPerson onSubmit={onSubmitForm} />
}

export default CreateSubaccoutForm
