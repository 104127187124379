import { Box } from "@material-ui/core"
import Button from "common/components/inputs/basics/Button"
import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import { useHistory } from "react-router-dom"
import SubaccoutsTable from "./components/subaccountsTable"

const Subaccouts = () => {
	const history = useHistory()

	const redirectToCreateSubaccount = () => {
		history.push(`/subcuentas/create`)
	}
	return (
		<DashboardLayout>
			<MainProvider>
				<Box maxWidth={900}>
					<Box mb={3} display="flex" justifyContent="end">
						<Button
							color="primary"
							onClick={redirectToCreateSubaccount}
						>
							Nueva subcuenta
						</Button>
					</Box>
					<SubaccoutsTable />
				</Box>
			</MainProvider>
		</DashboardLayout>
	)
}

export default Subaccouts
