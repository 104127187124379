import request from "common/plugins/request"
import { useMemo, useState } from "react"

export const useSignUp = () => {
	const [activePlan, setActivePlan] = useState({})

	const changePlan = (data) => {
		setActivePlan(data)
	}

	const getPlans = async () => {
		const { results } = await request.get("planes/")
		return results
	}

	const createUser = async (dataUser) => {
		return await request.post("userdata/userpasiololegal/", dataUser)
	}

	const updateUser = async (userID, dataUser) => {
		return await request.patch(
			"userdata/updateuserpasiololegal/",
			dataUser,
			{ params: { id_user: userID } }
		)
	}

	return useMemo(
		() => ({
			getPlans,
			activePlan,
			changePlan,
			createUser,
			updateUser,
		}),
		// eslint-disable-next-line
		[activePlan]
	)
}
