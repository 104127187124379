import { Avatar, Box, IconButton, Paper } from "@material-ui/core"
import { MainProvider } from "common/context/mainContext/mainContext"
import useGuard from "common/hooks/useGuard"
import DashboardLayout from "common/layouts/DashboardLayout"
import EditLoggedUserForm from "./EdiLoggedUserForm"

const EditLoggedUser = () => {
	const { user } = useGuard()
	if (!user?.id) return null
	console.log("user", user)

	const mapSubaccount = {}
	mapSubaccount.nombre = user?.name
	mapSubaccount.apellido = user?.apellido
	mapSubaccount.email = user?.email
	mapSubaccount.cedula = user?.cedula
	mapSubaccount.celular = user?.celular_persona
	mapSubaccount.telefonofijo = user?.telefonofijo_persona
	mapSubaccount.direccion = user?.direccion
	mapSubaccount.departamento = user?.departamento
	mapSubaccount.municipio = user?.municipio
	mapSubaccount.barrio = user?.barrio

	return (
		<DashboardLayout>
			<MainProvider>
				<Box component={Paper} p={4} maxWidth={650} margin="0 auto">
					<Box display="flex" justifyContent="center" mb={3}>
						<IconButton color="primary">
							<Avatar style={{ width: 110, height: 110 }} />
						</IconButton>
					</Box>
					<EditLoggedUserForm user={mapSubaccount} />
				</Box>
			</MainProvider>
		</DashboardLayout>
	)
}

export default EditLoggedUser
