import { Box, Grid } from "@material-ui/core"
import {
	Autocomplete,
	Button,
	Input,
} from "common/components/inputs/basics"
import Modal from "common/components/modal"
import Title from "common/components/text/Title"
import { useMainContext } from "common/context/mainContext/mainContext"
import { useFormik } from "formik"
import { matchSorter } from "match-sorter"
import moment from "moment"
import React, { useEffect } from "react"
import * as Yup from "yup"

const GenerateEvent = ({
	isOpen = true,
	activeEvent,
	clearActiveEvent,
	typeAction,
}) => {
	const { createEvent, updateEvent, deleteEvent } =
		useMainContext().eventsCalendar
	const { processes, getProcesses } = useMainContext().process
	const { subaccounts, getSubaccounts } = useMainContext().subaccounts
	const filterCup = (options, { inputValue }) =>
		matchSorter(options, inputValue)

	useEffect(() => {
		getProcesses()
		getSubaccounts()
		// eslint-disable-next-line
	}, [])

	const typeEvent = {
		AUDIENCIAS: "Audiencias",
		REUNIONES: "Reuniones",
		RECORDATORIOS: "Recordatorios",
		VTERMINOS: "Vencimiento de terminos",
	}

	const typeEventColors = {
		AUDIENCIAS: "#83d5d0",
		REUNIONES: "#ed6d7f",
		RECORDATORIOS: "#f8da67",
		VTERMINOS: "#a0ced9",
	}

	const typeEventValues = Object.keys(typeEvent).map((k) => [
		k,
		typeEvent[k],
	])

	const eventSchema = Yup.object().shape({
		title: Yup.string().required("Requerido").nullable(),
		address: Yup.string(),
		description: Yup.string(),
		start: Yup.string().required("Requerido").nullable(),
		startTime: Yup.string().required("Requerido").nullable(),
		end: Yup.string().required("Requerido").nullable(),
		endTime: Yup.string().required("Requerido").nullable(),
		typeEvent: Yup.string().required("Requerido").nullable(),
		cup: Yup.string().when("typeEvent", (_typeEvent) => {
			if (_typeEvent === "AUDIENCIAS" || _typeEvent === "VTERMINOS")
				return Yup.string().required("Requerido")
		}),
		invited: Yup.array().default([]),
	})
	const eventForm = useFormik({
		validationSchema: eventSchema,
		initialValues: {
			start: activeEvent?.start
				? moment(activeEvent.start).format("YYYY-MM-DD")
				: "",
			startTime: activeEvent?.start
				? moment(activeEvent.start).format("HH:mm")
				: "",
			end: activeEvent?.end
				? moment(activeEvent.end).format("YYYY-MM-DD")
				: "",
			endTime: activeEvent?.end
				? moment(activeEvent.end).format("HH:mm")
				: "",
			cup: activeEvent.cup,
			title: activeEvent.title,
			address: activeEvent.address,
			typeEvent: activeEvent.event_type,
			description: activeEvent.description,
			invited: [],
		},
		onSubmit(values) {
			const event = {
				...values,
				display: "auto",
			}
			event.backgroundColor = typeEventColors[values.typeEvent]
			event.borderColor = typeEventColors[values.typeEvent]

			const sendEvent = {
				...event,
				event_type: event.typeEvent,
				endDate: moment(`${event.end} ${event.endTime}`),
				startDate: moment(`${event.start} ${event.startTime}`),
			}
			if (activeEvent?.id) {
				updateEvent(activeEvent.id, sendEvent)
			} else {
				createEvent(sendEvent)
			}
			clearActiveEvent()
		},
	})

	const onDeleteEvent = () => {
		deleteEvent(activeEvent.id)
		clearActiveEvent()
	}

	return (
		<Modal isOpen={isOpen} onClose={() => clearActiveEvent()}>
			<Title size="h3" align="center" style={{ marginBottom: 20 }}>
				{typeAction === "create"
					? "Agendar un nuevo evento"
					: "Actualizar evento"}
			</Title>
			<form
				noValidate
				autoComplete="off"
				onSubmit={eventForm.handleSubmit}
			>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Input
							name="title"
							label="Titulo"
							onChange={eventForm.handleChange}
							value={eventForm.values.title}
							errorText={eventForm.errors.title}
							error={
								eventForm.touched.title && !!eventForm.errors.title
							}
						></Input>
					</Grid>
					<Grid item xs={12}>
						<Input
							rows={4}
							multiline
							name="description"
							label="Descripción"
							onChange={eventForm.handleChange}
							value={eventForm.values.description}
						></Input>
					</Grid>

					<Grid item xs={12}>
						<Input
							name="address"
							label="Dirección"
							onChange={eventForm.handleChange}
							value={eventForm.values.address}
							multiline
						></Input>
					</Grid>

					<Grid item>
						<Input
							name="start"
							label="Desde"
							type="date"
							onChange={eventForm.handleChange}
							value={eventForm.values.start}
							errorText={eventForm.errors.start}
							error={
								eventForm.touched.start && !!eventForm.errors.start
							}
						></Input>
					</Grid>
					<Grid item>
						<Input
							name="startTime"
							type="time"
							onChange={eventForm.handleChange}
							value={eventForm.values.startTime}
							inputProps={{ step: 300 }}
							errorText={eventForm.errors.startTime}
							error={
								eventForm.touched.startTime &&
								!!eventForm.errors.startTime
							}
						></Input>
					</Grid>
					<Grid item>
						<Input
							name="end"
							label="Hasta"
							type="date"
							onChange={eventForm.handleChange}
							value={eventForm.values.end}
							errorText={eventForm.errors.end}
							error={eventForm.touched.end && !!eventForm.errors.end}
						></Input>
					</Grid>
					<Grid item>
						<Input
							name="endTime"
							type="time"
							onChange={eventForm.handleChange}
							value={eventForm.values.endTime}
							inputProps={{ step: 300 }}
							errorText={eventForm.errors.endTime}
							error={
								eventForm.touched.endTime &&
								!!eventForm.errors.endTime
							}
						></Input>
					</Grid>
					<Grid item xs={5}>
						<Input
							select
							name="typeEvent"
							label="Tipo de evento"
							onChange={eventForm.handleChange}
							value={eventForm.values.typeEvent}
							optionsSelect={typeEventValues}
							errorText={eventForm.errors.typeEvent}
							error={
								eventForm.touched.typeEvent &&
								!!eventForm.errors.typeEvent
							}
						></Input>
					</Grid>
					{(eventForm.values.typeEvent === "AUDIENCIAS" ||
						eventForm.values.typeEvent === "VTERMINOS") && (
						<Grid item xs={7}>
							<Autocomplete
								label="CUP"
								filterOptions={filterCup}
								options={processes.map(({ cup }) => cup)}
								onInputChange={(_, cup) =>
									eventForm.setFieldValue("cup", cup)
								}
								getOptionLabel={(cup) => cup}
								value={eventForm.values.cup}
							/>
						</Grid>
					)}
					{!!subaccounts.length && (
						<Grid item xs={7}>
							<Autocomplete
								label="Invitados"
								getOptionLabel={(subaccount) => subaccount}
								options={subaccounts.map(({ name }) => name)}
								onInputChange={(_, invited) =>
									console.log("event: ", _, invited)
								}
							/>
						</Grid>
					)}
				</Grid>
				<Box
					marginTop={4}
					display="flex"
					justifyContent="space-between"
				>
					<Button color="primary" type="submit">
						{typeAction === "create"
							? "Guardar evento"
							: "Actualizar evento"}
					</Button>
					{typeAction !== "create" && (
						<Button type="button" onClick={onDeleteEvent}>
							Eliminar evento
						</Button>
					)}
				</Box>
			</form>
		</Modal>
	)
}

export default GenerateEvent
