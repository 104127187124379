import {
	Box,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import React from "react"

const DataTable = ({
	data = [],
	title = "",
	headers = [],
	titleBackground = true,
	canUpdated = false,
	canDeleted = false,
	onUpdated,
	onDeleted,
}) => {
	const classes = useStyles()
	const classTitle = titleBackground
		? classes.title_table
		: classes.title_table_without_bg

	return (
		<>
			{!!title.length && <Box className={classTitle}>{title}</Box>}
			<TableContainer component={Paper}  style={{ maxHeight: 400 }}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							{headers.map((header, index) => (
								<TableCell className={classes.head_table} key={index}>
									{header}
								</TableCell>
							))}
							{canUpdated && (
								<TableCell
									className={classes.head_table}
									width={20}
								></TableCell>
							)}
							{canDeleted && (
								<TableCell
									className={classes.head_table}
									width={20}
								></TableCell>
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{data.length ? (
							data.map((item, index) => (
								<TableRow key={index}>
									{item.map((value, subindex) => (
										<>
											<TableCell
												className={classes.body_table}
												key={subindex}
											>
												{value}
											</TableCell>
										</>
									))}
									{canUpdated && (
										<TableCell
											className={classes.body_table}
											align="center"
										>
											<EditIcon
												onClick={() => onUpdated(index)}
												style={{ cursor: "pointer " }}
											></EditIcon>
										</TableCell>
									)}
									{canDeleted && (
										<TableCell
											className={classes.body_table}
											align="center"
										>
											<DeleteIcon
												onClick={() => onDeleted(index)}
												style={{ cursor: "pointer " }}
											></DeleteIcon>
										</TableCell>
									)}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell
									className={classes.body_table}
									colSpan={100}
									align="center"
								>
									No se encontraron registros en la tabla
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}

const useStyles = makeStyles((theme) => ({
	title_table: {
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		backgroundColor: theme.palette.primary.main,
		padding: 12,
		color: theme.palette.light,
		fontWeight: 500,
		fontSize: 14,
	},

	title_table_without_bg: {
		paddingTop: 5,
		paddingBottom: 10,
		fontWeight: 600,
		fontSize: 14,
		color: theme.palette.primary.main,
	},

	head_table: {
		color: theme.palette.darkGray,
		fontWeight: 500,
		fontSize: 13,
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 15,
		paddingRight: 15,
	},
	body_table: {
		color: theme.palette.lightGray,
		fontWeight: 500,
		fontSize: 13,
		paddingTop: 12,
		paddingBottom: 12,
		paddingLeft: 15,
		paddingRight: 15,
	},
}))

export default DataTable
