import { Switch, TableCell, TableRow } from "@material-ui/core"
import { useMainContext } from "common/context/mainContext/mainContext"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const ProcessesSubaccountTableRowHead = () => (
	<TableRow>
		<TableCell width="10%" align="center">
			Radicado
		</TableCell>
		<TableCell width="20%" align="center">
			Despacho
		</TableCell>
		<TableCell width="15%" align="center">
			Ciudad
		</TableCell>
		<TableCell width="15%" align="center">
			Demandante
		</TableCell>
		<TableCell width="15%" align="center">
			Demandado
		</TableCell>
		<TableCell width="10%" align="center">
			Asignado
		</TableCell>
	</TableRow>
)

const ProcessesSubaccountTableRowBody = ({ row }) => {
	const { getProcesses, updateProcess } = useMainContext().process
	const { subaccountID } = useParams()
	const isAssigned = row?.subaccount?.includes(subaccountID)

	const toggleAssignProcess = async () => {
		const subaccount = row.subaccount ? row.subaccount : []
		if (!isAssigned) {
			subaccount.push(subaccountID)
		} else {
			const subaccountIndex = subaccount.indexOf(subaccountID)
			subaccount.splice(subaccountIndex, 1)
		}
		await updateProcess(row.id, {
			subaccount,
		})
		await getProcesses()
	}

	return (
		<TableRow hover={true}>
			<TableCell align="center">{row?.radicado}</TableCell>
			<TableCell>{row.despacho_de_origen_del_proceso}</TableCell>
			<TableCell align="center">{row.ciudad}</TableCell>
			<TableCell>{row.dte}</TableCell>
			<TableCell>{row.ddo}</TableCell>
			<TableCell align="center">
				<Switch
					color="primary"
					name="activo"
					checked={isAssigned}
					onChange={() => toggleAssignProcess(row.id)}
				/>
			</TableCell>
		</TableRow>
	)
}

export {
	ProcessesSubaccountTableRowBody,
	ProcessesSubaccountTableRowHead,
}
