import { Grid } from "@material-ui/core"
import { Input } from "common/components/inputs/basics"
import FuncionalityDataTable from "common/components/table/funcionalityDataTable"
import Title from "common/components/text/Title"
import React from "react"
import * as Yup from "yup"

const AssociatedCosts = ({ data, changeData }) => {
	const validationSchema = Yup.object().shape({
		descripcion: Yup.string().required("Requerido"),
		valor: Yup.string().required("Requerido"),
	})
	return (
		<>
			<Title size="h4" marginBottom={0.5}>
				Costos Asociados
			</Title>

			<FuncionalityDataTable
				headers={["Descripción", "Valor"]}
				initialValues={{ descripcion: "", valor: "" }}
				onChangeData={(newData) => changeData(newData)}
				typesValues={{ valor: "money"}}
				validationSchema={validationSchema}
				data={data}
			>
				{({ handleValue, values, setFieldValue, touched, errors }) => (
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<Input
								name="descripcion"
								label="Descripción"
								onChange={handleValue}
								value={values.descripcion}
								errorText={errors.descripcion}
								error={
									touched.descripcion &&
									!!errors.descripcion
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<Input
								name="valor"
								label="Valor"
								type="currency"
								value={values.valor}
								onChange={(_, value)=> setFieldValue("valor", value)}
								currencySymbol="$"
								errorText={errors.valor}
								error={
									touched.valor &&
									!!errors.valor
								}
							/>
						</Grid>
					</Grid>
				)}
			</FuncionalityDataTable>
		</>
	)
}

export default AssociatedCosts
