import { Box, List, ListItem, Tooltip, Zoom } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 280,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9",
		zIndex: 10,
	},
}))(Tooltip)

const Ellipsis = withStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.light,
		height: "12px",
		lineHeight: "5px",
		width: "15px",
		textAlign: "center",
		borderRadius: "2px",
		fontWeight: 600,
		marginLeft: 2,
	},
}))(Box)

export const ProcessTruncateCUP = ({ cup, processCode }) => {
	return (
		<>
			<LightTooltip
				leaveDelay={500}
				placement="right"
				TransitionComponent={Zoom}
				PopperProps={{
					disablePortal: true,
				}}
				title={
					<>
						<List style={{ padding: 0 }}>
							<ListItem style={{ paddingBottom: 0 }}>
								<strong>Radicado</strong>: {processCode}
							</ListItem>
							<ListItem style={{ paddinTop: 0 }}>
								<strong>CUP</strong>: {cup}
							</ListItem>
						</List>
					</>
				}
			>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					{processCode}
					<Ellipsis>...</Ellipsis>
				</Box>
			</LightTooltip>
		</>
	)
}
