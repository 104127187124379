import {
	Card,
	CardContent,
	Grid,
	makeStyles
} from "@material-ui/core"
import React from "react"

const useStyles = makeStyles((theme) => ({
	cardContent: {
		fontSize: 13,
		"& p": { padding: 6 },
		"& strong": {
			color: theme.palette.darkGray,
			marginRight: 5,
		},
	},
	titleCard: {
		paddingTop: 5,
		paddingBottom: 10,
		fontWeight: 600,
		fontSize: 14,
		color: theme.palette.primary.main,
	},
}))

const CardReportData = ({ process = {} }) => {
	const classes = useStyles()


	return (
		<Card className={classes.card}>
			<CardContent className={classes.cardContent}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<p>
							<strong>CUP: </strong>
							12345678
						</p>

						<p>
							<strong>Radicado Interno:</strong>
							20180220922
						</p>

						<p>
							<strong>Despacho de Origen del Proceso:</strong>
							Despacho nacional
						</p>

						<p>
							<strong>Abogado y/o Responsable del Proceso:</strong>
							Nombre del padre - Nombre del hijo
						</p>
					</Grid>

					<Grid item xs={12} sm={6} >
						<p>
							<strong>Cuantía: </strong>
						</p>

						<p>
							<strong>Costos Asociados:</strong>
							$10.000
						</p>

						<p>
							<strong>Despacho Actual del Proceso:</strong>
							Despacho actual
						</p>
					</Grid>


				</Grid>
			</CardContent>
		</Card>
	)
}
export default CardReportData
