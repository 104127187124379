import { Box, Button, Grid } from "@material-ui/core"
import { Autocomplete, Input } from "common/components/inputs/basics"
import useCities from "common/hooks/useCities"
import useOfficeTypesGroup from "common/hooks/useOfficeTypesGroup"
import useOffices from "common/hooks/useOffices"
import { UcFirst } from "containers/signUp/utils/UcFirst"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const GeneralNotificationsFilters = () => {
	const history = useHistory()
	const { cities } = useCities()
	const { officeTypesGroup } = useOfficeTypesGroup()
	const { offices, getOffices } = useOffices()

	const queryParamsFilters = useMemo(() => {
		const queryParams = new URLSearchParams(history.location.search)
		let filters = {
			date: queryParams.get("date") || moment().format("YYYY-MM-DD"),
		}
		const typeOffice = queryParams.get("typeOffice")
		const city = queryParams.get("city")
		const office = queryParams.get("office")

		if (city) filters.city = city
		if (office) filters.office = office
		if (typeOffice) filters.typeOffice = typeOffice

		return filters
	}, [history.location.search])

	const [
		generalNotificationsFilters,
		setGeneralNotificationsFilters,
	] = useState(queryParamsFilters)

	useEffect(() => {
		const { city } = generalNotificationsFilters
		if (city) getOffices(city)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generalNotificationsFilters.city])

	const onChangeFilters = (filters) => {
		history.push({
			pathname: history.location.pathname,
			search: new URLSearchParams(filters).toString(),
		})
	}

	return (
		<Box display="flex" alignItems="center">
			<Grid container spacing={1} style={{ width: 700 }}>
				<Grid item xs={1.5}>
					<Input
						type="date"
						name="date"
						label="Fecha"
						onChange={({ target: { value } }) => {
							setGeneralNotificationsFilters({
								...generalNotificationsFilters,
								date: value,
							})
						}}
						value={generalNotificationsFilters.date}
					/>
				</Grid>

				<Grid item xs={3}>
					<Autocomplete
						label="Ciudad"
						options={cities.map(({ name }) => name)}
						onChange={(_, city) =>
							setGeneralNotificationsFilters({
								...generalNotificationsFilters,
								city,
							})
						}
						getOptionLabel={(city) => UcFirst(city)}
						value={generalNotificationsFilters.city}
						disabled={!cities.length}
					/>
				</Grid>

				<Grid item xs={3}>
					<Autocomplete
						label="Tipo despacho"
						options={officeTypesGroup?.map(({ type }) => type)}
						onChange={(_, typeOffice) =>
							setGeneralNotificationsFilters({
								...generalNotificationsFilters,
								typeOffice,
							})
						}
						getOptionLabel={(type) => UcFirst(type)}
						value={generalNotificationsFilters.typeOffice}
						disabled={!officeTypesGroup.length}
					/>
				</Grid>

				<Grid item xs={3}>
					<Autocomplete
						label="Despacho"
						options={offices.map(({ name }) => name)}
						onInputChange={(_, office) =>
							setGeneralNotificationsFilters({
								...generalNotificationsFilters,
								office,
							})
						}
						getOptionLabel={(office) => UcFirst(office)}
						value={generalNotificationsFilters.office}
						disabled={!offices.length}
					/>
				</Grid>
			</Grid>

			<Button
				color="primary"
				variant="contained"
				style={{ marginLeft: 10 }}
				onClick={() => onChangeFilters(generalNotificationsFilters)}
			>
				Filtrar
			</Button>
		</Box>
	)
}

export default GeneralNotificationsFilters
