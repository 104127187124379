import { styled } from "@material-ui/core"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import { useState } from "react"
import Input from "./Input"

const InputPassword = (props) => {
	const [isRevealPassword, setRevealPassword] = useState(false)

	return (
		<InputPasswordStyled>
			<Input
				{...props}
				type={isRevealPassword ? "text" : "password"}
			/>

			<div
				className="reveal-pass"
				onClick={() => setRevealPassword(!isRevealPassword)}
			>
				{!isRevealPassword ? (
					<VisibilityIcon />
				) : (
					<VisibilityOffIcon />
				)}
			</div>
		</InputPasswordStyled>
	)
}

const InputPasswordStyled = styled("div")({
	display: "flex",
	position: "relative",
	"& .reveal-pass": {
		position: "absolute",
		top: 0,
		right: 0,
		transform: "translate(-50%, 50%)",
		color: "#ccc",
		cursor: "pointer",
		lineHeight: 0,
		marginYop: -1,
	},
})

export default InputPassword
