import request from "common/plugins/request"
import moment from "moment"
import { useMemo, useState } from "react"

export const useEventsCalendar = () => {
	const [events, setEvents] = useState([])

	const getEvents = async () => {
		const { results: events } = await request.get("/agenda/")
		const eventsMapped = events.map(
			({ startDate: start, endDate: end, ...event }) => ({
				borderColor: "transparent",
				...event,
				start,
				end,
			})
		)
		setEvents(eventsMapped)
	}

	const createEvent = async ({
		cup,
		title,
		address,
		endDate,
		startDate,
		event_type,
		description,
		backgroundColor,
	}) => {
		console.log("going")
		const formatDateTime = "YYYY-MM-DD HH:mm:ss"
		await request.post("/agenda/", {
			cup,
			title,
			address,
			event_type,
			description,
			backgroundColor,
			endDate: moment(endDate).format(formatDateTime),
			startDate: moment(startDate).format(formatDateTime),
		})
		getEvents()
	}

	const updateEvent = async (
		id,
		{
			cup,
			title,
			address,
			endDate,
			startDate,
			event_type,
			description,
			backgroundColor,
		}
	) => {
		const formatDateTime = "YYYY-MM-DD HH:mm:ss"
		await request.put(`/agenda/${id}/`, {
			cup,
			title,
			address,
			event_type,
			description,
			backgroundColor,
			endDate: moment(endDate).format(formatDateTime),
			startDate: moment(startDate).format(formatDateTime),
		})
		getEvents()
	}

	const deleteEvent = async (id) => {
		await request.delete(`/agenda/${id}/`)
		getEvents()
	}

	return useMemo(
		() => ({
			events,
			getEvents,
			createEvent,
			updateEvent,
			deleteEvent,
		}),
		// eslint-disable-next-line
		[events]
	)
}
