import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
} from "@material-ui/core/"
import FormHelperText from "@material-ui/core/FormHelperText"
import { Autocomplete, Input } from "common/components/inputs/basics"
import Title from "common/components/text/Title"

import useCities from "common/hooks/useCities"
import useSpecialities from "common/hooks/useSpecialities"
import useTypeProcess from "common/hooks/useTypeProcess"
import { GenerateCup } from "containers/addProcess/components/GenerateCup"

import { useMainContext } from "common/context/mainContext/mainContext"
import useNotificationsCUP from "common/hooks/useNotificationsCUP"
import useOffices from "common/hooks/useOffices"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import Claimant from "./claimant"
import ClaimantAttorney from "./claimantAttorney"
import Defendant from "./defendant"
import DefendantAttorney from "./defendantAttorney"
import ProcessSchema from "./processFormSchema"

const ProcessForm = ({ children, ...process }) => {
	const { validationSchema, initialValues } = ProcessSchema({
		...process,
	})
	const processForm = useFormik({
		validationSchema,
		initialValues,
		onSubmit() {},
	})

	const { specilities } = useSpecialities()
	const { typeProcess } = useTypeProcess()
	const { cities } = useCities()
	const { offices, clearOffices, getOffices } = useOffices()
	const { notifications, getNotificationsCUP } = useNotificationsCUP()
	const [officeCUD, setOfficeCUD] = useState()

	const [isFailureAutocomplete, setIsFailureAutocomplete] = useState()

	const {
		process: { getBasicInfoByCUP },
	} = useMainContext()

	console.log("processForm", processForm.values)

	const onGeneratedCUP = async ({
		CUP,
		codeCity,
		codeOffice,
		numberOffice,
		yearProcess,
		consecutive,
	}) => {
		getNotificationsCUP(CUP)
		changeCityByCUP(codeCity)
		setOfficeCUD(`${codeCity}${codeOffice}${numberOffice}`)

		processForm.setValues({
			...processForm.values,
			ano_del_Proceso: yearProcess,
			consecutivo: consecutive,
			data_dte: processForm.values.data_dte?.length
				? processForm.values.data_dte
				: [],
			data_ddo: processForm.values.data_ddo?.length
				? processForm.values.data_ddo
				: [],
			cup: CUP,
		})

		getBasicInfoByCUP(CUP).then(
			({ dte: plaintiff, ddo: defendant }) => {
				if (!plaintiff && !defendant) {
					setIsFailureAutocomplete(true)
					return setTimeout(
						() => setIsFailureAutocomplete(false),
						1500
					)
				}

				const plaintiffs = processForm.values.data_dte
				plaintiffs.push({ cedula: "", nombre: plaintiff })
				processForm.setFieldValue("data_dte", plaintiffs)

				const defendants = processForm.values.data_ddo
				defendants.push({ cedula: "", nombre: defendant })
				processForm.setFieldValue("data_ddo", defendants)

				console.log("processForm", processForm)
			}
		)
	}

	const changeCityByCUP = (cityInCUP) => {
		const cityByCUP = cities.find(
			({ dane_code_city }) => dane_code_city === cityInCUP
		)
		if (cityByCUP?.name) onChangeCity(cityByCUP.name)
	}

	const changeOfficeByCUP = () => {
		const officeByCUP = offices.find(({ cud }) =>
			cud.includes(officeCUD)
		)
		if (officeByCUP?.name) {
			processForm.setFieldValue(
				"despacho_cursa_proceso",
				officeByCUP.name
			)
		}
		setOfficeCUD(null)
	}

	const onChangeCity = (cityName) => {
		if (cityName) {
			processForm.setFieldValue("ciudad_cursa_proceso", cityName)
		} else {
			clearOffices()
			processForm.setFieldValue("ciudad_cursa_proceso", null)
		}
		processForm.setFieldValue("despacho_cursa_proceso", null)
	}

	useEffect(() => {
		if (processForm?.values?.ciudad_cursa_proceso)
			getOffices(processForm.values.ciudad_cursa_proceso)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [processForm?.values?.ciudad_cursa_proceso])

	useEffect(() => {
		if (!!offices?.length && officeCUD) changeOfficeByCUP()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offices])

	useEffect(() => {
		if (notifications?.length) {
			processForm.setFieldValue("data_dte", [
				...processForm.values.data_dte,
				{
					cedula: "",
					nombre: notifications[0]?.dte,
				},
			])

			processForm.setFieldValue("data_ddo", [
				...processForm.values.data_ddo,
				{
					cedula: "",
					nombre: notifications[0]?.ddo,
				},
			])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifications])

	return (
		<>
			{isFailureAutocomplete && (
				<Dialog open>
					<DialogTitle>Error al autocompletar proceso</DialogTitle>
					<DialogContent>
						No se pudo autocompletar el proceso, complete el
						formulario manualmente
					</DialogContent>
				</Dialog>
			)}
			<form noValidate autoComplete="off">
				<Title size="h4" marginBottom={0.5}>
					Generar CUP:
				</Title>
				<Box marginBottom={4}>
					<GenerateCup
						value={processForm.values.cup}
						onChange={onGeneratedCUP}
					/>
				</Box>

				<Title size="h4" marginTop={2} marginBottom={0.5}>
					Datos generales:
				</Title>
				<Box marginBottom={4}>
					<Grid container spacing={2}>
						<Grid item xs={3}>
							<Autocomplete
								name="tipo_de_proceso"
								label="Tipo de proceso"
								getOptionLabel={(typeProcess) => typeProcess}
								options={typeProcess.map(({ name }) => name)}
								onChange={(_, typeProcess) =>
									processForm.setFieldValue(
										"tipo_de_proceso",
										typeProcess
									)
								}
								value={processForm.values.tipo_de_proceso}
								errorText={processForm.errors.tipo_de_proceso}
								error={
									processForm.touched.tipo_de_proceso &&
									!!processForm.errors.tipo_de_proceso
								}
								disabled={!specilities.length}
							/>
						</Grid>

						<Grid item xs={6}>
							<Autocomplete
								name="especialidad"
								label="Especialidad"
								getOptionLabel={(specility) => specility}
								options={specilities.map(({ name }) => name)}
								onChange={(_, specility) =>
									processForm.setFieldValue("especialidad", specility)
								}
								value={processForm.values.especialidad}
								errorText={processForm.errors.especialidad}
								error={
									processForm.touched.especialidad &&
									!!processForm.errors.especialidad
								}
								disabled={!specilities.length}
							/>
						</Grid>

						<Grid item xs={3}>
							<Input
								select
								name="tipodeparte"
								label="Tipo de parte"
								onChange={processForm.handleChange}
								value={processForm.values.tipodeparte}
								optionsSelect={[
									["DEMANDANTE", "Demandante"],
									["DEMANDADO", "Demandado"],
									["INTERVINIENTE", "Interviniente"],
								]}
								errorText={processForm.errors.tipodeparte}
								error={
									processForm.touched.tipodeparte &&
									!!processForm.errors.tipodeparte
								}
							/>
						</Grid>
					</Grid>
				</Box>
				<Title size="h4" marginTop={2} marginBottom={0.5}>
					Despacho donde cursa el proceso:
				</Title>

				<Grid container spacing={2}>
					<Grid item xs={4}>
						<Autocomplete
							label="Ciudad"
							options={cities.map(({ name }) => name)}
							onChange={(_, cityName) => onChangeCity(cityName)}
							value={processForm.values.ciudad_cursa_proceso}
							getOptionLabel={(cityName) => cityName}
							errorText={processForm.errors.ciudad_cursa_proceso}
							error={
								processForm.touched.ciudad_cursa_proceso &&
								!!processForm.errors.ciudad_cursa_proceso
							}
							disabled={!cities.length}
						/>
					</Grid>
					<Grid item xs={8}>
						<Autocomplete
							label="Nombre del Despacho"
							options={offices.map(
								({ nombre_publico }) => nombre_publico
							)}
							onChange={(_, officeName) => {
								processForm.setFieldValue(
									"despacho_cursa_proceso",
									officeName
								)
							}}
							getOptionLabel={(officeName) => officeName}
							value={processForm.values.despacho_cursa_proceso}
							errorText={processForm.errors.despacho_cursa_proceso}
							error={
								processForm.touched.despacho_cursa_proceso &&
								!!processForm.errors.despacho_cursa_proceso
							}
							disabled={!offices.length}
						/>
					</Grid>
				</Grid>

				<Title size="h4" marginTop={2} marginBottom={0.5}>
					Dato demandante:
				</Title>
				<Box marginBottom={4}>
					<Grid container spacing={5}>
						<Grid item xs={12}>
							<Claimant
								data={processForm.values.data_dte}
								changeData={(newData) =>
									processForm.setFieldValue("data_dte", newData)
								}
							/>
							{processForm.touched.data_dte &&
								!!processForm.errors.data_dte && (
									<FormHelperText error={true}>
										{processForm.errors.data_dte}
									</FormHelperText>
								)}
						</Grid>

						<Grid item xs={12}>
							<ClaimantAttorney
								data={processForm.values.data_abogadodte}
								changeData={(newData) =>
									processForm.setFieldValue(
										"data_abogadodte",
										newData
									)
								}
							/>
							{processForm.touched.data_abogadodte &&
								!!processForm.errors.data_abogadodte && (
									<FormHelperText error={true}>
										{processForm.errors.data_abogadodte}
									</FormHelperText>
								)}
						</Grid>
					</Grid>
				</Box>
				<Title size="h4" marginTop={2} marginBottom={0.5}>
					Dato demandado:
				</Title>
				<Box marginBottom={4}>
					<Grid container spacing={5}>
						<Grid item xd={12}>
							<Defendant
								data={processForm.values.data_ddo}
								changeData={(newData) =>
									processForm.setFieldValue("data_ddo", newData)
								}
							/>
							{processForm.touched.data_ddo &&
								!!processForm.errors.data_ddo && (
									<FormHelperText error={true}>
										{processForm.errors.data_ddo}
									</FormHelperText>
								)}
						</Grid>

						<Grid item xs={12}>
							<DefendantAttorney
								data={processForm.values.data_abogadoddo}
								changeData={(newData) =>
									processForm.setFieldValue(
										"data_abogadoddo",
										newData
									)
								}
							/>
							{processForm.touched.data_abogadoddo &&
								!!processForm.errors.data_abogadoddo && (
									<FormHelperText error={true}>
										{processForm.errors.data_abogadoddo}
									</FormHelperText>
								)}
						</Grid>
					</Grid>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{children(processForm)}
					</Grid>
				</Grid>
			</form>
		</>
	)
}

export default ProcessForm
