const { default: request } = require("common/plugins/request")

const useGeneralNotifications = () => {
	const getGeneralNotification = async (params) => {
		const response = await request.get(
			"notificacionesgenerales/data/",
			{ params }
		)

		if (response?.total >= 0)
			return {
				totalNotifications: response?.total,
				notifications: response?.resuslts?.length
					? response.resuslts
					: [],
			}
	}

	return {
		getGeneralNotification,
	}
}

export default useGeneralNotifications
