import { Grid } from "@material-ui/core"
import DataTable from "common/components/table/dataTable"
import { useMainContext } from "common/context/mainContext/mainContext"
import React from "react"

const ProcessPrivateData = () => {
	const { process } = useMainContext().process

	return (
		<Grid container spacing={5}>
			<Grid item xs={12} md={6}>
				<DataTable
					title="Costos asociados"
					headers={["Descripción", "Valor"]}
					titleBackground={false}
					data={
						process.detalle_del_proceso?.data_costos
							? process.detalle_del_proceso?.data_costos.map(
									({ descripcion, valor }) => [descripcion, valor]
							  )
							: []
					}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<DataTable
					title="Pendientes"
					headers={["Actividad", "Responsable"]}
					titleBackground={false}
					data={
						process.detalle_del_proceso?.datos_pendientes
							? process.detalle_del_proceso?.datos_pendientes.map(
									({ actividad, nombre }) => [actividad, nombre]
							  )
							: []
					}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<DataTable
					title="Cuantía"
					headers={["Descripción", "Valor"]}
					titleBackground={false}
					data={
						process.detalle_del_proceso?.datos_cuantia
							? process.detalle_del_proceso?.datos_cuantia.map(
									({ descripcion, valor }) => [descripcion, valor]
							  )
							: []
					}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<DataTable
					title="Pretensiones"
					headers={["Descripción"]}
					titleBackground={false}
					data={
						process.detalle_del_proceso?.datos_pretensiones
							? process.detalle_del_proceso?.datos_pretensiones.map(
									({ pretension }) => [pretension]
							  )
							: []
					}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<DataTable
					title="Contestación y excepciones"
					headers={["Descripción"]}
					titleBackground={false}
					data={
						process.detalle_del_proceso?.exepciones
							? [[process.detalle_del_proceso?.exepciones]]
							: []
					}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<DataTable
					title="Hechos resumen"
					headers={["Descripción"]}
					titleBackground={false}
					data={
						process.detalle_del_proceso?.hechos
							? [[process.detalle_del_proceso?.hechos]]
							: []
					}
				/>
			</Grid>
		</Grid>
	)
}

export default ProcessPrivateData
