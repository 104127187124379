import { createContext, useContext } from "react"
import { useAddress } from "./hooks/useAddress"
import { useEventsCalendar } from "./hooks/useEventsCalendar"
import { useNotifications } from "./hooks/useNotifications"
import { useOffices } from "./hooks/useOffices"
import { useProcess } from "./hooks/useProcess"
import { useSearchNotifications } from "./hooks/useSearchNotifications"
import { useSignUp } from "./hooks/useSignUp"
import { useSubaccounts } from "./hooks/useSubaccounts"
import { useVSNotifications } from "./hooks/useVSNotifications"

const MainContext = createContext()

export const MainProvider = ({ children }) => {
	const value = {
		process: useProcess(),
		offices: useOffices(),
		notifications: useNotifications(),
		VSNotifications: useVSNotifications(),
		searchNotifications: useSearchNotifications(),
		eventsCalendar: useEventsCalendar(),
		subaccounts: useSubaccounts(),
		signUp: useSignUp(),
		address: useAddress(),
	}

	return (
		<MainContext.Provider
			value={value}
			displayName="Context Display Name"
		>
			{children}
		</MainContext.Provider>
	)
}

export const useMainContext = () => {
	const context = useContext(MainContext)

	if (!context) {
		return {}
	}

	return context
}
