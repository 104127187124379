import { Box } from "@material-ui/core"
import { DataGrid } from "common/components/DataGrid"
import { useMainContext } from "common/context/mainContext/mainContext"
import useGuard from "common/hooks/useGuard"
import { UcFirst } from "containers/signUp/utils/UcFirst"
import React, { useEffect, useMemo } from "react"
import { ProcessActionsCell } from "./ProcessActionsCell"
import { ProcessTruncateCUP } from "./ProcessTruncateCUP"
export const ProcessTable = () => {
	const { getProcesses, processes, qtyProcesses } =
		useMainContext().process

	const { isSuperUser } = useGuard()
	const [pagination, setPagination] = React.useState({
		pageIndex: 0,
		pageSize: 10, //customize the default page size
	})

	const columns = useMemo(() => {
		const defaultColumns = [
			{
				maxSize: 70,
				accessorKey: "cup",
				header: "Radicado",
				Cell: ({ renderedCellValue, row: { original } }) => {
					return (
						<ProcessTruncateCUP
							cup={renderedCellValue}
							processCode={original?.radicado}
						></ProcessTruncateCUP>
					)
				},
			},
			{
				size: 350,
				accessorKey: "despacho_cursa_proceso",
				header: "Despacho",
				Cell: ({ renderedCellValue }) => UcFirst(renderedCellValue),
			},

			{
				size: 150,
				accessorKey: "dte",
				header: "Demandante",
				Cell: ({ renderedCellValue }) => UcFirst(renderedCellValue),
			},
			{
				size: 150,
				accessorKey: "ddo",
				header: "Demandado",
				Cell: ({ renderedCellValue }) => UcFirst(renderedCellValue),
			},

			{
				size: 100,
				accessorKey: "id",
				header: "Acciones",
				Cell: ({ renderedCellValue }) => (
					<Box display="flex" justifyContent="center">
						<ProcessActionsCell id={renderedCellValue} />
					</Box>
				),
			},
		]

		if (isSuperUser) {
			defaultColumns.splice(0, 0, {
				size: 150,
				accessorKey: "razon_social",
				header: "Cliente",
				Cell: ({ renderedCellValue }) => UcFirst(renderedCellValue),
			})
		} else {
			defaultColumns.splice(4, 0, {
				size: 150,
				accessorKey: "detalle_del_proceso.datos_responsable",
				header: "Responsables",
				Cell: ({ renderedCellValue }) =>
					renderedCellValue?.length && (
						<ul>
							{renderedCellValue?.map(({ nombre, activo }) => {
								return activo === "Activo" && <li>{nombre}</li>
							})}
						</ul>
					),
			})
		}

		return defaultColumns
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [processes, isSuperUser])

	// const tableProps = useDataTable(columns, config)

	useEffect(() => {
		getProcesses({
			offset: pagination.pageIndex * 10,
			limit: pagination.pageSize,
		})
		// eslint-disable-next-line
	}, [pagination])

	return (
		<Box style={{ width: "100%" }}>
			<DataGrid
				columns={columns}
				data={processes}
				rowCount={qtyProcesses}
				enablePagination={true}
				pagination={pagination}
				onPaginationChange={setPagination}
			/>
		</Box>
	)
}
