import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import CurrencyTextField from "@unicef/material-ui-currency-textfield"

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiInputBase-input": {
			height: 20,
		},
		"& input,  & .MuiSelect-root": {
			fontSize: 14,
		},
		"& label": {
			fontSize: 15,
			fontWeight: 500,
			color: theme.palette.dark,
		},
		"& label.Mui-focused": {
			color: theme.palette.primary.main,
		},
		"& .MuiInputBase-root": {
			backgroundColor: theme.palette.light,
		},
		"& .MuiOutlinedInput-root": {
			"&.Mui-focused fieldset": {
				borderWidth: 1,
				borderColor: theme.palette.primary.main,
			},
		},
	},

	onFocusBorder: {
		"& .MuiOutlinedInput-root fieldset": {
			borderColor: "transparent !important",
		},
	},

	itemList: {
		fontSize: 13,
	},
}))

const InputBasic = ({
	errorText,
	helperText,
	className,
	optionsSelect = [],
	type = "text",
	...props
}) => {
	const classes = useStyles()

	const Field = type !== "currency" ? TextField : CurrencyTextField

	return (
		<Field
			fullWidth
			size="small"
			variant="outlined"
			className={[classes.root, className]}
			helperText={
				<div style={{ fontSize: ".73rem" }}>
					{props.error ? (errorText ? errorText : helperText) : false}
				</div>
			}
			InputLabelProps={{
				shrink: true,
			}}
			decimalCharacter=","
			digitGroupSeparator="."
			outputFormat="number"
			type={type}
			{...props}
		>
			{props.select &&
				optionsSelect.map((values, index) => {
					if (!Array.isArray(values)) values = values.split()

					const [value, label = false] = values

					return (
						<MenuItem
							key={index}
							value={value}
							className={classes.itemList}
						>
							{label ? label : value}
						</MenuItem>
					)
				})}
		</Field>
	)
}

export default InputBasic
