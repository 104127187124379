import { Switch, TableCell, TableRow } from "@material-ui/core"
import { useMainContext } from "common/context/mainContext/mainContext"
import React, { useEffect, useState } from "react"

const AvailableProcessesTableRowHead = () => (
	<TableRow>
		<TableCell width="20%" align="center">
			Despacho
		</TableCell>
		<TableCell width="15%" align="center">
			Ciudad
		</TableCell>
		<TableCell width="10%" align="center">
			Radicado
		</TableCell>
		<TableCell width="15%" align="center">
			Demandante
		</TableCell>
		<TableCell width="15%" align="center">
			Demandado
		</TableCell>
		<TableCell width="10%" align="center">
			Seleccionar
		</TableCell>
	</TableRow>
)

const AvailableProcessesTableRowBody = ({ row }) => {
	const [selected, setSelected] = useState(false)
	const { toggleProcessesID, proccessesID } =
		useMainContext().subaccounts

	useEffect(() => {
		const existsIndex = proccessesID.findIndex((id) => id === row.id)
		if (existsIndex !== -1) setSelected(true)
		// eslint-disable-next-line
	}, [])

	const changeSelected = (id) => {
		toggleProcessesID(id)
		setSelected(!selected)
	}

	return (
		<TableRow hover={true}>
			<TableCell>{row.despacho_de_origen_del_proceso}</TableCell>
			<TableCell align="center">{row.ciudad}</TableCell>
			<TableCell align="center">{row.radicado_del_proceso}</TableCell>
			<TableCell>{row.dte}</TableCell>
			<TableCell>{row.ddo}</TableCell>
			<TableCell align="center">
				<Switch
					color="primary"
					name="activo"
					checked={selected}
					onChange={() => changeSelected(row.id)}
				/>
			</TableCell>
		</TableRow>
	)
}

export {
	AvailableProcessesTableRowBody,
	AvailableProcessesTableRowHead,
}
