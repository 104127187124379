import { useMainContext } from "common/context/mainContext/mainContext"
import { useState, useEffect } from "react"

const useCities = () => {
	const [cities, setCities] = useState([])
	const { getCities } = useMainContext().offices

	useEffect(() => {
		getCities().then((_cities) => {
			setCities(_cities)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return { cities }
}

export default useCities
