import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
	container: {
		position: "fixed",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		zIndex: 10000,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#fff",
		opacity: 0.85,
	},
	img: {
		width: 280,
		maxWidth: "100%",
	},
}))

const Loading = () => {
	const classes = useStyles()
	return (
		<Box className={classes.container}>
			<img src="/loading.gif" className={classes.img} alt="" />
		</Box>
	)
}

export default Loading
