import { Typography } from "@material-ui/core"
import { DataGrid } from "common/components/DataGrid"
import { useMainContext } from "common/context/mainContext/mainContext"
import { UcFirst } from "containers/signUp/utils/UcFirst"
import React, { useEffect, useMemo } from "react"

const AllNotificationsTable = () => {
	const {
		allNotifications,
		qtyAllNotifications,
		getAllNotificationsByBolivar,
	} = useMainContext().searchNotifications

	const [pagination, setPagination] = React.useState({
		pageIndex: 0,
		pageSize: 10,
	})

	useEffect(() => {
		getAllNotificationsByBolivar({
			offset: pagination.pageIndex * pagination.pageSize,
			limit: pagination.pageSize,
		})
		// eslint-disable-next-line
	}, [pagination])

	const columns = useMemo(
		() => [
			{
				accessorKey: "office",
				header: "Despacho",
				Cell: ({ renderedCellValue: office }) => UcFirst(office),
			},
			{
				accessorKey: "cup",
				header: "CUP",
			},
			{
				accessorKey: "clase",
				header: "Clase",
				Cell: ({ renderedCellValue: clase }) => UcFirst(clase),
			},
			{
				accessorKey: "dte",
				header: "Demandante",
				Cell: ({ renderedCellValue: dte }) => UcFirst(dte),
			},
			{
				accessorKey: "ddo",
				header: "Demandado",
				Cell: ({ renderedCellValue: ddo }) => UcFirst(ddo),
			},
			{
				width: 100,
				accessorKey: "fecha_auto",
				header: "Fecha Auto",
			},
			{
				accessorKey: "auto_anotación",
				header: "Auto Anotación",
				Cell: ({ renderedCellValue: auto_anotación }) =>
					UcFirst(auto_anotación),
			},
		],
		[]
	)

	return (
		<>
			<Typography component="b">
				Notificaciones Generales de Bolívar
			</Typography>
			<DataGrid
				columns={columns}
				data={allNotifications}
				enablePagination={true}
				pagination={pagination}
				onPaginationChange={setPagination}
				rowCount={qtyAllNotifications}
			/>
		</>
	)
}

export default AllNotificationsTable
