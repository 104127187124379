import { Box, Paper } from "@material-ui/core"
import Title from "common/components/text/Title"
import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import CreateSubaccoutForm from "./components/createSubaccountForm"

const CreateSubaccout = () => {
	return (
		<DashboardLayout>
			<MainProvider>
				<Box component={Paper} maxWidth={700} p={3}>
					<Title size="h4" marginBottom={2}>
						Crear subcuenta
					</Title>
					<CreateSubaccoutForm></CreateSubaccoutForm>
				</Box>
			</MainProvider>
		</DashboardLayout>
	)
}

export default CreateSubaccout
