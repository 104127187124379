import { Box, Button } from "@material-ui/core"
import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import { ProcessTable } from "containers/processes/components/ProcessTable"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import ProcessFilters from "./components/ProcessFilters"
// import ProcessFilter from "containers/processes/components/processFilter"
// import MediaQuery from "react-responsive"

const Processes = () => {
	const history = useHistory()
	const redirectToNewProcess = () => {
		history.push(`/processes/addprocess`)
	}

	return (
		<DashboardLayout>
			<MainProvider>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					marginBottom={3}
				>
					<ProcessFilters />
					<Button
						color="primary"
						variant="contained"
						onClick={redirectToNewProcess}
					>
						Nuevo proceso
					</Button>
				</Box>
				<ProcessTable />
			</MainProvider>
		</DashboardLayout>
	)
}

export default Processes
