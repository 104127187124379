import { Box } from "@material-ui/core"
import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import ProcessFilters from "containers/processes/components/ProcessFilters"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import ProcessesSubaccountTable from "./components/ProcessesSubaccountTable"

export const DetailSubaccount = () => {
	const { subaccountID } = useParams()

	return (
		<DashboardLayout>
			<MainProvider>
				<Box marginBottom={4}>
					<ProcessFilters />
				</Box>
				<ProcessesSubaccountTable
					subaccountID={subaccountID}
				></ProcessesSubaccountTable>
			</MainProvider>
		</DashboardLayout>
	)
}
