import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormLabel,
	Grid,
} from "@material-ui/core"
import { Input } from "common/components/inputs/basics"
import InputPassword from "common/components/inputs/basics/InputPassword"
import request from "common/plugins/request"
import { useFormik } from "formik"
import { useState } from "react"
import * as Yup from "yup"
import SecretCodeField from "./SecretCodeField"

const FreeTrialForm = () => {
	const freeTrialForm = useFormik({
		initialValues: {
			firstName: "",
			email: "",
			phone: "",
			password: "",
		},
		validationSchema: Yup.object().shape({
			firstName: Yup.string().required("Requerido").nullable(),
			email: Yup.string()
				.required("Requerido")
				.email("Email invalido")
				.test("existEmail", "Email existente", async (value) => {
					if (value) {
						const { availability_users } = await request.get(
							`/user/availability_users/?username=${value}`,
							{ hideLoader: true }
						)
						return availability_users === "true"
					}
				}),
			phone: Yup.string().required("Requerido").nullable(),
			password: Yup.string()
				.required("Requerido")
				.min(8, "Min. 8 caracteres")
				.nullable(),
			hasCoupon: Yup.boolean(),
			acceptConditions: Yup.boolean().required("Requerido"),
			useMyData: Yup.boolean().required("Requerido"),
			coupon: Yup.string().test(
				"invalidCoupon",
				"El cupón ingresado no es valido",
				async (coupon, ...props) => {
					try {
						if (!freeTrialForm.values.hasCoupon) return true
						const { results } = await request.get(
							`/codigopromocion/?codigo=${coupon}`,
							{ hideLoader: true }
						)
						return !!results.length
					} catch (e) {
						console.error(e)
						return false
					}
				}
			),
		}),
		onSubmit: async ({
			firstName,
			email,
			password,
			phone,
			coupon,
		}) => {
			try {
				await request.post("/userdata/userpasiololegal/", {
					usuarioData: {
						username: email,
						email,
						password,
						free: "True",
						first_name: firstName,
						cupon: coupon,
					},
					dataUserPasioloLegal: {
						celular_persona: phone,
					},
				})
				setIsFormCompleted(true)
			} catch (e) {
				console.error(e)
			}
		},
	})

	const [isFormCompleted, setIsFormCompleted] = useState(false)

	const isNotAcceptedConditions =
		freeTrialForm.errors.acceptConditions ||
		freeTrialForm.errors.useMyData
	return (
		<>
			<form
				noValidate
				disabled={isFormCompleted}
				autoComplete="off"
				onSubmit={freeTrialForm.handleSubmit}
			>
				<Grid container spacing={4}>
					<Grid item xs={12} md={6}>
						<Input
							name="firstName"
							label="Nombre"
							autoComplete="name"
							onChange={freeTrialForm.handleChange}
							value={freeTrialForm.values.firstName}
							errorText={freeTrialForm.errors.firstName}
							error={
								freeTrialForm.touched.firstName &&
								!!freeTrialForm.errors.firstName
							}
							disabled={isFormCompleted}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Input
							name="email"
							label="Email"
							autoComplete="email"
							onChange={freeTrialForm.handleChange}
							value={freeTrialForm.values.email}
							errorText={freeTrialForm.errors.email}
							error={
								freeTrialForm.touched.email &&
								!!freeTrialForm.errors.email
							}
							disabled={isFormCompleted}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<InputPassword
							name="password"
							label="Contraseña"
							onChange={freeTrialForm.handleChange}
							value={freeTrialForm.values.password}
							errorText={freeTrialForm.errors.password}
							error={
								freeTrialForm.touched.password &&
								!!freeTrialForm.errors.password
							}
							disabled={isFormCompleted}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Input
							type="number"
							name="phone"
							label="Celular"
							autoComplete="phone"
							onChange={freeTrialForm.handleChange}
							value={freeTrialForm.values.phone}
							errorText={freeTrialForm.errors.phone}
							error={
								freeTrialForm.touched.phone &&
								!!freeTrialForm.errors.phone
							}
							disabled={isFormCompleted}
						/>
					</Grid>
				</Grid>

				{!isFormCompleted && (
					<Box display="flex" flexDirection="column" my={2}>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									name="hasCoupon"
									checked={freeTrialForm.values.hasCoupon}
									onChange={freeTrialForm.handleChange}
								/>
							}
							label="Tengo un cupón y quiero ingresarlo"
						/>

						{freeTrialForm.values.hasCoupon && (
							<Box display="flex" justifyContent="center">
								<Input
									name="coupon"
									label="Cupón"
									value={freeTrialForm.values.coupon}
									onChange={freeTrialForm.handleChange}
									errorText={freeTrialForm.errors.coupon}
									error={
										freeTrialForm.touched.coupon &&
										!!freeTrialForm.errors.coupon
									}
									style={{ width: 320 }}
								/>
							</Box>
						)}

						<FormControlLabel
							control={
								<Checkbox
									required
									color="primary"
									name="acceptConditions"
									checked={freeTrialForm.values.acceptConditions}
									onChange={freeTrialForm.handleChange}
								/>
							}
							label="Acepto términos y condiciones"
						/>

						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									name="useMyData"
									checked={freeTrialForm.values.useMyData}
									onChange={freeTrialForm.handleChange}
								/>
							}
							label="Acepto política de tratamiento de datos personales"
						/>
					</Box>
				)}

				{isNotAcceptedConditions && (
					<FormLabel error>
						*Acepta ambas opciones para continuar
					</FormLabel>
				)}

				{isFormCompleted && (
					<Box my={2}>
						<SecretCodeField />
					</Box>
				)}

				<Box mt={3}>
					{!isFormCompleted && (
						<Button
							type="submit"
							variant="contained"
							color="secondary"
							style={{ padding: "12px 38px" }}
						>
							Crear
						</Button>
					)}
				</Box>
			</form>
		</>
	)
}

export default FreeTrialForm
