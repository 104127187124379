import * as Yup from "yup"

const validationSchema = Yup.object().shape({
	cup: Yup.string().required(),
	tipo_de_proceso: Yup.string().nullable(),
	especialidad: Yup.string().nullable(),
	tipodeparte: Yup.string().nullable(),
	ciudad_cursa_proceso: Yup.string().nullable(),
	despacho_cursa_proceso: Yup.string().nullable(),
	data_dte: Yup.array()
		.required("El CUP es obligatorio")
		.min(1, "Ingresa almenos 1 registro"),
	data_ddo: Yup.array()
		.default([])
		.required()
		.min(1, "Ingresa almenos 1 registro"),
	data_abogadodte: Yup.array().default([]), //.required().min(1, "Ingresa almenos 1 registro"),
	data_abogadoddo: Yup.array().default([]), //.required().min(1, "Ingresa almenos 1 registro"),
})

const processForm = ({
	cup = "",
	tipo_de_proceso = "",
	especialidad = "",
	ciudad_cursa_proceso = "",
	despacho_cursa_proceso = "",
	tipodeparte = "",
	//Demanandante
	data_dte = [],
	data_abogadodte = [],
	//Demandado
	data_ddo = [],
	data_abogadoddo = [],
}) => {
	const initialValues = {
		cup,
		tipodeparte,
		tipo_de_proceso,
		especialidad,
		ciudad_cursa_proceso,
		despacho_cursa_proceso,
		data_dte,
		data_abogadodte,
		data_ddo,
		data_abogadoddo,
	}

	return {
		validationSchema,
		initialValues,
	}
}

export default processForm
