import { Box, Button, Typography } from "@material-ui/core"
import AlertMessage from "common/components/AlertMessage"
import { Input } from "common/components/inputs/basics"
import request from "common/plugins/request"
import { useFormik } from "formik"
import { useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import * as Yup from "yup"

const SecretCodeField = () => {
	const history = useHistory()
	const [userIsCreated, setUserIsCreated] = useState(false)
	const [isValidCode, setIsValidCode] = useState(true)

	const emailForm = useFormik({
		initialValues: {
			code: "",
		},
		validationSchema: Yup.object().shape({
			code: Yup.string().required("Requerido"),
		}),
		onSubmit: async ({ code: codigo }) => {
			request
				.get(`/codigoverificacioncorreo/?codigo=${codigo}`)
				.then(() => {
					setIsValidCode(true)
					setUserIsCreated(true)
					setTimeout(() => {
						history.push("/")
					}, 2000)
				})
				.catch(() => setIsValidCode(false))
		},
	})

	return (
		<>
			<AlertMessage
				open={userIsCreated}
				type="success"
				title="Usuario creado"
				message="El usuario se ha creado exitosamente"
			/>

			<Typography
				component="p"
				style={{
					fontSize: ".8rem",
					lineHeight: "1.4rem",
					textAlign: "left",
					color: "#b3b3b3",
				}}
			>
				Ingresa el código que llegó a para poder activar tu usuario y
				disfrutar del periodo gratuito.
			</Typography>

			<Box
				mt={2}
				display="flex"
				alignItems="start"
				justifyContent="space-between"
			>
				<Input
					name="code"
					label="Codigo"
					style={{ maxWidth: 230 }}
					onChange={emailForm.handleChange}
					value={emailForm.values.code}
					errorText={
						emailForm.errors.code ||
						(!isValidCode && "El código ingresado no es valido")
					}
					error={
						(emailForm.touched.code && !!emailForm.errors.code) ||
						!isValidCode
					}
				/>
				<Button
					type="button"
					color="secondary"
					variant="contained"
					style={{ height: 40 }}
					onClick={emailForm.handleSubmit}
				>
					Verificar código
				</Button>
			</Box>
		</>
	)
}

export default SecretCodeField
