import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Typography,
} from "@material-ui/core"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import { Input } from "common/components/inputs/basics"
import InputPassword from "common/components/inputs/basics/InputPassword"
import request from "common/plugins/request"
import { useFormik } from "formik"
import { useCallback, useState } from "react"
import * as Yup from "yup"

const RecoverPass = ({ isOpen, onClose }) => {
	const [activeStep, setActiveStep] = useState(0)

	const commonRequest = async (payload) =>
		request.post("/userdata/changepassword/", payload)

	const [existsEmail, setExistsEmail] = useState(true)
	const [isValidCode, setIsValidCode] = useState(true)

	const emailForm = useFormik({
		initialValues: { email: "" },
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.required("Requerido")
				.email("Email invalido"),
		}),
		onSubmit({ email }) {
			commonRequest({ email })
				.then(() => {
					setExistsEmail(true)
					setActiveStep(1)
				})
				.catch(() => setExistsEmail(false))
		},
	})

	const codeForm = useFormik({
		initialValues: { code: "" },
		validationSchema: Yup.object().shape({
			code: Yup.string()
				.required("Requerido")
				.min(4, "Min. 4 caracteres"),
		}),
		onSubmit({ code }) {
			commonRequest({ codigo: code })
				.then(() => {
					setIsValidCode(true)
					setActiveStep(2)
				})
				.catch(() => setIsValidCode(false))
		},
	})

	const newPasswordForm = useFormik({
		initialValues: { password: "" },
		validationSchema: Yup.object().shape({
			password: Yup.string().required("Requerido"),
			validatePassword: Yup.string().oneOf(
				[Yup.ref("password"), null],
				"Las contraseñas no coinciden"
			),
		}),
		onSubmit({ password }) {
			commonRequest({
				email: emailForm.values.email,
				codigo: codeForm.values.code,
				password,
			}).then(() => {
				setActiveStep(3)
				setTimeout(() => {
					onClose()
					setActiveStep(0)
				}, 3000)
			})
		},
	})

	const onSubmitForm = useCallback(() => {
		switch (activeStep) {
			case 1:
				codeForm.submitForm()
				break
			case 2:
				newPasswordForm.submitForm()
				break
			default:
				emailForm.submitForm()
		}
	}, [activeStep, codeForm, emailForm, newPasswordForm])

	if (!isOpen) return null

	if (activeStep === 3)
		return (
			<Dialog open={isOpen} onClose={onClose} maxWidth="xs">
				<Box
					mt={3}
					mb={2}
					display="flex"
					flexDirection="column"
					alignItems="center"
				>
					<CheckCircleOutlineIcon
						style={{ fontSize: "3.5rem", color: "#37C33F" }}
					/>
					<DialogTitle
						style={{ textAlign: "center", color: "#5b5a5d" }}
					>
						Proceso exitoso.
					</DialogTitle>
					<DialogContent>
						<Typography component="p" style={{ lineHeight: "1.4" }}>
							Se cambio la contraseña de la cuenta
						</Typography>
					</DialogContent>
				</Box>
			</Dialog>
		)

	return (
		<Dialog open={isOpen} onClose={onClose} maxWidth="xs">
			<DialogTitle style={{ textAlign: "center", color: "#5b5a5d" }}>
				Recuperar cuenta
			</DialogTitle>
			<DialogContent>
				{activeStep === 4 && (
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
					>
						<CheckCircleOutlineIcon
							style={{ fontSize: "2.5rem", color: "#37C34F" }}
						/>
						<Typography component="p" style={{ lineHeight: "1.4" }}>
							Se cambio exitosamente la contraseña de la cuenta
						</Typography>
					</Box>
				)}
				{activeStep === 0 && (
					<>
						<Typography component="p" style={{ lineHeight: "1.4" }}>
							Ingresa el correo registrado para continuar con el
							proceso
						</Typography>

						<Box mt={3} mb={2}>
							<Input
								name="email"
								label="Email"
								onBlur={emailForm.handleChange}
								errorText={
									emailForm.errors.email ||
									(!existsEmail && "El email no existe")
								}
								error={
									!existsEmail ||
									(emailForm.touched.email &&
										!!emailForm.errors.email)
								}
							/>
						</Box>
					</>
				)}

				{activeStep === 1 && (
					<>
						<Typography component="p" style={{ lineHeight: "1.4" }}>
							Ingresa el código enviado a {emailForm.values.email}{" "}
							para continuar.
						</Typography>
						<Box mt={3} mb={2} display="flex" justifyContent="center">
							<Input
								name="code"
								label="Código"
								style={{ maxWidth: "60%" }}
								onChange={codeForm.handleChange}
								value={codeForm.values.code}
								errorText={
									codeForm.errors.code ||
									(!isValidCode && "El código es invalido")
								}
								error={
									!isValidCode ||
									(codeForm.touched.code && !!codeForm.errors.code)
								}
								maxLength={6}
							/>
						</Box>
					</>
				)}

				{activeStep === 2 && (
					<>
						<Typography component="p" style={{ lineHeight: "1.4" }}>
							Ingresa la nueva contraseña para finalizar.
						</Typography>

						<Box mt={3} mb={2}>
							<InputPassword
								name="password"
								label="Contraseña"
								onChange={newPasswordForm.handleChange}
								value={newPasswordForm.values.password}
								errorText={newPasswordForm.errors.password}
								error={
									newPasswordForm.touched.password &&
									!!newPasswordForm.errors.password
								}
							/>
						</Box>

						<Box mt={3} mb={2}>
							<InputPassword
								name="validatePassword"
								label="Validar Contraseña"
								onChange={newPasswordForm.handleChange}
								value={newPasswordForm.values.validatePassword}
								errorText={newPasswordForm.errors.validatePassword}
								error={
									newPasswordForm.touched.validatePassword &&
									!!newPasswordForm.errors.validatePassword
								}
							/>
						</Box>
					</>
				)}

				{activeStep !== 3 && (
					<Box display="flex" justifyContent="flex-end">
						<Button
							type="button"
							color="secondary"
							variant="contained"
							onClick={onSubmitForm}
							style={{ marginLeft: "auto" }}
						>
							{activeStep === 2 ? "Finalizar" : "Continuar"}
						</Button>
					</Box>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default RecoverPass
