import { Box } from "@material-ui/core"
import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import AllNotificationsFilters from "./components/AllNotificationsFilters"
import AllNotificationsTable from "./components/AllNotificationsTable"

const AllNotifications = () => {
	return (
		<DashboardLayout>
			<MainProvider>
				<Box marginBottom={3}>
					<AllNotificationsFilters />
				</Box>
				<AllNotificationsTable />
			</MainProvider>
		</DashboardLayout>
	)
}

export default AllNotifications
