import React from "react"
import ReactModal from "react-modal"

const customStyles = {
	overlay: {
		backgroundColor: "#00000060",
		zIndex: 1000,
	},
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		border: "none",
		maxHeight: "100vh",
		padding: "30px",
	},
}

const Modal = ({ children, isOpen, onClose }) => {
	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onClose}
			style={customStyles}
		>
			<div
				className="max-w-full rounded-md"
				style={{ width: 600, maxWidth: "100%" }}
			>
				{children}
			</div>
		</ReactModal>
	)
}

export default Modal
