import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined"
import useGuard from "common/hooks/useGuard"
import { primary } from "common/themes/colors"
import styled from "styled-components"

const FreeTrialAlertInline = () => {
	const { user } = useGuard()
	if (!user?.days_to_expire) return null
	return (
		<FreeTrialAlertContainer>
			<ReportProblemOutlinedIcon />

			<p>
				Quedan <strong>{user?.days_to_expire}</strong> días restantes
				al periodo actual de prueba. Luego que este periodo termine no
				podrás acceder.
			</p>
			<div className="free-status">FREE</div>
		</FreeTrialAlertContainer>
	)
}

const FreeTrialAlertContainer = styled("div")({
	minHeight: 50,
	borderRadius: 5,
	width: "100%",
	backgroundColor: primary,
	color: "#fff",
	display: "flex",
	alignItems: "center",
	padding: "0 30px",
	fontWeight: "500",

	"& p": {
		marginLeft: 8,
	},

	"& .free-status": {
		marginLeft: "auto",
		backgroundColor: "#fff",
		color: primary,
		borderRadius: 4,
		fontWeight: 600,
		fontSize: ".75rem",
		padding: "3px 8px",
	},
})

export default FreeTrialAlertInline
