import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import DashboardLayout from "common/layouts/DashboardLayout"
import { Link } from "react-router-dom"
import { dataLocations } from "./data/dataLocations"

export default function Dashboard() {
	return (
		<DashboardLayout>
			<Grid
				container
				spacing={2}
				direction="row"
				justify="center"
				alignItems="center"
			>
				{dataLocations.map(({ name, redirectTo, icon }, index) => (
					<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
						<Link to={redirectTo} style={{ textDecoration: "none" }}>
							<Card>
								<CardContent style={{ textAlign: "center" }}>
									{icon}
									<Typography
										gutterBottom
										variant="Paragraph"
										component="h5"
									>
										{name}
									</Typography>
								</CardContent>
							</Card>
						</Link>
					</Grid>
				))}
			</Grid>
		</DashboardLayout>
	)
}
