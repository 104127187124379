import MaterialReactTable from "material-react-table"
import { MRT_Localization_ES } from "material-react-table/locales/es"

export function DataGrid({
	// required props
	data,
	columns,
	// required props in case of implement pagination
	pagination = {},
	enablePagination = false,
	//onPaginationChange,
	//optional props
	state = {},
	enableColumnActions = false,
	enableColumnFilters = false,
	enableSorting = false,
	enableFilters = false,
	enableTopToolbar = false,
	muiTableBodyCellProps = {},
	...props
}) {
	return (
		<MaterialReactTable
			columns={columns}
			data={data}
			enableColumnActions={enableColumnActions}
			enableColumnFilters={enableColumnFilters}
			enableSorting={enableSorting}
			enableFilters={enableFilters}
			enableTopToolbar={enableTopToolbar}
			muiTableProps={{
				sx: {
					fontFamily: "Montserrat",
					borderRadius: 5,
					overflow: "hidden",
				},
			}}
			muiTableHeadCellProps={{
				align: "center",
				sx: {
					fontWeight: 700,
					fontSize: 13,
					paddingBottom: 2,
					paddingTop: 2,
				},
			}}
			muiTableBodyRowProps={{ hover: true }}
			muiTableBodyCellProps={{
				...muiTableBodyCellProps,
				sx: {
					fontWeight: 500,
					fontSize: 13.5,
					paddingBottom: 1,
					paddingTop: 1,
					...muiTableBodyCellProps?.sx,
				},
			}}
			muiTablePaginationProps={{
				rowsPerPageOptions: [10, 20, 50, 100],
			}}
			muiBottomToolbarProps={{
				variant: "dense",
			}}
			enablePagination={enablePagination}
			manualPagination={enablePagination}
			state={{
				...(enablePagination ? { pagination } : {}),
				...state,
			}}
			localization={MRT_Localization_ES}
			{...props}
			// enableStickyHeader
			// muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
		/>
	)
}
