import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Input } from "common/components/inputs/basics"
import LoginLayout from "common/layouts/LoginLayout"
import request from "common/plugins/request"
import { useState } from "react"
import { useMediaQuery } from "react-responsive"
import RecoverPass from "./RecoverPass"

export default function SignIn() {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [showRecoverPass, setShowRecoverPass] = useState(false)
	const isMobile = useMediaQuery({ query: "(max-width: 600px)" })

	const classes = useStyles()
	const getToken = async (e) => {
		e.preventDefault()
		const { id, username, token, is_superuser } = await request.post(
			"auth-login/",
			{
				username: email,
				password,
			}
		)

		localStorage.setItem("token_pasiolo", token)
		localStorage.setItem("user_pasiolo", username)
		localStorage.setItem("id_pasiolo", id)
		localStorage.setItem("is_superuser_pasiolo", is_superuser)
		setTimeout(() => {
			window.location.href = "/"
		}, 1500)
	}

	return (
		<>
			<RecoverPass
				isOpen={showRecoverPass}
				onClose={() => setShowRecoverPass(false)}
			/>

			<LoginLayout>
				<div className={classes.content}>
					<img
						className={isMobile ? classes.pictureSm : classes.picture}
						src="/logo638x306.png"
						alt=""
					/>
					<form
						className={classes.form}
						onSubmit={getToken}
						noValidate
					>
						<Box style={{ marginBottom: 18 }}>
							<Input
								size="medium"
								name="email"
								label="Email"
								autoComplete="email"
								value={email}
								onChange={({ target }) => setEmail(target.value)}
							/>
						</Box>
						<Input
							size="medium"
							name="password"
							label="Password"
							type="password"
							autoComplete="current-password"
							value={password}
							onChange={({ target }) => setPassword(target.value)}
						/>

						<Button
							fullWidth
							className={classes.btn_submit}
							variant="contained"
							type="submit"
						>
							Ingresar
						</Button>
					</form>
					<p className={classes.remember_password}>
						¿Ha olvidado su clave?{" "}
						<a
							href="/"
							onClick={(event) => {
								event.preventDefault()
								setShowRecoverPass(true)
							}}
						>
							Click aquí
						</a>
					</p>
				</div>
			</LoginLayout>
		</>
	)
}

const useStyles = makeStyles((theme) => ({
	content: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: "90vh",
	},
	picture: {
		width: 180,
		marginBottom: 20,
	},
	pictureSm: {
		width: 140,
		marginBottom: 20,
	},
	form: {
		width: 400,
		maxWidth: "95%",
	},
	btn_submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.light,
		borderRadius: 25,
		fontWeight: 600,
		height: 50,
	},
	remember_password: {
		color: theme.palette.dark,
		fontWeight: 600,
		fontSize: ".8rem",
		"& a": {
			color: theme.palette.primary.main,
		},
	},
}))
