import AssessmentIcon from "@material-ui/icons/Assessment"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import EventIcon from "@material-ui/icons/Event"
import FindInPageIcon from "@material-ui/icons/FindInPage"
import HelpIcon from "@material-ui/icons/Help"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"
import NotificationsIcon from "@material-ui/icons/Notifications"
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle"
import { primary } from "common/themes/colors"

export const dataLocations = [
	{
		name: "Procesos", 
		redirectTo: "/processes",
		icon: <FindInPageIcon style={{ fontSize: 100, color: primary }} />
	},
	{
		name: "Notificaciones",
		redirectTo: "/notificaciones",
		icon: <NotificationsIcon style={{ fontSize: 100, color: primary }} />

	},
	{
		name: "Agenda",
		redirectTo: "/agenda",
		icon: <EventIcon style={{ fontSize: 100, color: primary }} />
	},
	{
		name: "SubCuentas",
		redirectTo: "/subcuentas",
		icon: <SupervisedUserCircleIcon style={{ fontSize: 100, color: primary }} />
	},
	{
		name: "Gestion de Caja",
		redirectTo: "/gestiondecaja",
		icon: <MonetizationOnIcon style={{ fontSize: 100, color: primary }} />
	},
	{
		name: "Clientes",
		redirectTo: "/clientes",
		icon: <AssignmentIndIcon style={{ fontSize: 100, color: primary }} />
	},
	{
		name: "Informes",
		redirectTo: "/informes",
		icon: <AssessmentIcon style={{ fontSize: 100, color: primary }} />
	},
	{
		name: "Ayuda",
		redirectTo: "/ayuda",
		icon: <HelpIcon style={{ fontSize: 100, color: primary }} />
	},
]