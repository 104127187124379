import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

const useStyles = makeStyles({
	root: {
		fontWeight: "600",
		fontSize: 12,
		display: "inline-flex",
		alignItems: "center",
		minWidth: "auto",
		boxSizing: "content-box",
		"& .MuiButton-label": {
			width: "auto",
		},
	},
	normal: {
		padding: "8px 15px",
	},
	rounded: {
		borderRadius: "50%",
		minWidth: 30,
		width: 30,
		height: 30,
		padding: 5,
		fontSize: 14,
	},
})

const ButtonBasic = ({
	children,
	color = "light",
	rounded = false,
	...props
}) => {
	const classes = useStyles()
	return (
		<Button
			className={[
				classes.root,
				rounded ? classes.rounded : classes.normal,
			]}
			variant="contained"
			color={color}
			{...props}
		>
			{children}
		</Button>
	)
}

export default ButtonBasic
