import { Grid } from "@material-ui/core"
import { Input } from "common/components/inputs/basics"
import FuncionalityDataTable from "common/components/table/funcionalityDataTable"
import Title from "common/components/text/Title"
import React from "react"
import * as Yup from "yup"

const Pending = ({ data, changeData, values: dataValues }) => {
	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required("Requerido"),
		actividad: Yup.string().required("Requerido"),
	})
	return (
		<>
			<Title size="h4" marginBottom={0.5}>
				Pendientes
			</Title>

			<FuncionalityDataTable
				headers={["Actividad", "Nombre", "Fecha Límite "]}
				initialValues={{
					nombre: "",
					actividad: "",
					fecha_limite: "",
				}}
				validationSchema={validationSchema}
				onChangeData={(newData) => changeData(newData)}
				data={data}
			>
				{({ handleValue, values, errors, touched }) => (
					<Grid container spacing={3}>
						<Grid item xs={6} md={4}>
							<Input
								name="actividad"
								label="Actividad"
								onChange={handleValue}
								value={values.actividad}
								errorText={errors.actividad}
								error={
									touched.actividad &&
									!!errors.actividad
								}
							/>
						</Grid>

						<Grid item xs={6} md={5}>
							<Input
								select
								name="nombre"
								label="Responsable"
								onChange={handleValue}
								value={values.nombre}
								optionsSelect={dataValues.responsables}
								disabled={!dataValues.responsables.length}
								errorText={errors.nombre}
								error={
									touched.nombre &&
									!!errors.nombre
								}
							/>
						</Grid>

						<Grid item xs={6} md={3}>
							<Input
								type="date"
								name="fecha_limite"
								label="Fecha Límite"
								onChange={handleValue}
								value={values.fecha_limite}
							/>
						</Grid>
					</Grid>
				)}
			</FuncionalityDataTable>
		</>
	)
}

export default Pending
