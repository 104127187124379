import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
} from "@material-ui/core"
import { useMainContext } from "common/context/mainContext/mainContext"
import React, { useEffect, useState } from "react"
import MediaQuery from "react-responsive"
import {
	AssignedProcessesTableRowBody,
	AssignedProcessesTableRowHead,
} from "./AssignedProcessesTableRow"
import {
	AssignedProcessesTableRowBodySm,
	AssignedProcessesTableRowHeadSm,
} from "./AssignedProcessesTableRowSm"

const AssignedProcessesByAdminTable = ({ onRowClick = () => {} }) => {
	const { getProcesses, processes } = useMainContext().process
	const { getSubaccounts } = useMainContext().subaccounts
	const [assignedProcesses, setAssignedProcesses] = useState([])

	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	useEffect(() => {
		getProcesses()
		getSubaccounts()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		setAssignedProcesses(
			processes.filter(({ subaccount }) => !!subaccount?.length)
		)
	}, [processes])

	return (
		<>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<MediaQuery minDeviceWidth={960}>
							{(matches) =>
								matches ? (
									<AssignedProcessesTableRowHead />
								) : (
									<AssignedProcessesTableRowHeadSm />
								)
							}
						</MediaQuery>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? assignedProcesses.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
							  )
							: assignedProcesses
						).map((row, index) => (
							<MediaQuery minDeviceWidth={960} key={index}>
								{(matches) =>
									matches ? (
										<AssignedProcessesTableRowBody
											onRowClick={onRowClick}
											row={row}
										/>
									) : (
										<AssignedProcessesTableRowBodySm
											onRowClick={onRowClick}
											row={row}
										/>
									)
								}
							</MediaQuery>
						))}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[10, 20, 50, 100]}
					component="div"
					count={assignedProcesses.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Filas por pagina"
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} de ${count}`
					}
				/>
			</TableContainer>
		</>
	)
}

export default AssignedProcessesByAdminTable
