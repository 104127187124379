import { Box, Button, Paper } from "@material-ui/core"
import { DataGrid } from "common/components/DataGrid"
import useGeneralNotifications from "common/hooks/useGeneralNotifications"
import useGuard from "common/hooks/useGuard"
import { UcFirst } from "containers/signUp/utils/UcFirst"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

const GeneralNotificationsTable = ({ type }) => {
	const location = useLocation()
	const [notifications, setNotifications] = useState([])
	const [qtyNotifications, setQtyNotifications] = useState(0)

	const { isSuperUser } = useGuard()
	const generalNotifications = useGeneralNotifications()

	const queryParams = useMemo(
		() => new URLSearchParams(location.search),
		[location.search]
	)

	const columns = useMemo(() => {
		const defaultColumns = [
			{
				header: "Fecha",
				accessorKey: "created_at",
				muiTableBodyCellProps: {
					align: "center",
				},
				Cell: ({ renderedCellValue: created_at }) =>
					moment(created_at).format("YYYY-MM-DD"),
			},

			{
				header: "Departamento",
				accessorKey: "departamento",
				Cell: ({ renderedCellValue: departamento }) =>
					UcFirst(departamento),
			},
			{
				accessorKey: "ciudad",
				header: "Ciudad",
				Cell: ({ renderedCellValue: ciudad }) => UcFirst(ciudad),
			},
			{
				accessorKey: "corporacion",
				header: "Tipo de Despacho",
				Cell: ({ renderedCellValue: corporacion }) =>
					UcFirst(corporacion),
			},
			{
				accessorKey: "despacho",
				header: "Despacho",
				Cell: ({ renderedCellValue: despacho }) => UcFirst(despacho),
			},
			{
				size: 150,
				accessorKey: "documents",
				header: "Documentos",
				Cell: ({ row: { original } }) => {
					return (
						<Box display="flex">
							<Button
								href={original.rutatyba}
								color="primary"
								disabled={!original?.rutatyba?.length}
							>
								Tyba
							</Button>
							<Button
								href={original?.rutarama}
								color="primary"
								disabled={!original?.rutarama?.length}
							>
								Rama
							</Button>
							{/* <Button color="primary">Samai</Button>
						<Button color="primary">Otro</Button> */}
						</Box>
					)
				},
			},
		]

		return defaultColumns
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifications, isSuperUser])

	useEffect(() => {
		const generalNotificationsParams = {
			fecha: queryParams.get("date") || moment().format("YYYY-MM-DD"),
			tipo_depacho: queryParams.get("typeOffice"),
			ciudad: queryParams.get("city"),
			despacho: queryParams.get("office"),
			tipo: type,
		}

		generalNotifications
			.getGeneralNotification(generalNotificationsParams)
			.then(({ totalNotifications = 0, notifications }) => {
				setNotifications(notifications)
				setQtyNotifications(totalNotifications)
			})
		// eslint-disable-next-line
	}, [queryParams])

	return (
		<Box
			style={{ width: "100%", maxHeight: 500, overflow: "auto" }}
			component={Paper}
		>
			<DataGrid
				columns={columns}
				data={notifications}
				rowCount={qtyNotifications}
			/>
		</Box>
	)
}

export default GeneralNotificationsTable
