import { Box } from "@material-ui/core/"
import { Button } from "common/components/inputs/basics"
import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import request from "common/plugins/request"
import ProcessForm from "components/forms/processForm/processForm"
import { useHistory } from "react-router"

const AddProcesses = () => {
	const history = useHistory()

	const validateSubmit = (formData) => {
		formData.submitForm()
		if (
			formData.isValid &&
			JSON.stringify(formData.values) !==
				JSON.stringify(formData.initialValues)
		) {
			onSubmit(formData.values)
		}
	}

	const onSubmit = async ({
		data_dte,
		data_ddo,
		data_abogadodte,
		data_abogadoddo,
		...data
	}) => {
		const id_cliente = localStorage.getItem("id_pasiolo")
		data.dte = data_dte.length ? data_dte[0].nombre : ""
		data.ddo = data_ddo.length ? data_ddo[0].nombre : ""
		data.abogadodte = data_abogadodte.length
			? data_abogadodte[0].nombre
			: ""
		data.abogadoddo = data_abogadoddo.length
			? data_abogadoddo[0].nombre
			: ""
		data.id_cliente = id_cliente
		data.detalle_del_proceso = {
			...process.detalle_del_proceso,
			...{
				data_dte,
				data_ddo,
				data_abogadodte,
				data_abogadoddo,
			},
		}

		const result = await request.post(
			"datosprivadosdelproceso/",
			data
		)

		history.push(`/processes/${result.id}/summary`)
	}

	const backLocation = () => {
		history.push("/processes")
	}

	return (
		<DashboardLayout>
			<MainProvider>
				<Box maxWidth={800} /*p={5}*/>
					<ProcessForm>
						{(formData) => (
							<>
								<Button
									type="button"
									onClick={backLocation}
									style={{ marginRight: 15 }}
								>
									CANCELAR
								</Button>
								<Button
									type="button"
									color="primary"
									onClick={() => validateSubmit(formData)}
								>
									GUARDAR
								</Button>
							</>
						)}
					</ProcessForm>
				</Box>
			</MainProvider>
		</DashboardLayout>
	)
}

export default AddProcesses
