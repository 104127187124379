import request from "common/plugins/request"
const { useMemo, useEffect, useState } = require("react")

const useGuard = () => {
	const [user, setUser] = useState()

	useEffect(() => {
		if (!user) {
			request
				.get("/userdata/info_user/")
				.then((_user) => setUser(_user))
		}
	}, [user])

	return useMemo(
		() => ({
			isSuperUser:
				localStorage.getItem("is_superuser_pasiolo") === "true",
			isAuth: localStorage.getItem("token_pasiolo") !== null,
			userID: localStorage.getItem("id_pasiolo"),
			user,
		}),
		[user]
	)
}

export default useGuard
