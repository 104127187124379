import request from "common/plugins/request"
import { useFormik } from "formik"
import * as Yup from "yup"

export const useFormPerson = ({ user, onSubmit }) => {
	const hasUserName = !!user?.username
	const signUpSchema = Yup.object().shape({
		cedula: Yup.string().required("Requerido").nullable(),
		departamento: Yup.string().required("Requerido").nullable(),
		municipio: Yup.string().required("Requerido").nullable(),
		direccion: Yup.string().required("Requerido").nullable(),
		barrio: Yup.string().required("Requerido").nullable(),
		celular: Yup.string().required("Requerido").nullable(),
		telefonofijo: Yup.string().nullable(),
		username: Yup.string()
			.required("Requerido")
			.test("existsUsername", "Usuario existente", async (value) => {
				if (value) {
					const { availability_users } = await request.get(
						`/user/availability_users/?username=${value}`,
						{ hideLoader: true }
					)
					return availability_users === "true" || hasUserName
				}
			}),

		nombre: Yup.string().required("Requerido").nullable(),
		apellido: Yup.string().required("Requerido").nullable(),
		email: Yup.string()
			.required("Requerido")
			.email("Email invalido")
			.nullable(),
		password: Yup.string().required("Requerido").nullable(),
		validatePassword: Yup.string().oneOf(
			[Yup.ref("password"), null],
			"Las contraseñas no coinciden"
		),
	})

	const formSingUp = useFormik({
		initialValues: {
			departamento: user?.departamento,
			municipio: user?.municipio,
			direccion: user?.direccion,
			barrio: user?.barrio,
			telefonofijo: user?.telefonofijo,
			celular: user?.celular,

			username: user?.username,
			nombre: user?.nombre,
			apellido: user?.apellido,
			email: user?.email,
			cedula: user?.cedula,
			password: user?.password,
		},
		validationSchema: signUpSchema,
		onSubmit,
	})

	return formSingUp
}
