import { Box, Grid } from "@material-ui/core"
import { Input } from "common/components/inputs/basics"
import Title from "common/components/text/Title"
import React from "react"
import AssignSubcount from "../../../subaccounts/subaccountsProcesses/components/AssignSubcount"

const FormMoneyManagement = () => (
	<form noValidate autoComplete="off">
		<Title size="h4" marginBottom={0.5}>
			Datos basicos de caja
		</Title>
		<Box marginBottom={4}>
			<Grid container spacing={2}>
				<Grid item xs={6} lg={3}>
					<Input label="Nombre" />
				</Grid>

				<Grid item xs={6} lg={3}>
					<Input label="Administrador de Caja" />
				</Grid>
			</Grid>
		</Box>

		<Title size="h4" marginBottom={0.5}>
			Asignar Usuarios
		</Title>
		<Box marginBottom={4}>
			<AssignSubcount />
		</Box>

		<Title size="h4" marginBottom={0.5}>
			Ingreso / Egreso Caja
		</Title>
		<Box marginBottom={4}>
			<Grid container spacing={2}>
				<Grid item xs={6} lg={2}>
					<Input label="Tipo Movimiento" />
				</Grid>

				<Grid item xs={6} lg={3}>
					<Input label="Valor" />
				</Grid>

				<Grid item xs={6} lg={2}>
					<Input label="Tipo tercero" />
				</Grid>
			</Grid>
		</Box>
		<Box marginBottom={4}>
			<Grid container spacing={2} marginTop={5}>
				<Grid item xs={7}>
					<Input rows={3} multiline label="Descripción" />
				</Grid>
			</Grid>
		</Box>
	</form>
)

export default FormMoneyManagement
