import { Grid } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import SaveIcon from "@material-ui/icons/Save"
import { Button } from "common/components/inputs/basics"
import { DataTable } from "common/components/table"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"

const FuncionalityDataTable = ({
	data,
	headers,
	onChangeData,
	initialValues,
	validationSchema,
	typesValues = {},
	children,
}) => {
	const {
		values,
		setValues,
		submitForm,
		handleChange,
		setFieldValue,
		...allProps
	} = useFormik({
		validationSchema,
		initialValues,
		onSubmit() {
			if (updateIndex !== -1) updatePretension()
			else addPretension()
		},
	})
	const [updateIndex, setUpdateIndex] = useState(-1)
	const [finalData, setFinalData] = useState([])

	useEffect(() => {
		if (data?.length && updateIndex === -1) {
			const _finalData = data.map((item) => {
				item = sortData(item)
				Object.keys(typesValues).forEach((key) => {
					/*if(isNaN(item[key])){
						item[key] = null
					}*/
					if (item[key]) {
						switch (typesValues[key]) {
							case "currency":
								item[key] = new Intl.NumberFormat("es-CO").format(
									Number(item[key])
								)
								break

							case "money":
								item[key] = new Intl.NumberFormat("es-CO").format(
									parseFloat(item[key])
								)
								break

							default:
								break
						}
					}
				})
				return Object.values(item)
			})
			setFinalData(_finalData)
		}
		// eslint-disable-next-line
	}, [data, updateIndex])

	const sortData = (rowData) => {
		const sortData = {}
		Object.keys(initialValues).forEach((key) => {
			sortData[key] = rowData[key]
		})
		return sortData
	}

	useEffect(() => {
		if (updateIndex !== -1) {
			setValues(data[updateIndex])
		} else {
			setValues(initialValues)
		}
		// eslint-disable-next-line
	}, [updateIndex])

	const addPretension = () => {
		if (values) {
			onChangeData([...data, values])
			allProps.resetForm()
		}
	}

	const deletePretension = (index) => {
		onChangeData(data.filter((_, _index) => index !== _index))
		allProps.resetForm()
	}

	const updatePretension = () => {
		data[updateIndex] = values
		onChangeData(data)
		setUpdateIndex(-1)
		allProps.resetForm()
	}

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} style={{ display: "flex" }}>
					{children({
						values,
						setFieldValue,
						handleValue: handleChange,
						...allProps,
					})}

					<Button
						color="primary"
						style={{ marginLeft: 10 }}
						onClick={() => submitForm()}
						rounded
					>
						{updateIndex !== -1 ? <SaveIcon /> : <AddIcon />}
					</Button>
				</Grid>
				{!!data?.length && (
					<Grid item xs={12}>
						<DataTable
							headers={headers}
							data={finalData}
							onUpdated={(index) => setUpdateIndex(index)}
							onDeleted={(index) => deletePretension(index)}
							canUpdated={true}
							canDeleted={true}
						/>
					</Grid>
				)}
			</Grid>
		</>
	)
}

export default FuncionalityDataTable
