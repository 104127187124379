import { Box, Grid } from "@material-ui/core"
import {
	Autocomplete,
	Button,
	Input,
} from "common/components/inputs/basics"
import { useMainContext } from "common/context/mainContext/mainContext"
import useCities from "common/hooks/useCities"
import { UcFirst } from "containers/signUp/utils/UcFirst"
import NotificationsFiltersAdvanced from "./NotificationsFiltersAdvanced"

const NotificationsFilters = () => {
	const mainContext = useMainContext()
	const { changeFilter, getNotifications } = mainContext.notifications
	const { cities } = useCities()

	return (
		<Box display="flex" alignItems="center">
			<NotificationsFiltersAdvanced />

			<Grid
				container
				spacing={1}
				style={{ marginLeft: 5, width: 800 }}
			>
				<Grid item xs={3}>
					<Input
						name="cup"
						label="CUP / Radicado"
						onChange={({ target: { value: radicado } }) =>
							changeFilter({ radicado })
						}
						autoComplete="off"
					/>
				</Grid>
				<Grid item xs={3}>
					<Autocomplete
						label="Ciudad"
						options={cities.map(({ name }) => name)}
						onChange={(_, city) => changeFilter({ city })}
						getOptionLabel={(city) => UcFirst(city)}
						disabled={!cities.length}
					/>
				</Grid>
				<Grid item xs={3}>
					<Input
						name="claimant"
						label="Demandante"
						onChange={({ target: { value: claimant } }) =>
							changeFilter({ claimant })
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<Input
						name="defendant"
						label="Demandado"
						onChange={({ target: { value: defendant } }) =>
							changeFilter({ defendant })
						}
					/>
				</Grid>
			</Grid>

			<Button
				color="primary"
				variant="contained"
				style={{ marginLeft: 5 }}
				onClick={() => getNotifications()}
			>
				Filtrar
			</Button>
		</Box>
	)
}

export default NotificationsFilters
