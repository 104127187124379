import { Tooltip } from "@material-ui/core"
import { Box } from "@material-ui/core"
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd"
import { Autocomplete } from "common/components/inputs/basics"
import { Button } from "common/components/inputs/basics"
import { useMainContext } from "common/context/mainContext/mainContext"
import React, { useEffect, useState } from "react"
import List from "common/components/list"

const AssignSubcount = () => {
	const {
		subaccounts = [],
		getSubaccounts,
		toggleSubaccountsID,
		subaccountsID,
		removeSubaccountID,
	} = useMainContext().subaccounts
	const [selectedSubaccount, setSelectedSubaccount] = useState({})

	useEffect(() => {
		getSubaccounts()
		// eslint-disable-next-line
	}, [])

	const addSubacountToList = () => {
		if (selectedSubaccount?.id) {
			toggleSubaccountsID(selectedSubaccount.id)
			setSelectedSubaccount({})
		}
	}

	const removeSubaccountFromList = (index) => {
		removeSubaccountID(subaccounts[index].id)
	}

	return (
		<Box marginTop={2} width={600} maxWidth="100%">
			<Box display="flex" paddingBottom={2}>
				<Box width={180}>
					<Autocomplete
						label="Cédula"
						options={subaccounts.filter(
							({ id, cedula }) =>
								!subaccountsID.includes(id) && cedula
						)}
						getOptionLabel={({ cedula }) => cedula}
						disabled={!subaccounts.length}
						onChange={(_, subaccount) => {
							setSelectedSubaccount(subaccount)
						}}
						value={selectedSubaccount}
					/>
				</Box>
				<Box flexGrow={1} marginLeft={2} marginRight={2}>
					<Autocomplete
						label="Nombre"
						options={subaccounts.filter(
							({ id, razonsocial }) =>
								!subaccountsID.includes(id) && razonsocial
						)}
						getOptionLabel={({ razonsocial }) => razonsocial}
						disabled={!subaccounts.length}
						onChange={(_, subaccount) => {
							setSelectedSubaccount(subaccount)
						}}
						value={selectedSubaccount}
					/>
				</Box>
				<Box>
					<Tooltip title="Agregar usuario" placement="top">
						<Button color="primary" onClick={addSubacountToList}>
							<PlaylistAddIcon />
						</Button>
					</Tooltip>
				</Box>
			</Box>
			{!!subaccountsID.length && (
				<List
					onRemoveItem={removeSubaccountFromList}
					list={subaccounts.map(({ id, cedula, razonsocial }) =>
						subaccountsID.includes(id) ? (
							<>
								<strong>{cedula}</strong>
								<span>{razonsocial}</span>
							</>
						) : null
					)}
				></List>
			)}
		</Box>
	)
}

export default AssignSubcount
