import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
} from "@material-ui/core"
import { useMainContext } from "common/context/mainContext/mainContext"
import React, { useEffect, useState } from "react"
import MediaQuery from "react-responsive"
import {
	AvailableProcessesTableRowBody,
	AvailableProcessesTableRowHead,
} from "./AvailableProcessesTableRow"
import {
	AvailableProcessesTableRowBodySm,
	AvailableProcessesTableRowHeadSm,
} from "./AvailableProcessesTableRowSm"

const AvailableProcessesTable = () => {
	const { getProcesses, processes } = useMainContext().process
	const [unassignedProcesses, setUnassignedProcesses] = useState([])

	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	useEffect(() => {
		getProcesses()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		setUnassignedProcesses(
			processes.filter(({ subaccount }) => !subaccount?.length)
		)
	}, [processes])

	return (
		<>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<MediaQuery minDeviceWidth={960}>
							{(matches) =>
								matches ? (
									<AvailableProcessesTableRowHead />
								) : (
									<AvailableProcessesTableRowHeadSm />
								)
							}
						</MediaQuery>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? unassignedProcesses.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
							  )
							: unassignedProcesses
						).map((row, index) => (
							<MediaQuery minDeviceWidth={960} key={row.id}>
								{(matches) =>
									matches ? (
										<AvailableProcessesTableRowBody row={row} />
									) : (
										<AvailableProcessesTableRowBodySm row={row} />
									)
								}
							</MediaQuery>
						))}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[10, 20, 50, 100]}
					component="div"
					count={unassignedProcesses.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Filas por pagina"
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} de ${count}`
					}
				/>
			</TableContainer>
		</>
	)
}

export default AvailableProcessesTable
