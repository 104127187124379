import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	tabsContent: {
		display: "flex",
		width: "100%",
	},
	tab: {
		flexGrow: 1,
		fontSize: 12,
		fontWeight: 700,
		textAlign: "center",
		maxWidth: "100%",
		borderBottomWidth: 2,
		borderBottomStyle: "solid",
		borderBottomColor: theme.palette.lightGray,
		color: theme.palette.lightGray,
	},
}))

const TabsBasic = ({ value = 0, tabs = [], handleChange }) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Tabs
				value={value}
				onChange={handleChange}
				className={classes.tabsContent}
				indicatorColor="primary"
				textColor="primary"
			>
				{tabs.map((tab, index) => (
					<Tab
						label={tab}
						value={index}
						key={index}
						className={classes.tab}
					/>
				))}
			</Tabs>
		</div>
	)
}

TabsBasic.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
	tabs: PropTypes.array,
}

export default TabsBasic
