import { Box } from "@material-ui/core"
import TabsBasic from "common/components/tabs"
import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import { useState } from "react"
import GeneralNotificationsFilters from "./GeneralNotificationsFilters"
import GeneralNotificationsTable from "./GeneralNotificationsTable"
const generalNotificationsTabs = [
	{
		label: "Estados",
		value: "ESTADO",
	},
	{
		label: "Fijaciones y Translados",
		value: "FIJACION,TRASLADO",
	},
	{
		label: "Edictos",
		value: "EDICTO",
	},
]

const GeneralNotifications = () => {
	const [activeTab, setActiveTab] = useState(0)

	return (
		<DashboardLayout>
			<MainProvider>
				<Box marginBottom={3}>
					<TabsBasic
						value={activeTab}
						handleChange={(_, index) => setActiveTab(index)}
						tabs={generalNotificationsTabs.map(({ label }) => label)}
					/>
				</Box>

				<Box marginBottom={3}>
					<GeneralNotificationsFilters
						key={generalNotificationsTabs[activeTab]?.value}
					/>
				</Box>
				<GeneralNotificationsTable
					key={generalNotificationsTabs[activeTab]?.value}
					type={generalNotificationsTabs[activeTab]?.value}
				/>
			</MainProvider>
		</DashboardLayout>
	)
}

export default GeneralNotifications
