import { Autocomplete } from "@material-ui/lab"
import React from "react"
import { Input } from "."

const AutocompleteBasic = ({
	size = "small",
	type = "text",
	errorText,
	error,
	label,
	...props
}) => (
	<Autocomplete
		size="small"
		renderInput={(params) => (
			<Input
				type={type}
				label={label}
				errorText={errorText}
				error={error}
				InputLabelProps={{
					shrink: true,
				}}
				autoComplete="off"
				{...params}
			/>
		)}
		{...props}
	/>
)

export default AutocompleteBasic
