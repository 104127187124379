import { Box, Card, styled } from "@material-ui/core"
import Title from "common/components/text/Title"
import { MainProvider } from "common/context/mainContext/mainContext"
import FreeTrialForm from "./FreeTrialForm"

const FreeTrialPeriod = () => {
	return (
		<MainProvider>
			<BgContainer>
				<Box pt={8} maxWidth={450} mx="auto">
					<Card>
						<Box textAlign="center" p={4}>
							<img
								src="/logo638x306.png"
								style={{ width: 150 }}
								alt=""
							/>
							<Title
								size="h1"
								style={{ fontSize: "1rem", color: "#5b5a5d" }}
							>
								Obten una prueba gratuita
							</Title>
							<Box mb={3} />
							<FreeTrialForm />
						</Box>
					</Card>
				</Box>
			</BgContainer>
		</MainProvider>
	)
}

const BgContainer = styled("div")(({ theme }) => ({
	background: theme.palette.gray,
	width: "100%",
	minHeight: "100vh",
	overflowX: "hidden",
}))

export default FreeTrialPeriod
