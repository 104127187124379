import { Breadcrumbs, Link } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import React from "react"
import { useLocation } from "react-router-dom"
import { Routes } from "router/routes"

const useStyles = makeStyles((theme) => ({
	root: {
		fontSize: 12.5,
		"& li": {
			color: theme.palette.darkGray,
			fontWeight: "500",
		},
		"& .active": {
			color: theme.palette.primary.main,
			fontWeight: "600",
		},
	},
}))

const Breakcrum = () => {
	const location = useLocation(),
		classes = useStyles(),
		breadcrums = Object.values(Routes).filter((route) =>
			location.pathname.includes(route.path)
		)

	console.log("location: ", location)

	if (breadcrums.length <= 1) return null
	return (
		<Breadcrumbs
			className={classes.root}
			separator={<NavigateNextIcon fontSize="small" />}
		>
			{breadcrums.map(({ label, path }, index) => (
				<Link
					key={index}
					href={path}
					color="inherit"
					className={{ active: path === location.pathname }}
				>
					{label}
				</Link>
			))}
		</Breadcrumbs>
	)
}

export default Breakcrum
