import { Input } from "common/components/inputs/basics"
import FuncionalityDataTable from "common/components/table/funcionalityDataTable"
import Title from "common/components/text/Title"
import React from "react"

const Objectives = ({ data, changeData }) => {
	return (
		<>
			<Title size="h4" marginBottom={0.5}>
				Pretensiones
			</Title>
			<FuncionalityDataTable
				headers={["Pretensiones"]}
				initialValues={{ pretension: "" }}
				onChangeData={(newData) => changeData(newData)}
				data={data}
			>
				{({ handleValue, values }) => (
					<Input
						name="pretension"
						label="Pretensiones"
						onChange={handleValue}
						value={values.pretension}
					/>
				)}
			</FuncionalityDataTable>
		</>
	)
}

export default Objectives
