import { Box, Paper } from "@material-ui/core"
import { DataGrid } from "common/components/DataGrid"
import { useMainContext } from "common/context/mainContext/mainContext"
import useGuard from "common/hooks/useGuard"
import { ProcessTruncateCUP } from "containers/processes/components/ProcessTruncateCUP"
import { UcFirst } from "containers/signUp/utils/UcFirst"
import moment from "moment"
import React, { useEffect, useMemo } from "react"
import { NotificationsActionsCell } from "./NotificationsActionsCell"

export const NotificationsTable = () => {
	const { notifications: notificationsCtx } = useMainContext()
	const {
		getNotifications,
		notifications = [],
		qtyNotifications,
	} = notificationsCtx

	const { isSuperUser } = useGuard()
	const [pagination, setPagination] = React.useState({
		pageIndex: 0,
		pageSize: 10,
	})

	const columns = useMemo(() => {
		const defaultColumns = [
			{
				header: "Fecha",
				accessorKey: "fecha_creacion",
				muiTableBodyCellProps: {
					align: "center",
				},
				Cell: ({ renderedCellValue: fecha_creacion }) =>
					moment(fecha_creacion).format("YYYY-MM-DD"),
			},

			{
				size: 70,
				header: "Radicado",
				accessorKey: "radicado",
				muiTableBodyCellProps: {
					align: "center",
				},
				Cell: ({
					renderedCellValue: radicado,
					row: { original },
				}) => {
					return (
						<ProcessTruncateCUP
							cup={original?.cup}
							processCode={radicado}
						></ProcessTruncateCUP>
					)
				},
			},
			{
				header: "Despacho",
				accessorKey: "office",
				Cell: ({ renderedCellValue: office }) => UcFirst(office),
			},
			{
				accessorKey: "dte",
				header: "Demandante",
				Cell: ({ renderedCellValue: dte }) => UcFirst(dte),
			},
			{
				accessorKey: "ddo",
				header: "Demandado",
				Cell: ({ renderedCellValue: ddo }) => UcFirst(ddo),
			},
			{
				accessorKey: "auto_anotacion",
				header: "Anotación",
				Cell: ({ renderedCellValue: auto_anotacion }) =>
					UcFirst(auto_anotacion),
			},
			{
				accessorKey: "id",
				header: "Acciones",
				Cell: ({ row: { original } }) => (
					<NotificationsActionsCell {...original} />
				),
			},
		]

		if (isSuperUser) {
			defaultColumns.splice(1, 0, {
				accessorKey: "razon_social",
				header: "Cliente",
				Cell: ({ renderedCellValue: razon_social }) =>
					UcFirst(razon_social),
			})
		}

		return defaultColumns
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifications, isSuperUser])

	useEffect(() => {
		getNotifications({
			offset: pagination.pageIndex * pagination.pageSize,
			limit: pagination.pageSize,
		})
		// eslint-disable-next-line
	}, [pagination])

	return (
		<Box style={{ width: "100%" }} component={Paper}>
			<DataGrid
				columns={columns}
				data={notifications}
				rowCount={qtyNotifications}
				enablePagination={true}
				pagination={pagination}
				onPaginationChange={setPagination}
			/>
		</Box>
	)
}
