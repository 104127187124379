import { Box } from "@material-ui/core"
import BusinessIcon from "@material-ui/icons/Business"
import PersonIcon from "@material-ui/icons/Person"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import { useMainContext } from "common/context/mainContext/mainContext"
import { useState } from "react"
// import { mapDataUser } from "../utils/mapDataUser"
import FormCompany from "./formCompany"
import FormPerson from "./formPerson"

const USER_TYPES = {
	PERSON: "PERSON",
	COMPANY: "COMPANY",
}

const UserTypes = () => {
	const { activePlan, createUser } = useMainContext().signUp
	const [activeUserType, setActiveUserType] = useState(
		USER_TYPES.PERSON
	)

	const onSubmitForm = async (data) => {
		// const dataUser = mapDataUser(data)
		const isPerson = activeUserType === USER_TYPES.PERSON
		const isCompany = activeUserType === USER_TYPES.COMPANY
		const usuarioData = {
			email: data.email,
			username: data.username,
			password: data.password,
			first_name: isPerson ? data.nombre : null,
		}
		const dataUserPasioloLegal = {
			user_pasiolo_id: null,
			apellido: isPerson ? data.apellido : null,
			cedula: isCompany ? data.nit : data.cedula,
			razonsocial: isCompany
				? data.razonsocial
				: `${data.nombre} ${data.apellido}`,
			departamento: data.departamento,
			municipio: data.municipio,
			direccion: data.direccion,
			barrio: data.barrio,
			pago: null,
			telefonofijo_persona: isPerson ? data?.telefonofijo : null,
			telefonofijo_empresa: isCompany ? data?.telefonofijo : null,
			celular_persona: isPerson ? data.celular : null,
			celular_empresa: isCompany ? data.celular : null,
			plan: activePlan?.id,
			sub_cuenta: false,
			padre_id: null,
		}
		// const dataUser = mapDataUser(data)
		try {
			await createUser({ usuarioData, dataUserPasioloLegal })
			window.location.href = activePlan.url_wompi
		} catch (error) {
			console.error(error)
		}
	}

	const changeActiveUser = (event, newAlignment) => {
		setActiveUserType(newAlignment)
	}

	return (
		<>
			<Box
				marginBottom={6}
				style={{ display: "flex", justifyContent: "center" }}
			>
				<ToggleButtonGroup
					exclusive
					value={activeUserType}
					onChange={changeActiveUser}
				>
					<ToggleButton value={USER_TYPES.PERSON}>
						<PersonIcon />
						<Box marginLeft={1}>Persona</Box>
					</ToggleButton>
					<ToggleButton value={USER_TYPES.COMPANY}>
						<BusinessIcon />
						<Box marginLeft={1}>Empresa</Box>
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
			{activeUserType === USER_TYPES.PERSON && (
				<FormPerson onSubmit={onSubmitForm} />
			)}
			{activeUserType === USER_TYPES.COMPANY && (
				<FormCompany onSubmit={onSubmitForm} />
			)}
		</>
	)
}

export default UserTypes
