import { useMainContext } from "common/context/mainContext/mainContext"
import { useState, useEffect } from "react"

const useSpecialities = () => {
	const [specilities, setSpecialities] = useState([])
	const { getSpecialities } = useMainContext().process

	useEffect(() => {
		getSpecialities().then((_specialities) => {
			setSpecialities(_specialities)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return { specilities }
}

export default useSpecialities
