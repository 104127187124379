import request from "common/plugins/request"
import { useMemo } from "react"

export const useOffices = () => {
	const getCities = async () => {
		const { results: cities } = await request.get("/city/")
		return cities
	}

	const getTypesOffices = async () => {
		const { results } = await request.get("/typeoffice/")
		return results
	}

	const getTypesOfficesGroup = async () => {
		const typesOffices = await request.get("/office/type/")
		return typesOffices
	}

	const getOffices = async (city = "", typeOffice = "") => {
		const { results: offices = [] } = await request.get("/office", {
			params: { city__icontains: city, type: typeOffice },
		})
		return offices
	}

	return useMemo(
		() => ({
			getCities,
			getTypesOffices,
			getTypesOfficesGroup,
			getOffices,
		}),
		// eslint-disable-next-line
		[]
	)
}
