import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
} from "@material-ui/core"
import { useMainContext } from "common/context/mainContext/mainContext"
import React, { useEffect } from "react"
import {
	ProcessesSubaccountTableRowBody,
	ProcessesSubaccountTableRowHead,
} from "./ProcessesSubaccountTableRow"

const ProcessesSubaccountTable = ({ subaccountID }) => {
	const { getProcesses, processes, qtyProcesses } =
		useMainContext().process

	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	useEffect(() => {
		getProcesses({
			offset: page ? page * rowsPerPage : 0,
			limit: rowsPerPage,
		})
		// eslint-disable-next-line
	}, [rowsPerPage, page])

	return (
		<>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<ProcessesSubaccountTableRowHead />
					</TableHead>
					<TableBody>
						{processes.map((row, index) => (
							<ProcessesSubaccountTableRowBody
								row={row}
								key={index}
							/>
						))}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[10, 20, 50, 100]}
					component="div"
					count={qtyProcesses}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Filas por pagina"
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} de ${count}`
					}
				/>
			</TableContainer>
		</>
	)
}

export default ProcessesSubaccountTable
