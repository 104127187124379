import { Box, Container, Grid, makeStyles } from "@material-ui/core"
import Title from "common/components/text/Title"
import { MainProvider } from "common/context/mainContext/mainContext"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import PlansTypes from "./components/plansTypes"
import UserTypes from "./components/userTypes"

const SignUp = () => {
	const classes = useStyles()
	const { planID } = useParams()
	return (
		<MainProvider>
			<div className={classes.root}>
				<Box textAlign="center" marginTop={2} marginBottom={2}>
					<Title className={classes.titleColor}>
						Regístrate en nuestra plataforma
					</Title>
				</Box>
				<Grid container className={classes.cardSignUp}>
					<Grid item xs={12} lg={8}>
						<Container maxWidth="lg" className={classes.container}>
							<UserTypes />
						</Container>
					</Grid>
					<Grid item xs={4} className={classes.rigthContent}>
						<Box className={classes.contentDescription}>
							<PlansTypes planID={planID} />
						</Box>
					</Grid>
				</Grid>
			</div>
		</MainProvider>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.gray,
		width: "100%",
		minHeight: "100vh",
		overflowX: "hidden",
	},
	titleColor: {
		color: theme.palette.primary.main,
	},
	cardSignUp: {
		marginBottom: theme.spacing(6),
		borderRadius: 5,
		overflow: "hidden",
		background: theme.palette.light,
		width: "1300px",
		margin: "auto",
		maxWidth: "100vw",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		padding: theme.spacing(6),
		[theme.breakpoints.down("md")]: {
			paddingTop: theme.spacing(4),
			padding: theme.spacing(2),
		},
	},
	rigthContent: {
		backgroundColor: theme.palette.primary.main,
		top: 0,
		right: 0,
		display: "flex",
		alignItems: "center",
		padding: "30px 40px",
	},
	contentDescription: {
		margin: "0 auto",
		width: 580,
		maxWidth: "100%",
		color: theme.palette.light,
		"& h1": {
			fontSize: "1.8rem !important",
		},
		"& p": {
			lineHeight: 1.6,
			marginTop: -15,
			fontSize: "1.1rem",
		},
	},
	separator: {
		width: "100%",
		height: 2,
		backgroundColor: theme.palette.gray,
		position: "relative",
	},
}))

export default SignUp
