import axios from "axios"
import request from "common/plugins/request"
import { useMemo, useState } from "react"

export const useProcess = () => {
	const [processes, setProcesses] = useState([])
	const [qtyProcesses, setQtyProcesses] = useState(0)

	const [process, setProcess] = useState(Object)
	const [filters, setFilters] = useState({
		cup: "",
		type: "",
		city: "",
		state: "",
		office: "",
		claimant: "",
		defendant: "",
		proxies: "",
		typeOffice: "",
		nameCompany: "",
	})
	const [processHistory, setProcessHistory] = useState([])
	const [isUpdated, setIsUpdated] = useState(false)

	const changeFilter = (filter) => {
		setFilters({
			...filters,
			...filter,
		})
	}

	const getStates = async () => {
		const { results: states } = await request.get("/state")
		return states
	}

	const getSpecialities = async () => {
		const { results: specialities } = await request.get("/specialty")
		return specialities
	}

	const getTypeProcess = async () => {
		const { results: typeProcess } = await request.get("/tipyprocess")
		return typeProcess
	}

	const getListCUP = async () => {
		const { results: listCUP } = await request.get(
			"/datosprivadosdelproceso/all_cup/"
		)
		return listCUP.filter(({ cup }) => cup !== null)
	}

	const getProcesses = async (queryParams) => {
		let params = {
			cup__icontains: filters.cup,
			tipo_despacho: filters.typeOffice,
			ciudad_cursa_proceso__icontains: filters.city,
			despacho_cursa_proceso__icontains: filters.office,
			razon_social__icontains: filters.nameCompany,
			tipo_de_proceso__icontains: filters.type,
			estado_del_proceso: filters.state,
			ddo__icontains: filters.defendant,
			dte__icontains: filters.claimant,
		}

		// TODO remove id_cliente
		// if (localStorage.getItem("is_superuser_pasiolo") !== "true") {
		// 	params.id_cliente = localStorage.getItem("id_pasiolo")
		// }

		if (queryParams) {
			params = { ...params, ...queryParams }
		}

		const { results, count } = await request.get(
			"datosprivadosdelproceso/",
			{ params }
		)
		setProcesses(results)
		setQtyProcesses(count)
	}

	const getProcess = async (id) => {
		const result = await request.get(`datosprivadosdelproceso/${id}/`)
		if (!result.detalle_del_proceso) result.detalle_del_proceso = {}
		setProcess(result)
	}

	const changeProcess = (newProcess = {}) => {
		setProcess({
			...process,
			...newProcess,
		})
	}

	const updateProcess = async (id, data) => {
		const result = await request.patch(
			`datosprivadosdelproceso/${id}/`,
			data ? data : process
		)
		setIsUpdated(Object.keys(result).length > 0)
	}

	const updateMultipleProcesses = async (_processes) => {
		const requests = []
		_processes.forEach(({ id, ..._process }) => {
			requests.push(
				request.put(`datosprivadosdelproceso/${id}/`, _process)
			)
		})

		const response = await axios.all(requests)
		console.log(response, _processes.length, response.length)
		if (_processes.length === response.length) {
			getProcesses()
		}
	}

	const createProcess = async (process) => {
		const { data: result } = await request.post(
			"datosprivadosdelproceso/",
			process
		)
		return result
	}

	const getProcessHistory = async (processID) => {
		const { results } = await request.get("gestionesrealizadas/", {
			params: { id_proceso: processID },
		})
		console.log(results)

		setProcessHistory(results)
	}

	const disableProcess = async (processID) => {
		await request.patch(`datosprivadosdelproceso/${processID}/`, {
			estado_del_proceso: "INACTIVE",
		})
	}

	const getBasicInfoByCUP = async (CUP) => {
		const result = await request.get(
			`datosprivadosdelproceso/get_info_cup/`,
			{ params: { cup: CUP } }
		)

		return result
	}

	return useMemo(
		() => ({
			filters,
			changeFilter,
			getStates,
			processes,
			qtyProcesses,
			getProcesses,
			createProcess,
			process,
			getListCUP,
			getProcess,
			changeProcess,
			updateProcess,
			disableProcess,
			getSpecialities,
			getTypeProcess,
			isUpdated,
			processHistory,
			updateMultipleProcesses,
			getProcessHistory,
			getBasicInfoByCUP,
		}),
		// eslint-disable-next-line
		[
			processes,
			process,
			qtyProcesses,
			filters,
			isUpdated,
			processHistory,
		]
	)
}
