import { useMainContext } from "common/context/mainContext/mainContext"
import { useState, useEffect } from "react"

const useStatesProcess = () => {
	const [statesProcess, setStatesProcess] = useState([])
	const { getStates } = useMainContext().process

	useEffect(() => {
		getStates().then((_states) => {
			setStatesProcess(_states)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return { statesProcess }
}

export default useStatesProcess
