import { useFormik } from "formik"
import * as Yup from "yup"

export const useFormLoggedUser = ({ user, onSubmit }) => {
	const loggedUserSchema = Yup.object().shape({
		cedula: Yup.string().required("Requerido").nullable(),
		departamento: Yup.string().required("Requerido").nullable(),
		municipio: Yup.string().required("Requerido").nullable(),
		direccion: Yup.string().required("Requerido").nullable(),
		barrio: Yup.string().required("Requerido").nullable(),
		celular: Yup.string().required("Requerido").nullable(),
		telefonofijo: Yup.string().nullable(),
		nombre: Yup.string().required("Requerido").nullable(),
		apellido: Yup.string().required("Requerido").nullable(),
		email: Yup.string()
			.required("Requerido")
			.email("Email invalido")
			.nullable(),
	})

	const formLoggedUser = useFormik({
		initialValues: {
			departamento: user?.departamento,
			municipio: user?.municipio,
			direccion: user?.direccion,
			barrio: user?.barrio,
			telefonofijo: user?.telefonofijo,
			celular: user?.celular,
			nombre: user?.nombre,
			apellido: user?.apellido,
			email: user?.email,
			cedula: user?.cedula,
		},
		validationSchema: loggedUserSchema,
		onSubmit,
	})

	return formLoggedUser
}
