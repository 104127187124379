import { Box } from "@material-ui/core"
import TabsBasic from "common/components/tabs"
import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import { useState } from "react"
import TabAssignProcesses from "./components/TabAssignProcesses"
import TabEditAssignedProcesses from "./components/TabEditAssignedProcesses"

const SubaccountsProcesses = () => {
	const [activeTab, setActiveTab] = useState(0)
	const subcountsComponents = [
		<TabAssignProcesses />,
		<TabEditAssignedProcesses />,
	]

	return (
		<DashboardLayout>
			<MainProvider>
				<Box marginBottom={3}>
					<TabsBasic
						value={activeTab}
						handleChange={(_, index) => setActiveTab(index)}
						tabs={[
							"Asignar Procesos",
							"Información Completa de Procesos",
						]}
					/>
				</Box>
				{subcountsComponents[activeTab]}
			</MainProvider>
		</DashboardLayout>
	)
}

export default SubaccountsProcesses
