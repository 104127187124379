import { useMainContext } from "common/context/mainContext/mainContext"
import { useCallback } from "react"
import useGuard from "./useGuard"

const useCreateNotification = () => {
	const { notifications: notificationService } = useMainContext()
	const { userID } = useGuard()

	const createNotifications = useCallback(
		(actionNotification, processID) =>
			notificationService.createNotification({
				id_cliente: userID,
				id_proceso: processID,
				id_notificacion: null,
				tipo_de_notificacion: "GESTION",
				...actionNotification,
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	return { createNotifications }
}

export default useCreateNotification
