import request from "common/plugins/request"
import { useEffect, useMemo, useState } from "react"

export const useAddress = () => {
	const [subaccountInfo, setSubaccountInfo] = useState({})
	const [provinces, setProvinces] = useState([])
	// const [province, setProvince] = useState({})
	const [cities, setCities] = useState([])
	// const [city, setCity] = useState({})
	const [neighborhoods, setNeighborhoods] = useState([])
	// const [neighborhood, setNeighborhood] = useState({})

	useEffect(() => {
		getSubaccountInfo()
	}, [])

	useEffect(() => {
		if (subaccountInfo?.paises) getProvinces()
		// eslint-disable-next-line
	}, [subaccountInfo])

	const getSubaccountInfo = async () => {
		const { data } = await request.get("/planes/dataextra/")
		setSubaccountInfo(data)
	}

	const getProvinces = () => {
		const _provinces = []
		subaccountInfo.paises[0].departamentos.forEach((province) => {
			_provinces.push(province.nombre)
		})
		setProvinces(_provinces)
		setCities([])
		setNeighborhoods([])
	}

	const getCities = (provinceName) => {
		const _cities = []

		const province = subaccountInfo.paises[0].departamentos.find(
			({ nombre }) => nombre === provinceName
		)

		for (let city of subaccountInfo.ciudades) {
			if (city.departamentoId === province.id) {
				_cities.push(city.nombre)
			}
		}
		setCities(_cities)
		setNeighborhoods([])
	}

	const getNeighborhoods = (cityID) => {
		const _neighborhoods = []
		const cityIndex = cities.findIndex(({ id }) => id === cityID)
		subaccountInfo.ciudades[cityIndex].barrios.forEach(
			(neighborhood) => {
				_neighborhoods.push({
					id: neighborhood.id,
					name: neighborhood.nombre,
					zipCode: neighborhood.codigoPostal,
					region: neighborhood.region,
					localidad1: neighborhood.localidad1,
					localidad2: neighborhood.localidad2,
					latitud: neighborhood.latitud,
					longitud: neighborhood.longitud,
					elevation: neighborhood.elevation,
					cityID,
				})
			}
		)
		console.log(_neighborhoods)
		setNeighborhoods(_neighborhoods)
	}

	return useMemo(
		() => ({
			provinces,
			getProvinces,
			cities,
			getCities,
			neighborhoods,
			getNeighborhoods,
		}),
		// eslint-disable-next-line
		[provinces, cities, neighborhoods]
	)
}
