import { useMainContext } from "common/context/mainContext/mainContext"
import { useState, useEffect } from "react"

const useTypeProcess = () => {
	const [typeProcess, setTypeProcess] = useState([])
	const { getTypeProcess } = useMainContext().process

	useEffect(() => {
		getTypeProcess().then((_typeProcess) => {
			setTypeProcess(_typeProcess)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return { typeProcess }
}

export default useTypeProcess
