import { Box, IconButton } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import { makeStyles } from "@material-ui/core/styles"
import MenuIcon from "@material-ui/icons/Menu"
import { RedprocesalWhiteLogo } from "assets"
import clsx from "clsx"
import FreeTrialAlertInline from "common/components/FreeTrialAlertInline"
import Breadcrumb from "common/components/breadcrumbs"
import HeaderNavBar from "common/components/headerNavBar"
import useGuard from "common/hooks/useGuard"
import React from "react"
import { Routes } from "router/routes"
import DashboardListItem from "./DashboradListItem"

const drawerWidth = 280

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	hide: {
		display: "none",
	},
	drawer: {
		flexShrink: 0,
		whiteSpace: "nowrap",
		width: drawerWidth,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.light,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		position: "relative",
		"@media (max-width: 1500px)": {
			position: "fixed",
			zIndex: 10000,
			height: "calc(100vh)",
			top: 0,
			lef: 0,
		},
	},
	drawerClose: {
		"@media (max-width: 1500px)": {
			transform: "translateX(-100%)",
		},
	},
	content: {
		flexGrow: 1,
		minHeight: "100vh",
		backgroundColor: theme.palette.gray,
	},
	menuBtn: {
		"@media (min-width: 1501px)": {
			display: "none",
		},
	},
}))

export default function DashboardLayout({ children }) {
	const classes = useStyles()
	const [open, setOpen] = React.useState(true)

	const { user } = useGuard()

	const toggleDrawerOpen = () => {
		setOpen(!open)
	}

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Drawer
				variant="permanent"
				classes={{
					paper: clsx(classes.drawer, {
						[classes.drawerClose]: open,
					}),
				}}
			>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					minHeight={64}
				>
					<IconButton
						className={classes.menuBtn}
						color="inherit"
						onClick={toggleDrawerOpen}
					>
						<MenuIcon />
					</IconButton>
					<img src={RedprocesalWhiteLogo} alt="" width="180px" />
				</Box>
				<Divider />
				<Box pt={2}>
					<List>
						{Object.values(Routes).map((route, index) => (
							<DashboardListItem {...route} key={index} />
						))}
					</List>
				</Box>
			</Drawer>
			<main className={classes.content}>
				<HeaderNavBar toggleDrawer={toggleDrawerOpen} />

				{user?.if_free && (
					<Box mx={3} mt={2}>
						<FreeTrialAlertInline />
					</Box>
				)}

				<Box py={2} px={3}>
					<Box marginTop={3} marginBottom={5}>
						<Breadcrumb />
					</Box>
					{children}
				</Box>
			</main>
		</div>
	)
}
