import request from "common/plugins/request"
import { useFormik } from "formik"
import * as Yup from "yup"

export const useFormCompany = ({ user, onSubmit }) => {
	const signUpSchema = Yup.object().shape({
		nit: Yup.string().required("Requerido").nullable(),
		razonsocial: Yup.string().required("Requerido").nullable(),
		departamento: Yup.string().required("Requerido").nullable(),
		municipio: Yup.string().required("Requerido").nullable(),
		direccion: Yup.string().required("Requerido").nullable(),
		barrio: Yup.string().required("Requerido").nullable(),
		celular: Yup.string().required("Requerido").nullable(),
		telefonofijo: Yup.string(),
		username: Yup.string()
			.required("Requerido")
			.test("existsUsername", "Usuario existente", async (value) => {
				if (value) {
					const { availability_users } = await request.get(
						`/user/availability_users/?username=${value}`,
						{ hideLoader: true }
					)
					return availability_users === "true"
				}
			}),
		email: Yup.string()
			.required("Requerido")
			.email("Email invalido")
			.nullable(),
		password: Yup.string().required("Requerido").nullable(),
		validatePassword: Yup.string().oneOf(
			[Yup.ref("password"), null],
			"Las contraseñas no coinciden"
		),
	})

	const formSingUp = useFormik({
		initialValues: {
			nit: "",
			razonsocial: "",
			departamento: "",
			municipio: "",
			direccion: "",
			barrio: "",
			telefonofijo: "",
			celular: "",
			username: "",
			email: "",
			password: "",
		},
		validationSchema: signUpSchema,
		onSubmit,
	})

	return formSingUp
}
