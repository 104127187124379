import { Box } from "@material-ui/core"
import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import VSNotificationsFiler from "./components/VSNotificationsFiler"
import VSNotificationsTable from "./components/VSNotificationsTable"

const VSNotifications = () => (
	<DashboardLayout>
		<MainProvider>
			<Box marginBottom={4}>
				<VSNotificationsFiler />
			</Box>
			<VSNotificationsTable />
		</MainProvider>
	</DashboardLayout>
)

export default VSNotifications
