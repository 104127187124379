import { Box } from "@material-ui/core"
import { MainProvider } from "common/context/mainContext/mainContext"
import DashboardLayout from "common/layouts/DashboardLayout"
import AllNotificationsTable from "./components/AllNotificationsTable"
import RelatedDocumentsTable from "./components/RelatedDocumentsTable"

const Search = () => (
	<DashboardLayout>
		<MainProvider>
			<AllNotificationsTable />
			<Box mt={3}>
				<RelatedDocumentsTable />
			</Box>
		</MainProvider>
	</DashboardLayout>
)

export default Search
