import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	makeStyles,
	Tooltip,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import VisibilityIcon from "@material-ui/icons/Visibility"
import { Button } from "common/components/inputs/basics"
import { useMainContext } from "common/context/mainContext/mainContext"
import { useState } from "react"
import { useHistory } from "react-router-dom"

export const ProcessActionsCell = ({ id }) => {
	const history = useHistory()
	const [openDeleteMsg, setOpenDeleteMsg] = useState(false)
	const { getProcesses, disableProcess } = useMainContext().process

	const useStyles = makeStyles((theme) => ({
		icons: {
			color: "#444",
			fontSize: 22,
		},
	}))

	const onDisableProcess = async () => {
		await disableProcess(id)
		getProcesses()
		setOpenDeleteMsg(false)
	}

	const classes = useStyles()
	return (
		<>
			{openDeleteMsg && (
				<Dialog
					open
					fullWidth
					maxWidth="xs"
					onClose={() => setOpenDeleteMsg(false)}
				>
					<DialogTitle>Eliminar proceso</DialogTitle>
					<DialogContent>¿Desea eliminar el proceso?</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpenDeleteMsg(false)}>
							Cancelar
						</Button>
						<Button color="primary" onClick={onDisableProcess}>
							Confirmar
						</Button>
					</DialogActions>
				</Dialog>
			)}
			<Box display="flex" justifyContent="space-between">
				<Tooltip title="Ver detalle" className={classes.icons}>
					<IconButton
						color="primary"
						onClick={() => history.push(`/processes/${id}/summary`)}
					>
						<VisibilityIcon />
					</IconButton>
				</Tooltip>

				<Tooltip title="Editar" className={classes.icons}>
					<IconButton
						onClick={() => history.push(`/processes/${id}/update`)}
					>
						<EditIcon />
					</IconButton>
				</Tooltip>

				<Tooltip title="Eliminar" className={classes.icons}>
					<IconButton onClick={() => setOpenDeleteMsg(true)}>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			</Box>
		</>
	)
}
