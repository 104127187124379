import { Box } from "@material-ui/core"
import { TableContainer } from "@material-ui/core"
import { TableHead } from "@material-ui/core"
import { TableCell } from "@material-ui/core"
import { TableBody } from "@material-ui/core"
import { Paper } from "@material-ui/core"
import { TableRow } from "@material-ui/core"
import { Table } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { Input } from "common/components/inputs/basics"
import { Button } from "common/components/inputs/basics"
import React from "react"

const GenerateReports = () => (
	<>
		<Grid container spacing={2}>
			<Grid item xs={12} md={3}>
				<Input label="Usuario" />
			</Grid>
			<Grid item xs={12} md={3}>
				<Input label="Nº de Identificación" />
			</Grid>
			<Grid item xs={12} md={2}>
				<Input label="Plan del Usuario" />
			</Grid>
			<Grid item xs={12} md={2}>
				<Input label="Rango Inicial" type="date" />
			</Grid>
			<Grid item xs={12} md={2}>
				<Input label="Rango Final" type="date" />
			</Grid>
			<Grid item xs={12} md={12}>
				<Button color="primary" style={{ marginLeft: 10 }}>
					Generar
				</Button>
			</Grid>
		</Grid>

		<Box marginTop={5}>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell width="10%" align="center">
								Ciudad
							</TableCell>
							<TableCell width="15%" align="center">
								Despacho
							</TableCell>
							<TableCell width="15%" align="center">
								Radicado
							</TableCell>
							<TableCell width="10%" align="center">
								Demandante
							</TableCell>
							<TableCell width="10%" align="center">
								Demandado
							</TableCell>
							<TableCell width="10%" align="center">
								Última Actuación
							</TableCell>
							<TableCell width="10%" align="center">
								Asignado
							</TableCell>
							<TableCell width="10%" align="center">
								Ver
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableCell width="10%" align="center" colSpan={100}>
							No se encontraron registros
						</TableCell>
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	</>
)


export default GenerateReports