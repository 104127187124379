import { Box, Grid } from "@material-ui/core"
import {
	Autocomplete,
	Button,
	Input,
} from "common/components/inputs/basics"
import Modal from "common/components/modal"
import Title from "common/components/text/Title"
import useCities from "common/hooks/useCities"
import useOfficeTypes from "common/hooks/useOfficeTypes"
import { useEffect, useState } from "react"
import { useGenerateCup } from "../hooks/useGenerateCup"

const fullYears = Array.from({ length: 60 }).map((_, index) =>
	(new Date().getFullYear() - index).toString()
)

export const GenerateCup = ({ value = "", onChange }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [enabledWriteCup, setEnabledWriteCup] = useState(false)
	const [cup, setCup] = useState(value)

	const { cities } = useCities()
	const { officeTypes } = useOfficeTypes()
	const {
		changeCity,
		changeConsecutive,
		changeNumberOffice,
		changeOfficeType,
		changeYear,
		generateCupForm,
	} = useGenerateCup({
		onGeneratedCUP: (e) => {
			console.log("info", e)
			setCup(e.CUP)
			setIsOpen(false)
			setEnabledWriteCup(false)
			onChange(e)
		},
	})

	const [activeCity, setActiveCity] = useState({})
	const [activeOffice, setActiveOffice] = useState({})

	const confirmGenerateCup = () => {
		generateCupForm.resetForm()
		setIsOpen(true)
	}

	const confirmWriteCup = () => {
		const codeCity = cup.slice(0, 5)
		const codeOffice = cup.slice(5, 9)
		const numberOffice = cup.slice(9, 12)
		const yearProcess = cup.slice(12, 16)
		const consecutive = cup.slice(16, 21)

		generateCupForm.setFieldValue("codeCity", codeCity)
		generateCupForm.setFieldValue("codeOffice", codeOffice)
		generateCupForm.setFieldValue("numberOffice", numberOffice)
		generateCupForm.setFieldValue("yearProcess", yearProcess)
		generateCupForm.setFieldValue("consecutive", consecutive)
		setIsOpen(true)
	}

	useEffect(() => {
		const codeCity = generateCupForm.values.codeCity
		if (codeCity)
			return setActiveCity(
				cities?.find((city) => city?.dane_code_city === codeCity)
			)
		setActiveCity({})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generateCupForm.values.codeCity])

	useEffect(() => {
		const codeOffice = generateCupForm.values.codeOffice
		if (codeOffice)
			return setActiveOffice(
				officeTypes?.find(({ cod }) => cod === codeOffice)
			)
		setActiveOffice({})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generateCupForm.values.codeOffice])

	const autoFillNumberOffice = ({ target }) => {
		const filledNumberOffice = String(target.value)
			.padStart(3, "0")
			.substring(0, 3)
		generateCupForm.setFieldValue("numberOffice", filledNumberOffice)
	}

	const autoFillConsecutive = ({ target }) => {
		const filledConsecutive = String(target.value)
			.padStart(5, "0")
			.substring(0, 5)
		generateCupForm.setFieldValue("consecutive", filledConsecutive)
	}

	return (
		<>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs={5}>
					<Input
						label="CUP"
						value={cup}
						onChange={({ target }) => {
							const regex = /[^0-9]+/g
							setCup(target?.value?.replace(regex, ""))
						}}
						disabled={!enabledWriteCup}
					/>
				</Grid>
				<Grid item xs={6}>
					{!enabledWriteCup && (
						<>
							<Button
								size="large"
								color="primary"
								variant="outlined"
								onClick={() => setEnabledWriteCup(true)}
							>
								Escribir CUP
							</Button>
							<Button
								size="large"
								color="primary"
								variant="outlined"
								onClick={confirmGenerateCup}
								style={{ marginLeft: 10 }}
							>
								Generar CUP
							</Button>
						</>
					)}

					{enabledWriteCup && (
						<>
							<Button
								color="primary"
								variant="outlined"
								onClick={confirmWriteCup}
							>
								Confirmar
							</Button>
							<Button
								color="primary"
								variant="outlined"
								onClick={() => setEnabledWriteCup(false)}
								style={{ marginLeft: 10 }}
							>
								Cancelar
							</Button>
						</>
					)}
				</Grid>
			</Grid>

			<Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
				<Title size="h3" align="center" style={{ marginBottom: 20 }}>
					Generar CUP
				</Title>
				<form
					noValidate
					autoComplete="off"
					onSubmit={generateCupForm.handleSubmit}
				>
					<Box marginBottom={4}>
						<Grid container spacing={2}>
							<Grid item xs={5}>
								<Autocomplete
									label="Ciudad"
									options={cities}
									getOptionLabel={(city) => city?.name}
									onChange={(_, city) =>
										changeCity(city?.dane_code_city)
									}
									value={activeCity}
									errorText={generateCupForm.errors.codeCity}
									error={
										generateCupForm.touched.codeCity &&
										!!generateCupForm.errors.codeCity
									}
									disabled={!cities.length}
								/>
							</Grid>

							<Grid item xs={7}>
								<Autocomplete
									label="Tipo de despacho"
									options={officeTypes}
									getOptionLabel={(officeType) => officeType?.name}
									onChange={(_, office) =>
										changeOfficeType(office?.cod)
									}
									value={activeOffice}
									errorText={generateCupForm.errors.codeOffice}
									error={
										generateCupForm.touched.codeOffice &&
										!!generateCupForm.errors.codeOffice
									}
									disabled={!officeTypes.length}
								/>
							</Grid>
						</Grid>
					</Box>

					<Box marginBottom={4}>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<Input
									type="number"
									label="Número de despacho"
									onChange={({ target }) =>
										changeNumberOffice(target.value)
									}
									onBlur={autoFillNumberOffice}
									value={generateCupForm.values.numberOffice}
									errorText={generateCupForm.errors.numberOffice}
									error={
										generateCupForm.touched.numberOffice &&
										!!generateCupForm.errors.numberOffice
									}
									disabled={!officeTypes.length}
								/>
							</Grid>
							<Grid item xs={4}>
								<Autocomplete
									label="Año del Proceso"
									options={fullYears}
									getOptionLabel={(option) => option}
									onChange={(_, year) => changeYear(year)}
									value={generateCupForm.values.yearProcess}
									errorText={generateCupForm.errors.yearProcess}
									error={
										generateCupForm.touched.yearProcess &&
										!!generateCupForm.errors.yearProcess
									}
								/>
							</Grid>

							<Grid item md={4}>
								<Input
									type="number"
									label="Consecutivo del proceso"
									placeholder=" Ejm 00256"
									onChange={({ target }) =>
										changeConsecutive(target.value)
									}
									onBlur={autoFillConsecutive}
									value={generateCupForm.values.consecutive}
									errorText={generateCupForm.errors.consecutive}
									error={
										generateCupForm.touched.consecutive &&
										!!generateCupForm.errors.consecutive
									}
								/>
							</Grid>
						</Grid>
					</Box>

					<Button color="primary" type="submit">
						Generar
					</Button>
				</form>
			</Modal>
		</>
	)
}
