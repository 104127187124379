import request from "common/plugins/request"
import { useMemo, useState } from "react"

export const useSearchNotifications = () => {
	const [allNotifications, setAllNotifications] = useState([])
	const [qtyAllNotifications, setQtyAllNotifications] = useState(0)
	const [relatedDocuments, setRelatedDocuments] = useState([])
	const [qtyRelatedDocuments, setQtyRelatedDocuments] = useState(0)

	const getAllNotificationsByBolivar = async ({
		dte = "",
		ddo = "",
		cup = "",
		...queryParams
	}) => {
		const { results: notifications, count } = await request.get(
			"allnotifications/",
			{
				params: {
					dte__icontains: dte,
					ddo__icontains: ddo,
					cup__icontains: cup,
					...queryParams,
				},
			}
		)
		setQtyAllNotifications(count)
		setAllNotifications(notifications)
	}

	const getRelatedDocuments = async ({ content, ...queryParams }) => {
		const { results: related, count } = await request.get(
			"bolivar/",
			{
				params: { content__contains: content, ...queryParams },
			}
		)
		setRelatedDocuments(related)
		setQtyRelatedDocuments(count)
	}

	return useMemo(
		() => ({
			relatedDocuments,
			allNotifications,
			qtyAllNotifications,
			getRelatedDocuments,
			qtyRelatedDocuments,
			getAllNotificationsByBolivar,
		}),
		// eslint-disable-next-line
		[relatedDocuments, allNotifications]
	)
}
