import React, { useState } from "react"
import Input from "./Input"
import { makeStyles } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	box: {
		display: "flex",
		position: "relative",
		marginTop: 5,
	},
	label: {
		fontSize: 12.5,
		fontWeight: 500,
	},
	filter: {
		top: 1,
		left: 1,
		zIndex: 20,
		fontSize: 13,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderTopLeftRadius: 5,
		borderBottomLeftRadius: 5,
		position: "absolute",
		width: 110,
		backgroundColor: theme.palette.gray,
		//color: theme.palette.dark,
		height: "calc(100% - 2px)",
		cursor: "pointer",
	},

	list: {
		top: "calc(100% - 1px)",
		width: "100%",
		position: "absolute",
		backgroundColor: "inherit",
		borderBottomLeftRadius: 5,
		borderBottomRightRadius: 5,
		overflow: "hidden",
		border: "1px solid #ccc",
		borderTop: 0,
		"& li": {
			fontSize: 12.5,
			listStyle: "none",
			paddingTop: 10,
			paddingBottom: 12,
			paddingLeft: 10,

			"&:hover": {
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.light,
				border: "inherit",
			},
		},
	},

	search: {
		boxSizing: "border-box",
		"& input": {
			paddingLeft: "120px",
		},
		"& .MuiInputBase-root": {},
	},
}))

const InputSearch = () => {
	const classes = useStyles()
	const [activeFilter, setActiveFilter] = useState("Todo")
	const [isOpenFilter, setIsOpenFilter] = useState(false)

	const handleFilter = (filter) => {
		setIsOpenFilter(false)
		setActiveFilter(filter)
	}

	const filters = [
		"Todo",
		"Despacho",
		"Ciudad",
		"CUP",
		"Estado",
		"Tipo",
		"Demandante",
		"Demandado",
		"Apoderados",
	]
	return (
		<>
			<label className={classes.label}>Buscar por</label>
			<Box className={classes.box}>
				<div
					className={classes.filter}
					onMouseOver={() => setIsOpenFilter(true)}
					onMouseLeave={() => setIsOpenFilter(false)}
				>
					{activeFilter}
					{isOpenFilter && (
						<ul className={classes.list}>
							{filters.map((filter, index) => (
								<li onClick={() => handleFilter(filter)} key={index}>
									{filter}
								</li>
							))}
						</ul>
					)}
				</div>
				<Input type="search" className={classes.search} />
			</Box>
		</>
	)
}

export default InputSearch
