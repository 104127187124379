import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Tooltip,
} from "@material-ui/core"
import TuneIcon from "@material-ui/icons/Tune"
import { Autocomplete, Input } from "common/components/inputs/basics"
import Button from "common/components/inputs/basics/Button"
import { useMainContext } from "common/context/mainContext/mainContext"
import useCities from "common/hooks/useCities"
import { useState } from "react"

const NotificationsFilter = () => {
	const mainContext = useMainContext()
	const { changeFilter, getNotifications } = mainContext.notifications
	const { cities } = useCities()

	const [isOpenFilter, setIsOpenFilter] = useState(false)

	const handleFilter = ({ target }) => {
		changeFilter({
			[target.name]: target.value,
		})
	}

	const onHandleFilter = () => {
		getNotifications()
		setIsOpenFilter(false)
	}

	return (
		<>
			<Tooltip title="Filtros avanzados">
				<Button
					variant="contained"
					onClick={() => setIsOpenFilter(true)}
				>
					<TuneIcon />
				</Button>
			</Tooltip>
			<Dialog
				open={isOpenFilter}
				onClose={() => setIsOpenFilter(false)}
			>
				<DialogTitle>Filtros Avanzados</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Input
								label="Fecha inicial"
								name="startDate"
								type="date"
								onChange={handleFilter}
							/>
						</Grid>
						<Grid item xs={6}>
							<Input
								label="Fecha final"
								name="endDate"
								type="date"
								onChange={handleFilter}
							/>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								label="Ciudad"
								options={cities.map(({ name }) => name)}
								onChange={(_, city) => changeFilter({ city })}
								getOptionLabel={(city) => city}
								disabled={!cities.length}
							/>
						</Grid>

						<Grid item xs={12}>
							<Input
								label="Despacho"
								name="office"
								onChange={handleFilter}
							/>
						</Grid>

						<Grid item xs={6}>
							<Input
								label="Radicado"
								name="radicado"
								onChange={handleFilter}
							/>
						</Grid>

						<Grid item xs={6}>
							<Input
								label="Tipo"
								name="type"
								onChange={handleFilter}
							/>
						</Grid>

						<Grid item xs={12}>
							<Input
								name="claimant"
								label="Demandante"
								onChange={handleFilter}
							/>
						</Grid>

						<Grid item xs={12}>
							<Input
								name="defendant"
								label="Demandado"
								onChange={handleFilter}
							/>
						</Grid>

						{localStorage.getItem("is_superuser_pasiolo") && (
							<Grid item xs={12}>
								<Input
									label="Razón Social"
									name="nameCompany"
									onChange={handleFilter}
								/>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={onHandleFilter}>
						Filtrar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default NotificationsFilter
