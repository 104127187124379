import useGuard from "common/hooks/useGuard"
import { BasicTheme } from "common/themes"
import FreeTrialPeriod from "containers/FreeTrialPeriod"
import Login from "containers/login/Login"
import SignUp from "containers/signUp/signUp"
import FreeTrialExpired from "containers/views/FreeTrialExpired"
import {
	BrowserRouter,
	Redirect,
	Route,
	Switch,
} from "react-router-dom"
import Routers from "router/Routers"

const App = () => {
	const { isAuth, user } = useGuard()

	return (
		<BasicTheme>
			<BrowserRouter>
				<Switch>
					<Route path="/login" component={Login} exact></Route>
					<Route
						path="/sign-up/free-trial"
						component={FreeTrialPeriod}
					></Route>
					<Route path="/sign-up/:planID?" component={SignUp}></Route>

					{isAuth && user?.if_free && user?.days_to_expire <= 0 && (
						<>
							<Route
								path="/free-trial-expired"
								component={FreeTrialExpired}
							></Route>
							<Redirect from="*" to="/free-trial-expired" />
						</>
					)}
					{!isAuth && <Redirect from="*" to="/login" />}
					<Routers />
				</Switch>
			</BrowserRouter>
			{/*<Loading />*/}
		</BasicTheme>
	)
}
export default App
