import { useMainContext } from "common/context/mainContext/mainContext"
import FormPerson from "containers/signUp/components/formPerson"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const UpdateSubaccoutForm = () => {
	const history = useHistory()
	const { id: userID } = useParams()
	const [user, setUser] = useState()
	const { updateUser } = useMainContext().signUp
	const { getSubaccount, subaccount } = useMainContext().subaccounts

	useEffect(() => {
		getSubaccount(userID)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (subaccount?.id) mapSubaccountData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subaccount])

	const mapSubaccountData = () => {
		const mapSubaccount = {}
		mapSubaccount.nombre = subaccount?.user?.first_name
		mapSubaccount.apellido = subaccount?.apellido
		mapSubaccount.email = subaccount?.user?.email
		mapSubaccount.cedula = subaccount?.cedula
		mapSubaccount.celular = subaccount?.celular_persona
		mapSubaccount.telefonofijo = subaccount?.telefonofijo_persona
		mapSubaccount.username = subaccount?.user?.username
		mapSubaccount.direccion = subaccount?.direccion
		mapSubaccount.departamento = subaccount?.departamento
		mapSubaccount.municipio = subaccount?.municipio
		mapSubaccount.barrio = subaccount?.barrio
		setUser(mapSubaccount)
	}

	const onSubmitForm = async (data) => {
		const usuarioData = {
			first_name: data.nombre,
			email: data.email,
		}
		const dataUserPasioloLegal = {
			user_pasiolo_id: null,
			apellido: data.apellido,
			cedula: data.cedula,
			razonsocial: `${data.nombre} ${data.apellido}`,
			departamento: data.departamento,
			municipio: data.municipio,
			direccion: data.direccion,
			barrio: data.barrio,
			telefonofijo_persona: data.telefonofijo,
			celular_persona: data.celular,
			telefonofijo_empresa: null,
			celular_empresa: null,
		}
		// const dataUser = mapDataUser(data)
		await updateUser(subaccount?.user?.id, {
			usuarioData,
			dataUserPasioloLegal,
		})
		history.push("/subcuentas")
	}

	return user ? (
		<FormPerson onSubmit={onSubmitForm} user={user} />
	) : null
}

export default UpdateSubaccoutForm
