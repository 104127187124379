import { makeStyles } from "@material-ui/core"
import { Button } from "common/components/inputs/basics"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100vw",
		height: "100vh",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		position: "fixed",
		overflow: "hidden",
		left: 0,
		top: 0,
		backgroundColor: theme.palette.primary.main,
		zIndex: 1000000,
	},
	title: {
		fontSize: "4rem",
		textAlign: "center",
		color: theme.palette.light,
		[theme.breakpoints.down("md")]: {
			fontSize: "3rem",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "2rem",
		},
	},
	text: {
		textAlign: "center",
		marginTop: 35,
		maxWidth: 750,
		lineHeight: 1.5,
		fontSize: "1.15rem",
		color: theme.palette.light,
	},

	button: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.light,
		textDecoration: "none",
		marginTop: 25,
		display: "flex",
		alignItems: "center",
		padding: 10,
		paddingLeft: 80,
		paddingRight: 80,
		"& .MuiButton-label": {
			fontSize: "1rem",
			fontWeight: 600,
		},
	},
}))

const FreeTrialExpired = () => {
	const classes = useStyles()
	const logout = () => {
		localStorage.clear()
		window.location = "/"
	}

	return (
		<div className={classes.root}>
			<h1 className={classes.title}>
				Tu periodo de prueba <br /> ha terminado.
			</h1>
			<p className={classes.text}>
				Para continuar utilizando la plataforma, le invitamos a
				comprar un plan y obtener acceso completo a nuestras funciones
				y beneficios.
			</p>

			<Button onClick={logout} className={classes.button}>
				Salir
			</Button>
		</div>
	)
}

export default FreeTrialExpired
