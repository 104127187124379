import { Grid } from "@material-ui/core";
import { Input } from "common/components/inputs/basics";
import FuncionalityDataTable from "common/components/table/funcionalityDataTable";
import React from "react";
import * as Yup from "yup";

const Claimant = ({ data, changeData }) => {
	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required("Requerido"),
	})
	
	return (
		<FuncionalityDataTable
			headers={["Cédula", "Nombre"]}
			initialValues={{ cedula: "", nombre: "" }}
			onChangeData={(newData) => changeData(newData)}
			typesValues={{ cedula: "currency"}}
			validationSchema={validationSchema}
			data={data}
		>
			{({ handleValue, values, setFieldValue, touched, errors }) => (
				<Grid container spacing={3}>
					<Grid item xs={7}>
						<Input
							name="nombre"
							label="Nombre Demandante"
							onChange={handleValue}
							value={values.nombre}
							errorText={errors.nombre}
							error={
								touched.nombre &&
								!!errors.nombre
							}
						/>
					</Grid>
					<Grid item xs={5}>
						<Input
							name="cedula"
							label="Cédula"
							type="currency"
							onChange={(_, value)=> setFieldValue("cedula", value)}
							value={values.cedula}
							currencySymbol="cc"
							decimalPlaces={0}
						/>
					</Grid>
				</Grid>
			)}
		</FuncionalityDataTable>
	)
}

export default Claimant
