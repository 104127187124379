import { Box, makeStyles } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import Title from "common/components/text/Title"
import { useMainContext } from "common/context/mainContext/mainContext"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useCurrency } from "../../../common/hooks/currency"

const PlansTypes = ({ planID }) => {
	const classes = useStyles()
	const { toCurrency } = useCurrency()
	const history = useHistory()
	const [plans, setPlans] = useState([])
	const [activePlan, setActivePlan] = useState(0)
	const { getPlans, changePlan } = useMainContext().signUp

	const scopeGetPlans = async () => {
		const _plans = await getPlans()
		setPlans(_plans)
	}

	const incrementPlan = () => {
		const planStep = activePlan + 1
		if (planStep <= plans.length - 1) {
			setActivePlan(planStep)
			changePlan(plans[planStep])
		}
	}

	const decrementPlan = () => {
		const planStep = activePlan - 1
		if (planStep >= 0) {
			setActivePlan(planStep)
			changePlan(plans[planStep])
		}
	}

	useEffect(() => {
		scopeGetPlans()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (!plans?.length) return false
		if (!planID) changePlan(plans[activePlan])
		activatePlansByRoute()

		// eslint-disable-next-line
	}, [plans])

	const activatePlansByRoute = () => {
		const planIndex = plans.findIndex(
			({ id }) => String(id) === planID
		)
		if (planIndex === -1) return history.push("/sign-up")
		setActivePlan(planIndex)
		changePlan(plans[planIndex])
	}

	return (
		<>
			<Box textAlign="center">
				<Title size="h3">PLAN SELECCIONADO</Title>
				<Box className={classes.planPricing}>
					<div className="qty-process">
						<RemoveIcon
							className="decrement"
							onClick={() => decrementPlan(activePlan - 1)}
						/>
						<p>
							<span className="qty">
								{plans[activePlan]?.cantidad}
							</span>{" "}
							procesos
						</p>
						<AddIcon
							className="increment"
							onClick={() => incrementPlan()}
						/>
					</div>
					<p className="price">
						${toCurrency(plans[activePlan]?.precio_venta)} mensuales
					</p>
				</Box>
			</Box>

			<Box className={classes.planInfo}>
				<p>Todos los planes incluyen:</p>
				<ul>
					<li>- Acceso al administrador de procesos</li>
					<li>- Creación de cuenta con perfil personalizado</li>
					<li>- Consulta del histórico de procesos</li>
					<li>
						- Alertas por email y en plataforma cada vez que un
						proceso es notificado en juzgados
					</li>
					<li>- Agenda electrónica compatible con Google Calendar</li>
				</ul>
			</Box>
		</>
	)
}

const useStyles = makeStyles((theme) => ({
	planPricing: {
		marginTop: 30,
		marginBottom: 50,
		"& p": {
			fontWeight: "600",
		},

		"& .qty-process": {
			display: "flex",
			justifyContent: "center",
			position: "relative",

			"& .decrement, & .increment": {
				cursor: "pointer",
				transform: "scale(2)",
				fontSize: "1rem",
				color: theme.palette.dark,
				marginLeft: 10,
				marginRight: 10,
				marginTop: 8,
			},

			"& .qty": {
				fontSize: "4.5rem",
				lineHeight: 0.8,
				display: "block",
				fontWeight: "700",
				marginBottom: 5,
			},
		},

		"& .price": {
			marginTop: 5,
			color: theme.palette.dark,
			fontWeight: "700",
		},
	},
	planInfo: {
		paddingTop: 50,
		"& p": {
			fontWeight: "700",
			fontSize: "1.2rem",
			color: theme.palette.dark,
			marginBottom: 8,
		},

		"& ul": {
			listStyle: "none",
			fontSize: ".9rem",
			lineHeight: 1.9,
			fontWeight: "500",
		},
	},
}))

export default PlansTypes
