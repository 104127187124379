import {
	Box,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from "@material-ui/core"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import { useMainContext } from "common/context/mainContext/mainContext"
import qs from "query-string"
import React from "react"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
	title_table: {
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		backgroundColor: theme.palette.primary.main,
		padding: 15,
		color: theme.palette.light,
		fontWeight: 500,
	},

	sortField: {
		display: "inline-flex",
		cursor: "pointer",
		alignItems: "center",
	},

	sortIcon: {
		display: "flex",
		color: theme.palette.lightGray,
	},
}))

const VSNotificationsTable = () => {
	const classes = useStyles()
	const history = useHistory()
	const { VSNotifications, VSNotificationsDate } =
		useMainContext().VSNotifications

	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [sort, setSort] = React.useState([])

	const sortByField = (field) => {
		const [sortField, sortType] = sort
		console.log(field, sortField)
		if (field !== sortField) setSort([field, "ASC"])
		else setSort([field, sortType === "ASC" ? "DES" : "ASC"])
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const redirectToDetailVSNotifications = (id) => {
		history.push({
			pathname: `/clientes/vs-notifications/${id}`,
			search: qs.stringify({
				fromDate: VSNotificationsDate.initialDate,
				toDate: VSNotificationsDate.finalDate,
			}),
		})
	}

	const getRowsSortAndPagination = () => {
		let activeRows = VSNotifications

		if (rowsPerPage > 0) {
			activeRows = VSNotifications.slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			)
		}

		if (sort.length) {
			const [sortField, sortType] = sort
			activeRows = activeRows.sort((a, b) => {
				let currentField = a[sortField]
				let nextField = b[sortField]

				if (
					typeof currentField === "string" &&
					typeof nextField === "string"
				) {
					currentField = currentField.toLowerCase()
					nextField = nextField.toLowerCase()
				}

				return currentField < nextField
					? -1
					: currentField > nextField
					? 1
					: 0
			})

			if (sortType === "DES") activeRows = activeRows.reverse()
		}

		return activeRows
	}

	const SortTable = ({ field, children }) => {
		const [sortField, sortType] = sort
		return (
			<Box
				className={classes.sortField}
				display="inline-flex"
				alignItems="center"
			>
				{children}
				<span className={classes.sortIcon}>
					{sortField === field ? (
						sortType === "ASC" ? (
							<ArrowDropUpIcon />
						) : (
							<ArrowDropDownIcon />
						)
					) : null}
				</span>
			</Box>
		)
	}

	return (
		<>
			<Box className={classes.title_table}>
				Pasiolo Legal VS Red Procesal
			</Box>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell
								width="25%"
								align="center"
								onClick={() => sortByField("razon_social")}
							>
								<SortTable field="razon_social">
									Razón social
								</SortTable>
							</TableCell>
							<TableCell
								width="25%"
								align="center"
								onClick={() => sortByField("nombre")}
							>
								<SortTable field="nombre">Nombre</SortTable>
							</TableCell>
							<TableCell
								width="15%"
								align="center"
								onClick={() => sortByField("redprocesal")}
							>
								<SortTable field="redprocesal">
									Cant. Red Procesal
								</SortTable>
							</TableCell>
							<TableCell
								width="15%"
								align="center"
								onClick={() => sortByField("pasiololegal")}
							>
								<SortTable field="pasiololegal">
									Cant. Pasiololegal
								</SortTable>
							</TableCell>
							<TableCell width="15%" align="center">
								VS
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{getRowsSortAndPagination().map(
							(
								{ razon_social, nombre, pasiololegal, redprocesal },
								index
							) => (
								<TableRow
									onClick={() =>
										redirectToDetailVSNotifications(nombre)
									}
									key={index}
								>
									<TableCell align="center">{razon_social}</TableCell>
									<TableCell align="center">{nombre}</TableCell>
									<TableCell align="center">{redprocesal}</TableCell>
									<TableCell align="center">{pasiololegal}</TableCell>
									<TableCell align="center">
										{Math.abs(redprocesal - pasiololegal)}
									</TableCell>
								</TableRow>
							)
						)}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[10, 20, 50, 100]}
					component="div"
					count={VSNotifications.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Filas por pagina"
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} de ${count}`
					}
				/>
			</TableContainer>
		</>
	)
}
export default VSNotificationsTable
