import { Box } from "@material-ui/core"
import { useMainContext } from "common/context/mainContext/mainContext"
import React, { useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router"
import PSNotificationsTable from "./PSNotificationsTable"
import RPNotificationsTable from "./RPNotificationsTable"
import qs from 'query-string';

const VSNotificationsTables = () => {
    
    const { id } = useParams()
    const history = useHistory()
    const location = useLocation();
	const { getVSNotification } = useMainContext().VSNotifications
 
	useEffect(() => {
        const { fromDate, toDate } = qs.parse(location.search)
        getVSNotification(id, fromDate, toDate).catch(() =>{
            history.push(`/clientes/vs-notifications`)
        })
		// eslint-disable-next-line
	}, [])
	return (
		<>
			<PSNotificationsTable />
			<Box marginTop={5}>
				<RPNotificationsTable />
			</Box>
		</>
	)
}

export default VSNotificationsTables
