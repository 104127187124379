import { useMemo } from "react"
import { useGenerateCupSchema } from "./useGenerateCupSchema"

export const useGenerateCup = ({ onGeneratedCUP }) => {
	const generateCupForm = useGenerateCupSchema(
		{},
		({
			codeCity,
			codeOffice,
			numberOffice,
			yearProcess,
			consecutive,
		}) => {
			const CUP = `${codeCity}${codeOffice}${numberOffice}${yearProcess}${consecutive}`
			onGeneratedCUP({
				CUP,
				codeCity,
				codeOffice,
				numberOffice,
				yearProcess,
				consecutive,
			})
		}
	)

	const changeCity = (codeCity) => {
		generateCupForm.setFieldValue("codeCity", codeCity)
	}

	const changeOfficeType = (codeOffice) => {
		generateCupForm.setFieldValue("codeOffice", codeOffice)
	}

	const changeNumberOffice = (numberOffice) => {
		generateCupForm.setFieldValue("numberOffice", numberOffice)
	}

	const changeYear = (yeard) => {
		generateCupForm.setFieldValue("yearProcess", yeard)
	}

	const changeConsecutive = (consecutive) => {
		generateCupForm.setFieldValue("consecutive", String(consecutive))
	}

	return useMemo(
		() => ({
			changeCity,
			changeConsecutive,
			changeNumberOffice,
			changeOfficeType,
			changeYear,
			generateCupForm,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[generateCupForm]
	)
}
