import { useMainContext } from "common/context/mainContext/mainContext"
import React, { useEffect } from "react"
import { useHistory, useParams } from "react-router"
import ProcessFirstStep from "./processFirstStep"
import ProcessSecondStep from "./processSecondStep"
import ProcessThirdStep from "./processThirdStep"

const ContentSteps = ({ steps, activeStep, handleStep }) => {
	const { id } = useParams()
	const history = useHistory()
	const { getProcess } = useMainContext().process

	useEffect(() => {
		getProcess(id)
		// eslint-disable-next-line
	}, [])

	const stepControls = {
		cancel(){
			history.push(`/processes`)
		},
		next() {
			handleStep(activeStep + 1)
		},
		back() {
			handleStep(activeStep - 1)
		},
		finish() {
			history.push(`/processes/${id}/summary`)
		},
		hasNext: steps - 1 > activeStep,
		hasBack: activeStep !== 0,
	}

	const contentSteps = [
		<ProcessFirstStep {...stepControls} />,
		<ProcessSecondStep {...stepControls} />,
		<ProcessThirdStep {...stepControls} />,
	]

	return contentSteps[activeStep]
}

export default ContentSteps
