import { Box, Grid } from "@material-ui/core"
import { Button, Input } from "common/components/inputs/basics"
import { useMainContext } from "common/context/mainContext/mainContext"

const AllNotificationsFilters = () => {
	const mainContext = useMainContext()
	const { changeFilterAdmin, getNotificationsAdmin } =
		mainContext.notifications

	return (
		<Box display="flex" alignItems="center">
			<Grid
				container
				spacing={1}
				style={{ marginLeft: 5, width: 800 }}
			>
				<Grid item xs={3}>
					<Input
						name="cup"
						label="CUP / Radicado"
						onChange={({ target: { value: cupOrCode } }) =>
							changeFilterAdmin({ cupOrCode })
						}
						autoComplete="off"
					/>
				</Grid>
				<Grid item xs={3}>
					<Input
						label="Despacho"
						onChange={({ target: { value: office } }) =>
							changeFilterAdmin({ office })
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<Input
						name="claimant"
						label="Demandante"
						onChange={({ target: { value: claimant } }) =>
							changeFilterAdmin({ claimant })
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<Input
						name="defendant"
						label="Demandado"
						onChange={({ target: { value: defendant } }) =>
							changeFilterAdmin({ defendant })
						}
					/>
				</Grid>
			</Grid>

			<Button
				color="primary"
				variant="contained"
				style={{ marginLeft: 5 }}
				onClick={() => getNotificationsAdmin()}
			>
				Filtrar
			</Button>
		</Box>
	)
}

export default AllNotificationsFilters
