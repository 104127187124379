import {
	Box,
	Collapse,
	IconButton,
	TableCell,
	TableRow,
} from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import React, { useState } from "react"

const AssignedProcessesTableRowHeadSm = () => (
	<TableRow>
		<TableCell width="8%" />
		<TableCell width="40%" align="center">
			Radicado
		</TableCell>
		<TableCell align="center">Ciudad</TableCell>
	</TableRow>
)

const AssignedProcessesTableRowBodySm = ({ row, onRowClick }) => {
	const [openRow, setOpenRow] = useState(false)
	return (
		<>
			<TableRow onClick={(e) => onRowClick(e, row)}>
				<TableCell padding="none">
					<IconButton
						onClick={(event) => {
							setOpenRow(!openRow)
							event.stopPropagation()
						}}
					>
						{openRow ? (
							<KeyboardArrowUpIcon />
						) : (
							<KeyboardArrowDownIcon />
						)}
					</IconButton>
				</TableCell>
				<TableCell>{row.radicado_del_proceso}</TableCell>
				<TableCell>{row.ciudad}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ padding: 0 }} colSpan={10}>
					<Collapse in={openRow} timeout="auto" unmountOnExit>
						<Box padding={2.5}>
							<ul style={{ listStyle: "none", lineHeight: 1.8 }}>
								<li style={{ fontWeight: "bold" }}>Asignado: </li>
								<li>{row.asignado}</li>
							</ul>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	)
}

export {
	AssignedProcessesTableRowHeadSm,
	AssignedProcessesTableRowBodySm,
}
