import {
	Box,
	Collapse,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles,
} from "@material-ui/core"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import { useState } from "react"
import { useHistory } from "react-router-dom"

const DashboardListItem = ({ label, path, children, icon }) => {
	const Icon = icon
	const classes = useStyles()
	const history = useHistory()
	const [openItems, setOpenItems] = useState(false)

	const changeOpenItem = (e) => {
		e.stopPropagation()
		setOpenItems(!openItems)
	}

	function goToRoute(route) {
		history.push(route)
	}

	return (
		<>
			<ListItem
				button
				className={classes.navItemOption}
				onClick={() => goToRoute(path)}
			>
				<ListItemIcon className={classes.navIconItem}>
					<Icon />
				</ListItemIcon>
				<ListItemText
					primary={label}
					className={classes.navTextItem}
				/>
				{children ? (
					<Box marginLeft="auto" onClick={changeOpenItem}>
						{openItems ? <ExpandLess /> : <ExpandMore />}
					</Box>
				) : null}
			</ListItem>
			{children ? (
				<Collapse in={openItems} timeout="auto" unmountOnExit>
					{Object.values(children).map(
						({ label: subLabel, path: subPath, visible }, index) =>
							visible ? (
								<List disablePadding key={index}>
									<ListItem
										button
										className={classes.navItemOption}
										onClick={() => goToRoute(subPath)}
									>
										<ListItemText
											primary={subLabel}
											className={classes.navTextItem}
										/>
									</ListItem>
								</List>
							) : null
					)}
				</Collapse>
			) : null}
		</>
	)
}

const useStyles = makeStyles((theme) => ({
	listItems: {
		marginTop: 40,
	},
	navItemOption: {
		fontSize: "15px !important",
		fontWeight: "500 !important",
		paddingTop: 12,
		paddingLeft: 25,
		paddingRight: 25,
		paddingBottom: 12,
		color: "inherit",
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 30,
			paddingRight: 30,
		},
	},

	navIconItem: {
		color: "inherit",
		minWidth: 0,
		marginRight: 25,
	},
	navTextItem: {},
}))

export default DashboardListItem
